import React from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
//UI
import { FaEnvira, FaFish, FaHome, FaLifeRing, FaList, FaMapMarked, FaRegLightbulb, FaShip } from 'react-icons/fa';
import BtnDashboard from '../buttons/BtnDashboard';
//RESOURCERS
import { useToolbarStore } from '../../store/VitalStore';
import { FaClock, FaEarlybirds, FaEarthAmericas, FaLocationDot, FaNoteSticky, FaPeopleArrows } from 'react-icons/fa6';

const PanelList = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { isToolbarOpen, closeToolbar } = useToolbarStore((state) => state);
    const { yacht_id, destination_id, baner_id, section_id, lead_id, notice_id, popup_id, description_id } = useParams();

    const user = JSON.parse(localStorage.getItem("userInfo"));

    return (
        <div className='w-[100%]'>
            <div className='pl-2 text-[10px] font-semibold uppercase text-start'>Rolf Wittmer - Screens</div>
            {
                user.role === 'admin' || user.role === 'marketing' ?
                    <>
                        <li
                            onClick={(e) => { navigate('/panel/home'); closeToolbar(); }}
                            className={`w-full ${location.pathname === '/panel/home' ? 'active' :
                                location.pathname === `/panel/home/baner/${baner_id}` ? 'active' :
                                    location.pathname === `/panel/home/section/${section_id}` ? 'active' :
                                        location.pathname === '/panel/home/recomendation' ? 'active' : ''}`}>
                            <BtnDashboard>
                                <FaHome />
                                <p>{t("Home")}</p>
                            </BtnDashboard>
                        </li>
                        <li
                            onClick={(e) => { navigate('/panel/our-history'); closeToolbar(); }}
                            className={`w-full ${location.pathname === '/panel/our-history' ? 'active' :
                                location.pathname === `/panel/our-history/baner/${baner_id}` ? 'active' :
                                    location.pathname === `/panel/our-history/section/${section_id}` ? 'active' :
                                        location.pathname === `/panel/our-history/description/${description_id}` ? 'active' : ''}`}>
                            <BtnDashboard>
                                <FaLocationDot />
                                <p>{t("Our history")}</p>
                            </BtnDashboard>
                        </li>
                        <li
                            onClick={(e) => { navigate('/panel/galapagos-islands'); closeToolbar(); }}
                            className={`w-full ${location.pathname === '/panel/galapagos-islands' ? 'active' :
                                location.pathname === `/panel/galapagos-islands/baner/${baner_id}` ? 'active' :
                                    location.pathname === `/panel/galapagos-islands/section/${section_id}` ? 'active' :
                                        location.pathname === `/panel/galapagos-islands/description/${description_id}` ? 'active' : ''}`}>
                            <BtnDashboard>
                                <FaEarthAmericas />
                                <p>{t("Galapagos islands")}</p>
                            </BtnDashboard>
                        </li>
                        <li
                            onClick={(e) => { navigate('/panel/last-minute-deals'); closeToolbar(); }}
                            className={`w-full ${location.pathname === '/panel/last-minute-deals' ? 'active' :
                                location.pathname === `/panel/last-minute-deals/baner/${baner_id}` ? 'active' :
                                    location.pathname === `/panel/last-minute-deals/section/${section_id}` ? 'active' :
                                        location.pathname === `/panel/last-minute-deals/description/${description_id}` ? 'active' : ''}`}>
                            <BtnDashboard>
                                <FaClock />
                                <p>{t("Last minutes deals")}</p>
                            </BtnDashboard>
                        </li>
                        <li
                            onClick={(e) => { navigate('/panel/galapagos-blog'); closeToolbar(); }}
                            className={`w-full ${location.pathname === '/panel/galapagos-blog' ? 'active' :
                                location.pathname === `/panel/galapagos-blog/baner/${baner_id}` ? 'active' :
                                location.pathname === `/panel/galapagos-blog/notice/${notice_id}/section/${section_id}` ? 'active' :
                                location.pathname === `/panel/galapagos-blog/notice/${notice_id}` ? 'active' :
                                location.pathname === `/panel/galapagos-blog/notice/${notice_id}/sections` ? 'active' :
                                location.pathname === `/panel/galapagos-blog/notice/new_notice` ? 'active' :
                                location.pathname === `/panel/galapagos-blog/notice/${notice_id}/new_section` ? 'active' :
                                location.pathname === `/panel/galapagos-blog/notices` ? 'active' : ''}`}>
                            <BtnDashboard>
                                <FaNoteSticky />
                                <p>{t("Galapagos blog")}</p>
                            </BtnDashboard>
                        </li>
                        <li
                            onClick={(e) => { navigate('/panel/social-responsability'); closeToolbar(); }}
                            className={`w-full ${location.pathname === '/panel/social-responsability' ? 'active' :
                                location.pathname === `/panel/social-responsability/baner/${baner_id}` ? 'active' :
                                location.pathname === `/panel/social-responsability/notice/${notice_id}/section/${section_id}` ? 'active' :
                                location.pathname === `/panel/social-responsability/notice/${notice_id}` ? 'active' :
                                location.pathname === `/panel/social-responsability/notice/${notice_id}/sections` ? 'active' :
                                location.pathname === `/panel/social-responsability/notice/new_notice` ? 'active' :
                                location.pathname === `/panel/social-responsability/notice/${notice_id}/new_section` ? 'active' :
                                location.pathname === `/panel/social-responsability/notices` ? 'active' : ''}`}>
                            <BtnDashboard>
                                <FaEarlybirds />
                                <p>{t("Social Responsability")}</p>
                            </BtnDashboard>
                        </li>
                    </> : " "
            }
            {
                user.role === 'admin' || user.role === 'marketing' || user.role === 'sales' ?
                    <>
                        <li
                            onClick={(e) => { navigate('/panel/leads'); closeToolbar(); }}
                            className={`w-full ${location.pathname === '/panel/leads' ? 'active' :
                                location.pathname === `/panel/leads/${lead_id}` ? 'active' : ''}`}>
                            <BtnDashboard>
                                <FaPeopleArrows />
                                <p>{t("Leads")}</p>
                            </BtnDashboard>
                        </li>
                        <li
                            onClick={(e) => { navigate('/panel/availability'); closeToolbar(); }}
                            className={`w-full ${location.pathname === '/panel/availability' ? 'active' :
                                location.pathname === `/panel/availability/${yacht_id}` ? 'active' : ''}`}>
                            <BtnDashboard>
                                <FaEnvira />
                                <p>{t("Availability")}</p>
                            </BtnDashboard>
                        </li>
                    </>
                    :
                    " "
            }
            {
                user.role === 'admin' || user.role === 'marketing' ?
                    <>
                        <div className='pl-2 text-[10px] font-semibold uppercase text-start'>RWittmer & Tip Top Fleet - Screens</div>

                        <li
                            onClick={(e) => { navigate('/panel/yachts'); closeToolbar(); }}
                            className={`w-full ${location.pathname === '/panel/yachts' ? 'active' :
                                location.pathname === '/panel/yachts_new' ? 'active' :
                                    location.pathname === `/panel/yacht/${yacht_id}/technical_info` ? 'active' :
                                        location.pathname === `/panel/yacht/${yacht_id}/full_screen_gallery` ? 'active' :
                                            location.pathname === `/panel/yacht/${yacht_id}/social_areas` ? 'active' :
                                                location.pathname === `/panel/yacht/${yacht_id}/deck_plan` ? 'active' :
                                                    location.pathname === `/panel/yacht/${yacht_id}/safety_communications` ? 'active' :
                                                        location.pathname === `/panel/yacht/${yacht_id}/mechanic_equipment` ? 'active' :
                                                            location.pathname === `/panel/yacht/${yacht_id}/guests_accomodations` ? 'active' :
                                                                location.pathname === `/panel/yacht/${yacht_id}/crews_on_board` ? 'active' : ''}`}>
                            <BtnDashboard>
                                <FaShip />
                                <p>{t("yachts_tag")}</p>
                            </BtnDashboard>
                        </li>
                        <li
                            onClick={(e) => { navigate('/panel/itineraries'); closeToolbar(); }}
                            className={`w-full ${location.pathname === '/panel/itineraries' ? 'active' :
                                location.pathname === '/panel/itineraries_new' ? 'active' : ''}`}>
                            <BtnDashboard>
                                <FaList />
                                <p>{t("itineraries_tag")}</p>
                            </BtnDashboard>
                        </li>
                        <li
                            onClick={(e) => { navigate('/panel/crews'); closeToolbar(); }}
                            className={`w-full ${location.pathname === '/panel/crews' ? 'active' :
                                location.pathname === '/panel/crews_new' ? 'active' : ''}`}>
                            <BtnDashboard>
                                <FaLifeRing />
                                <p>{"Crew"}</p>
                            </BtnDashboard>
                        </li>
                        <li
                            onClick={(e) => { navigate('/panel/destinations'); closeToolbar(); }}
                            className={`w-full ${location.pathname === '/panel/destinations' ? 'active' :
                                location.pathname === '/panel/destinations_new' ? 'active' :
                                    location.pathname === `/panel/destinations/${destination_id}/island_sites` ? 'active' : ''}`}>
                            <BtnDashboard>
                                <FaMapMarked />
                                <p>{"Destinations"}</p>
                            </BtnDashboard>
                        </li>
                        <li
                            onClick={(e) => { navigate('/panel/cancellation_policy'); closeToolbar(); }}
                            className={`w-full ${location.pathname === '/panel/cancellation_policy' ? 'active' : ''}`}>
                            <BtnDashboard>
                                <FaRegLightbulb />
                                <p>{"Cancellation policy"}</p>
                            </BtnDashboard>
                        </li>
                    </> : " "
            }
        </div>
    );
};

export default PanelList;