import { createSlice } from '@reduxjs/toolkit';
import { setIsLoading } from '../isLoading.slice';
import axios from 'axios';
import { logOut } from '../../../resources/utils';


export const noticesPageSlice = createSlice({
    name: 'noticesPage',
    initialState: {
        noticesPage: {},
        message: "",
        error: "",
    },
    reducers: {
        initialStateNoticesPage: (state, action) => {
            return {
                noticesPage: {},
                message: "",
                error: "",
            }
        },
        setNoticesPage: (state, action) => {
            return {
                noticesPage: action.payload,
                message: "",
                error: "",
            }
        },
        setNoticesPageMessages: (state, action) => {
            return {
                noticesPage: {},
                message: action.payload,
                error: "",
            }
        },
        setNoticesPageError: (state, action) => {
            return {
                noticesPage: {},
                message: "",
                error: action.payload,
            }
        },
        deleteSuccess(state, action) {
            return {
                noticesPage: {},
                message: action.payload,
                error: "",
            }
        },
        deleteError(state, action) {
            return {
                noticesPage: {},
                message: "",
                error: action.payload,
            }
        },
    }
})

//LastMinuteList
export const getNoticeThunk = () => dispatch => {
    dispatch(setIsLoading(true));
    axios.get(`/noticesScreen/adminContent`)
        .then(res => {
            if (res.status === 200) {
                dispatch(setNoticesPage(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setNoticesPageError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const {
    initialStateNoticesPage,
    setNoticesPages,
    setNoticesPage,
    setNoticesPageMessages,
    setNoticesPageError,
} = noticesPageSlice.actions;

export default noticesPageSlice.reducer;