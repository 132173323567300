import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
//UI
import HomeLayout from '../../../layouts/HomeLayout';
import MainLoader from '../../../components/Loaders/MainLoader';
import BtnContent from '../../../components/buttons/BtnContent';
import BreadTreeParts from '../../../components/breadcrumbs/BreadTreeParts';
import DropdownForm from '../../../components/Inputs/formInput/DropdonwForm';
import Error from '../../../components/errors/Error';

//SLICES
import { getYachtsThunk, initialStateYacht } from '../../../store/slices/catalogs/yacht.slice';
import { createItineraryYachtThunk } from '../../../store/slices/catalogs/itineraryYacht.slice';
import { getItineraryThunk, initialStateItinerary} from '../../../store/slices/catalogs/itinerary.slice';



const ItineraryYachtForm = () => {

    const { t } = useTranslation();
    let navigate = useNavigate();
    let { itinerary_id } = useParams();
    const { setValue, register, handleSubmit, formState: { errors } } = useForm();
    const itineraryState = useSelector(state => state.itineraries);
    const yachtState = useSelector(state => state.yachts);
    const itineraryYachtState = useSelector(state => state.itineraryYacht);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getItineraryThunk(itinerary_id))
        dispatch(getYachtsThunk())
        return () => {
            dispatch(initialStateYacht());
            dispatch(initialStateItinerary())
        };
    }, []);

    const onSubmit = (data) => {
        dispatch(createItineraryYachtThunk(itinerary_id, data));
    };

    if (itineraryYachtState.message === "resource created successfully" || itineraryYachtState.message === "resource updated successfully") {
        navigate(`/panel/itineraries/${itinerary_id}/yachts`);
    }

    return (
        <HomeLayout>
            {itineraryYachtState.fetching || itineraryState.fetching ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {itineraryYachtState.error ? <Error state={itineraryYachtState.error}/> : " "}
                    <BreadTreeParts
                        titleOne={t("itinerary_title")} toOne={'/panel/itineraries'}
                        titleTwo={itineraryState.itinerary.name} toTwo={`/panel/itineraries/${itinerary_id}/yachts`}
                        titleTree={t("yacht_title")}
                    />
                    <div className='h-[90%] overflow-y-scroll contenedor'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex gap-2 p-2'>
                                <DropdownForm
                                    label={t("yacht_name")}
                                    input="input"
                                    spam={true}
                                    cols={1}
                                    register={register("yachti_id", { required: true })}
                                    options={yachtState.yachts}
                                    errors={errors.yachti_id && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                />
                            </div>
                            <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                                <BtnContent type="submit">{t("save_button")}</BtnContent>
                                <BtnContent cancel={true} to={`/captains/${itinerary_id}/yachts`}>{t("canceled_button")}</BtnContent>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default ItineraryYachtForm;