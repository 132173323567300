import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut } from '../../../resources/utils';

export const destinationSlice = createSlice({
    name: 'destinations',
    initialState: {
        destinations: [],
        destination: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
    },
    reducers: {
        initialStateDestination(state, action) {
            return {
                destinations: [],
                destination: {},
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        requestFetchDestinations(state, action) {
            return {
                destinations: [],
                destination: {},
                processing: false,
                fetching: true,
                message: "",
                error: "",
            }
        },
        fetchDestinationsSuccess(state, action) {
            return {
                destinations: action.payload,
                destination: {},
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        fetchDestinationsError(state, action) {
            return {
                destinations: [],
                destination: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestFetchDestination(state, action) {
            return {
                destinations: [],
                destination: {},
                processing: false,
                fetching: true,
                message: "",
                error: "",
            }
        },
        fetchDestinationSuccess(state, action) {
            return {
                destinations: [],
                destination: action.payload,
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        fetchDestinationError(state, action) {
            return {
                destinations: [],
                destination: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestCreateDestination(state, action) {
            return {
                destinations: [],
                destination: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        createDestinationsuccess(state, action) {
            return {
                destinations: [],
                destination: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        createDestinationError(state, action) {
            return {
                destinations: [],
                destination: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestUpdateDestination(state, action) {
            return {
                destinations: [],
                destination: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        updateDestinationsuccess(state, action) {
            return {
                destinations: [],
                destination: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        updateDestinationError(state, action) {
            return {
                destinations: [],
                destination: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestDeleteDestination(state, action) {
            return {
                destinations: [],
                destination: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        deleteDestinationsuccess(state, action) {
            return {
                destinations: [],
                destination: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        deleteDestinationError(state, action) {
            return {
                destinations: [],
                destination: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },

    }
})

export const getDestinationsThunk = () => dispatch => {
    dispatch(requestFetchDestinations())
    axios.get('/destinations')
        .then(res => {
            if (res.status === 200) {
                dispatch(fetchDestinationsSuccess(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(fetchDestinationsError(res.response.data))
            }
        })
};

export const getDestinationThunk = (destination_id) => dispatch => {
    dispatch(requestFetchDestination())
    axios.get(`/destinations/${destination_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(fetchDestinationSuccess(res.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(fetchDestinationError(res.response.data))
            }
        })
};

export const getDestinationWithIslandsThunk = (destination_name) => dispatch => {
    dispatch(requestFetchDestination())
    axios.get(`/destinations/destinationWithIslands/${destination_name}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(fetchDestinationSuccess(res.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(fetchDestinationError(res.response.data))
            }
        })
};

export const createDestinationThunk = (data) => dispatch => {
    dispatch(requestCreateDestination())
    axios.post('/destinations/createDestination?images=destinations', data)
        .then(res => {
            if (res.status === 200) {
                dispatch(createDestinationsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(createDestinationError(res.response.data))
            }
        })
};

export const updateDestinationThunk = (destination_id, data) => dispatch => {
    dispatch(requestUpdateDestination())
    axios.put(`/destinations/updateDestination/${destination_id}?images=destinations`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(updateDestinationsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(updateDestinationError(res.response.data))
            }
        })
};

export const deleteDestinationThunk = (destination_id) => dispatch => {
    dispatch(requestDeleteDestination())
    axios.delete(`/destinations/${destination_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(deleteDestinationsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(deleteDestinationError(res.response.data))
            }
        })
};



export const { initialStateDestination,
    requestFetchDestinations,
    fetchDestinationsSuccess,
    fetchDestinationsError,
    requestFetchDestination,
    fetchDestinationSuccess,
    fetchDestinationError,
    requestCreateDestination,
    createDestinationsuccess,
    createDestinationError,
    requestUpdateDestination,
    updateDestinationsuccess,
    updateDestinationError,
    requestDeleteDestination,
    deleteDestinationsuccess,
    deleteDestinationError,
} = destinationSlice.actions;

export default destinationSlice.reducer;