import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut } from '../../../resources/utils';

export const proceduresAvailabilitySlice = createSlice({
    name: 'proceduresAvailability',
    initialState: {
        processing: false,
        fetching: false,
        message: "",
        error: "",
    },
    reducers: {
        initialStateAvailability(state, action) {
            return {
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        requestCreateAvailability(state, action) {
            return {
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        createAvailabilitysuccess(state, action) {
            return {
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        createAvailabilityError(state, action) {
            return {
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestUpdateAvailability(state, action) {
            return {
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        updateAvailabilitysuccess(state, action) {
            return {
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        updateAvailabilityError(state, action) {
            return {
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestDeleteAvailability(state, action) {
            return {
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        deleteAvailabilitysuccess(state, action) {
            return {
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        deleteAvailabilityError(state, action) {
            return {
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },

    }
})



export const createAvailabilityThunk = (data) => dispatch => {
    dispatch(requestCreateAvailability())
    axios.post('/availability/createAvailability', data)
        .then(res => {
            if (res.status === 200) {
                dispatch(createAvailabilitysuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(createAvailabilityError(res.response.data))
            }
        })
};

export const updateAvailabilityThunk = (year_id, data) => dispatch => {
    dispatch(requestUpdateAvailability())
    axios.put(`/availability/updateAvailability/${year_id}`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(updateAvailabilitysuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(updateAvailabilityError(res.response.data))
            }
        })
};

export const deleteAvailabilityThunk = (year_id) => dispatch => {
    dispatch(requestDeleteAvailability())
    axios.delete(`/availability/${year_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(deleteAvailabilitysuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(deleteAvailabilityError(res.response.data))
            }
        })
};



export const { initialStateAvailability,
    requestCreateAvailability,
    createAvailabilitysuccess,
    createAvailabilityError,
    requestUpdateAvailability,
    updateAvailabilitysuccess,
    updateAvailabilityError,
    requestDeleteAvailability,
    deleteAvailabilitysuccess,
    deleteAvailabilityError,
} = proceduresAvailabilitySlice.actions;

export default proceduresAvailabilitySlice.reducer;