import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
//UI
import MainLoader from '../../../components/Loaders/MainLoader';
import HomeLayout from '../../../layouts/HomeLayout';
import HeaderForm from '../../../components/headers/catalogs/HeaderForm';
import BtnContent from '../../../components/buttons/BtnContent';
import Error from '../../../components/errors/Error';
import HeaderSection from '../../../components/headers/catalogs/HeaderSection';
import { FaFilePdf } from 'react-icons/fa';
// SLICES 
import { getYachtThunk } from '../../../store/slices/catalogs/yacht.slice';
import { donwloadFileThunk } from '../../../store/slices/donwloadFile.slice';
import { getDeckPlanThunk, createDeckPlanThunk, updateDeckPlanThunk, initialStateYachtInformation } from '../../../store/slices/yachts/yachtInfo.slice';
// RESOURCES
import { API_BASE_URL } from '../../../store/constans';
import { Toast } from '../../../resources/utils';

const DeckPlan = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { yacht_id } = useParams();
    const [selectedRolfImage, setSelectedRolfImage] = useState(null);
    const [selectedTipTopImage, setSelectedTipTopImage] = useState(null);
    const [rolfFileName, setRolfFileName] = useState("");
    const [tipTopfileName, setTipTopFileName] = useState("");
    const yachtname = useSelector((state) => state.yachts)
    const yachtState = useSelector((state) => state.yachtInformation);
    const { handleSubmit, register, formState: { errors } } = useForm();

    useEffect(() => {
        dispatch(getYachtThunk(yacht_id));
        if (yacht_id) {
            dispatch(getDeckPlanThunk(yacht_id));
        }
        return () => {
            dispatch(initialStateYachtInformation());
        };
    }, []);

    useEffect(() => {
        setRolfFileName(yachtState.yachtInformation?.rwFile)
        setTipTopFileName(yachtState.yachtInformation?.ttfFile)
    }, [yachtState])

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('yachtId', yacht_id);
        if (data.rwImage[0]) {
            formData.append('rwImage', data.rwImage[0]);
        } else {
            formData.append('rwImage', yachtState.yachtInformation?.rwImage);
        }
        if (data.ttfImage[0]) {
            formData.append('ttfImage', data.ttfImage[0]);
        } else {
            formData.append('ttfImage', yachtState.yachtInformation?.ttfImage);
        }

        if (data.rwFile[0]) {
            formData.append('rwFile', data.rwFile[0]);
        } else {
            formData.append('rwFile', yachtState.yachtInformation?.rwFile);
        }
        if (data.ttfFile[0]) {
            formData.append('ttfFile', data.ttfFile[0]);
        } else {
            formData.append('ttfFile', yachtState.yachtInformation?.ttfFile);
        }

        if (yachtState.yachtInformation?.id) {
            dispatch(updateDeckPlanThunk(yacht_id, formData));
        } else {
            dispatch(createDeckPlanThunk(formData));
        }
    };

    if (yachtState.message === "resource created successfully" || yachtState.message === "resource updated successfully") {
        Toast.fire({
            icon: 'success',
            title: yachtState.message
        })
        dispatch(getDeckPlanThunk(yacht_id));
    }

    const handleImageRolfChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedRolfImage(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleImageTipTopChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedTipTopImage(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    function getFileNameFromPath(path) {
        return path.split('/').pop();
    }

    const handleDownload = (filename) => {
        dispatch(donwloadFileThunk(filename))
    };

    return (
        <HomeLayout>
            {yachtState.fetching || yachtState.processing ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {yachtState.error ? <Error state={yachtState.error} /> : " "}
                    <HeaderForm title={`Deck Plan - ${yachtname.yacht.name}`} yacht_id={yacht_id} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <HeaderSection title={"Gallery"} />
                        <div className='flex justify-center'>
                            <div className='flex flex-col gap-2 p-2 my-5 items-center font-semibold'>
                                <div className='flex items-center'>
                                    <label className="text-sm flex m-1">Imágen Rolf Wittmer<span className="text-red-500">*</span></label>
                                    <input {...register('rwImage')} type="file" onChange={handleImageRolfChange} accept="image/*" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    {selectedRolfImage || yachtState.yachtInformation?.rwImage ?
                                        <img className='h-32 w-42 rounded-md' src={selectedRolfImage ? selectedRolfImage : `${API_BASE_URL}${yachtState.yachtInformation?.rwImage}`} alt="" /> : ""}
                                </div>
                            </div>
                            <div className='flex flex-col gap-2 p-2 my-5 items-center font-semibold'>
                                <div className='flex items-center'>
                                    <label className="text-sm flex m-1">Imágen Tip Top Fleet<span className="text-red-500">*</span></label>
                                    <input {...register('ttfImage')} type="file" onChange={handleImageTipTopChange} accept="image/*" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    {selectedTipTopImage || yachtState.yachtInformation?.ttfImage ?
                                        <img className='h-32 w-42 rounded-md' src={selectedTipTopImage ? selectedTipTopImage : `${API_BASE_URL}${yachtState.yachtInformation?.ttfImage}`} alt="" /> : ""}
                                </div>
                            </div>
                        </div>
                        <HeaderSection title={"PDF Archive"} />
                        <div className='flex justify-center'>
                            <div className='flex flex-col gap-2 p-2 items-center font-semibold'>
                                <div className='flex gap-3'>
                                    <label className="text-sm flex m-1">Rolf Wittmer File</label>
                                    <div className='flex flex-col gap-2'>
                                        <input {...register('rwFile')} type="file" accept="application/pdf" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                    </div>
                                </div>
                                {rolfFileName ?
                                    <div className='flex flex-col items-center cursor-pointer' onClick={() => handleDownload(getFileNameFromPath(rolfFileName))} >
                                        <div className='bg-gray-300 py-3 w-24 rounded-md flex flex-col justify-center items-center' >
                                            <FaFilePdf size={90} color='red' />
                                        </div>
                                        <div className='w-full p-2'>
                                            <p className='text-[12px] w-auto'>{rolfFileName}</p>
                                        </div>
                                    </div> : " "
                                }
                            </div>
                            <div className='flex flex-col gap-2 p-2 items-center font-semibold'>
                                <div className='flex gap-3'>
                                    <label className="text-sm flex m-1">Tip Top Fleet File</label>
                                    <div className='flex flex-col gap-2'>
                                        <input {...register('ttfFile')} type="file" accept="application/pdf" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                    </div>
                                </div>
                                {tipTopfileName ?
                                    <div className='flex flex-col items-center cursor-pointer' onClick={() => handleDownload(getFileNameFromPath(tipTopfileName))} >
                                        <div className='bg-gray-300 py-3 w-24 rounded-md flex flex-col justify-center items-center' >
                                            <FaFilePdf size={90} color='red' />
                                        </div>
                                        <div className='w-full p-2'>
                                            <p className='text-[12px] w-auto'>{tipTopfileName}</p>
                                        </div>
                                    </div> : " "
                                }
                            </div>

                        </div>
                        <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                            <BtnContent type="submit">{t("save_button")}</BtnContent>
                            <BtnContent cancel={true} to={'/panel/yachts'}>{t("canceled_button")}</BtnContent>
                        </div>
                    </form>
                </div>
            )}
        </HomeLayout>
    );
};

export default DeckPlan;