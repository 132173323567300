import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut } from '../../../resources/utils';

export const descriptionSlice = createSlice({
    name: 'descriptions',
    initialState: {
        descriptions: [],
        description: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
    },
    reducers: {
        initialStateDescription(state, action) {
            return {
                descriptions: [],
                description: {},
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        requestFetchDescription(state, action) {
            return {
                descriptions: [],
                description: {},
                processing: false,
                fetching: true,
                message: "",
                error: "",
            }
        },
        fetchDescriptionSuccess(state, action) {
            return {
                descriptions: [],
                description: action.payload,
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        fetchDescriptionError(state, action) {
            return {
                descriptions: [],
                description: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestCreateDescription(state, action) {
            return {
                descriptions: [],
                description: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        createDescriptionsuccess(state, action) {
            return {
                descriptions: [],
                description: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        createDescriptionError(state, action) {
            return {
                descriptions: [],
                description: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestUpdateDescription(state, action) {
            return {
                descriptions: [],
                description: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        updateDescriptionsuccess(state, action) {
            return {
                descriptions: [],
                description: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        updateDescriptionError(state, action) {
            return {
                descriptions: [],
                description: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestDeleteDescription(state, action) {
            return {
                descriptions: [],
                description: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        deleteDescriptionsuccess(state, action) {
            return {
                descriptions: [],
                description: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        deleteDescriptionError(state, action) {
            return {
                descriptions: [],
                description: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },

    }
})

export const getDescriptionThunk = (description_id) => dispatch => {
    dispatch(requestFetchDescription())
    axios.get(`/descriptions/getDescription/${description_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(fetchDescriptionSuccess(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(fetchDescriptionError(res.response.data))
            }
        })
};


export const createDescriptionsThunk = (data) => dispatch => {
    dispatch(requestCreateDescription())
    axios.post(`/descriptions/createDescriptions`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(createDescriptionsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(createDescriptionError(res.response.data))
            }
        })
};

export const updateDescriptionsThunk = (page, data) => dispatch => {
    dispatch(requestUpdateDescription())
    axios.put(`/descriptions/updateDescriptions/${page}`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(updateDescriptionsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(updateDescriptionError(res.response.data))
            }
        })
};

export const createOneDescriptionThunk = (data) => dispatch => {
    dispatch(requestCreateDescription())
    axios.post(`/descriptions/createOneDescription`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(createDescriptionsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(createDescriptionError(res.response.data))
            }
        })
};

export const updateOneDescriptionThunk = (page, data) => dispatch => {
    dispatch(requestUpdateDescription())
    axios.put(`/descriptions/updateOneDescription/${page}`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(updateDescriptionsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(updateDescriptionError(res.response.data))
            }
        })
};

export const createDescriptionWhitImageThunk = (data) => dispatch => {
    dispatch(requestCreateDescription())
    axios.post(`/descriptions/createDescriptionWhitImages?images=our_history_page`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(createDescriptionsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(createDescriptionError(res.response.data))
            }
        })
};

export const updateDescriptionWhitImageThunk = (description_id, data) => dispatch => {
    dispatch(requestUpdateDescription())
    axios.put(`/descriptions/updateDescriptionWhitImages/${description_id}?images=our_history_page`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(updateDescriptionsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(updateDescriptionError(res.response.data))
            }
        })
};

export const deleteDescriptionThunk = (description_id) => dispatch => {
    dispatch(requestDeleteDescription())
    axios.delete(`/descriptions/${description_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(deleteDescriptionsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(deleteDescriptionError(res.response.data))
            }
        })
};



export const { initialStateDescription,
    requestFetchDescription,
    fetchDescriptionSuccess,
    fetchDescriptionError,
    requestCreateDescription,
    createDescriptionsuccess,
    createDescriptionError,
    requestUpdateDescription,
    updateDescriptionsuccess,
    updateDescriptionError,
    requestDeleteDescription,
    deleteDescriptionsuccess,
    deleteDescriptionError,
} = descriptionSlice.actions;

export default descriptionSlice.reducer;