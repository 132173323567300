import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
//UI
import HomeLayout from '../../../layouts/HomeLayout';
import BtnTable from '../../../components/buttons/BtnTable';
import HeaderSimple from '../../../components/headers/catalogs/HeaderSimple';
import MainLoader from '../../../components/Loaders/MainLoader';
import Error from '../../../components/errors/Error';
//SLICE
import { getCrewsThunk, deleteCrewThunk } from '../../../store/slices/catalogs/crew.slice';
//RESOURCES
import { API_BASE_URL } from '../../../store/constans';



const CrewList = () => {

    const { t } = useTranslation();
    const crewState = useSelector(state => state.crews);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getCrewsThunk());
    }, []);

    if (crewState.message === "resource deleted successfully") {
        dispatch(getCrewsThunk());
    }

    const handleDelete = (yacht_id) => {
        dispatch(deleteCrewThunk(yacht_id));
    };

    return (
        <HomeLayout>
            {crewState.fetching || crewState.processing ? (
                <MainLoader />
            ) : (
                <div className='mx-5 my-5 w-full'>
                    {crewState.error ? <Error state={crewState.error} /> : " "}
                    <HeaderSimple title={"Crew"} to={`/panel/crews_new`} />
                    <div className="overflow-y-scroll h-[87%] contenedor">
                        <table className="text-[13px] table-sm w-full text-start">
                            <thead className='border-t-2 border-t-[#2c6a80]' >
                                <tr className='text-left h-[50px] bg-[#c2c2c2]/20'>
                                    <th>{t("yacht_name")}</th>
                                    <th>{t("yacht_pick")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {crewState.crews.map(crew => (
                                    <tr key={crew.id} className='border-b-[2px] duration-75'>
                                        <td >{crew.name}</td>
                                        <td >
                                            <img className='rounded-md h-14 w-24 ' src={`${API_BASE_URL}${crew.image}`} alt={crew.name} />
                                        </td>
                                        <td >
                                            <div className='flex gap-1 justify-end'>
                                                <BtnTable action="edit" to={`/panel/crews/${crew.id}`} />
                                                <BtnTable title={t("crew_delete")} action="delete" onclick={() => handleDelete(crew.id)} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default CrewList;