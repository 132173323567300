import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
//UI
import MainLoader from '../../../components/Loaders/MainLoader';
import HomeLayout from '../../../layouts/HomeLayout';
import HeaderForm from '../../../components/headers/catalogs/HeaderForm';
import BtnContent from '../../../components/buttons/BtnContent';
import { FaFilePdf } from 'react-icons/fa';
// SLICES 
import { donwloadFileThunk } from '../../../store/slices/donwloadFile.slice';
import { createCancelationPolicyThunk, getCancelationPolicysThunk, initialStateCancelationPolicy, updateCancelationPolicyThunk } from '../../../store/slices/catalogs/cancelationPolicy.slice';
// RESOURCES
import Error from '../../../components/errors/Error';
//RESOURCES
import { Toast } from '../../../resources/utils';



const CancelationPolicy = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [rolfFileName, setRolfFileName] = useState("")
    const [tipTopFileName, setTipTopFileName] = useState("")
    const cancelationState = useSelector((state) => state.cancelationPolicy);
    const { setValue, register, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        dispatch(getCancelationPolicysThunk());
        return () => {
            dispatch(initialStateCancelationPolicy());
        };
    }, []);

    useEffect(() => {
        setRolfFileName(cancelationState.cancelationPolicy[0]?.rwFile)
        setTipTopFileName(cancelationState.cancelationPolicy[0]?.ttfFile)
    }, [cancelationState])

    const onSubmit = (data) => {
        const formData = new FormData();
        if (data.rwFile[0]) {
            formData.append('rwFile', data.rwFile[0]);
        } else {
            formData.append('rwFile', cancelationState.cancelationPolicy[0]?.rwFile);
        }

        if (data.ttfFile[0]) {
            formData.append('ttfFile', data.ttfFile[0]);
        } else {
            formData.append('ttfFile', cancelationState.cancelationPolicy[0]?.ttfFile);
        }

        if (cancelationState.cancelationPolicy[0]?.id) {
            const fileId = cancelationState.cancelationPolicy[0]?.id
            dispatch(updateCancelationPolicyThunk(fileId, formData));
        } else {
            dispatch(createCancelationPolicyThunk(formData));
        }
    };

    if (cancelationState.message === "resource created successfully" || cancelationState.message === "resource updated successfully") {
        Toast.fire({
            icon: 'success',
            title: cancelationState.message
        })
        dispatch(getCancelationPolicysThunk());
    }

    function getFileNameFromPath(path) {
        return path.split('/').pop();
    }

    const handleDownload = (filename) => {
        dispatch(donwloadFileThunk(filename))
    };

    return (
        <HomeLayout>
            {cancelationState.fetching || cancelationState.processing ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {cancelationState.error ? <Error state={cancelationState.error} /> : " "}
                    <HeaderForm title={"Booking & Cancellation Policy"} />
                    <div className='h-[90%] overflow-y-scroll contenedor'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex justify-center'>
                                <div className='flex flex-col gap-2 p-2 items-center font-semibold'>
                                    <div className='flex gap-3'>
                                        <label className="text-sm flex m-1">Rolf Wittmer File</label>
                                        <div className='flex flex-col gap-2'>
                                            <input {...register('rwFile')} type="file" accept="application/pdf" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                        </div>
                                    </div>
                                    {rolfFileName ?
                                        <div className='flex flex-col items-center cursor-pointer' onClick={() => handleDownload(getFileNameFromPath(rolfFileName))} >
                                            <div className='bg-gray-300 py-3 w-24 rounded-md flex flex-col justify-center items-center' >
                                                <FaFilePdf size={90} color='red' />
                                            </div>
                                            <div className='w-full p-2'>
                                                <p className='text-[12px] w-auto'>{rolfFileName}</p>
                                            </div>
                                        </div> : " "
                                    }
                                </div>
                                <div className='flex flex-col gap-2 p-2 items-center font-semibold'>
                                    <div className='flex gap-3'>
                                        <label className="text-sm flex m-1">Tip Top Fleet File</label>
                                        <div className='flex flex-col gap-2'>
                                            <input {...register('ttfFile')} type="file" accept="application/pdf" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                        </div>
                                    </div>
                                    {tipTopFileName ?
                                        <div className='flex flex-col items-center cursor-pointer' onClick={() => handleDownload(getFileNameFromPath(tipTopFileName))} >
                                            <div className='bg-gray-300 py-3 w-24 rounded-md flex flex-col justify-center items-center' >
                                                <FaFilePdf size={90} color='red' />
                                            </div>
                                            <div className='w-full p-2'>
                                                <p className='text-[12px] w-auto'>{tipTopFileName}</p>
                                            </div>
                                        </div> : " "
                                    }
                                </div>
                            </div>
                            <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                                <BtnContent type="submit">{t("save_button")}</BtnContent>
                                <BtnContent cancel={true} to={'/panel/itineraries'}>{t("canceled_button")}</BtnContent>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default CancelationPolicy;