import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
//UI
import HomeLayout from '../../../layouts/HomeLayout';
import HeaderSimpleII from '../../../components/headers/catalogs/HeaderSimpleII';
import MainLoader from '../../../components/Loaders/MainLoader';
//SLICE

import { getSalesUsersThunk } from '../../../store/slices/catalogs/users.slice';
import { getLeadsThunk, initialStateLead } from '../../../store/slices/pages/leads.slice';
import { updateLeadThunk, initialStateProcedureLead } from '../../../store/slices/pages/proceduresLeads.slice';

import Error from '../../../components/errors/Error';
import LeadsSubMenu from '../../../components/submenus/LeadsSubMenu';
import { useNavigate } from 'react-router-dom';
import { formatDateToLocal, formatDate } from '../../../utils/funtions';
import { FaCalendar, FaDownload, FaFileExcel, FaMailBulk, FaPhone, FaShip, FaUser } from 'react-icons/fa';
import BtnTable from '../../../components/buttons/BtnTable';
import { FaEarthAmericas, FaMobile } from 'react-icons/fa6';
import BtnCircle from '../../../components/buttons/BtnCircle';
import BtnDonwload from '../../../components/buttons/BtnDonwload';
import { generateExcelOfLeads } from '../../../resources/generateExcels';


const LeadsList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const leadsState = useSelector(state => state.leads);
    const userState = useSelector(state => state.users);
    const procedureLeadsState = useSelector(state => state.proceduresLeads);
    const isLoading = useSelector(state => state.isLoadingSlice);


    useEffect(() => {
        dispatch(getLeadsThunk());
        dispatch(getSalesUsersThunk());
        return () => {
            dispatch(initialStateLead())
            dispatch(initialStateProcedureLead());
        }
    }, []);

    if (procedureLeadsState.message === "resource updated successfully") {
        dispatch(getLeadsThunk());
        dispatch(initialStateProcedureLead());
    }

    const handleNaviate = (leadId) => {
        navigate(`/panel/leads/${leadId}`)
    }

    const handleUpdateSatatusLead = (leadId, data) => {
        dispatch(updateLeadThunk(leadId, data))
    }

    const handleGenerateExcel = () => {
        generateExcelOfLeads()
    };

    return (
        <HomeLayout>
            {isLoading ? (
                <MainLoader />
            ) : (
                <div className='mx-5 my-5 w-full'>
                    {leadsState.error ? <Error state={leadsState.error} /> : " "}
                    <div className='flex items-center justify-between'>
                        <HeaderSimpleII title={t("Leads")} />
                        <BtnDonwload btnAction={handleGenerateExcel}>
                            <FaDownload />
                            Descargar excel
                        </BtnDonwload>
                    </div>
                    <div className="h-screen md:overflow-y-scroll md:h-[87%] contenedor">
                        <table className="text-[13px] table-sm w-full text-start">
                            <thead className='hidden md:contents'>
                                <tr className='text-left h-[50px] bg-[#c2c2c2]/20'>
                                    <th className='w-[200px]'>{t("Fechas")}</th>
                                    <th className='w-[120px]'>{t("Datos lead")}</th>
                                    <th className='w-[400px]'>{t("Mensaje")}</th>
                                    <th className='w-[200px]'>{t("Estado")}</th>
                                    <th className='w-[120px]'>{t("Agente")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <thead className='md:hidden'>
                                <tr className='text-left h-[50px] bg-[#c2c2c2]/20'>
                                    <th>LEADS</th>
                                </tr>
                            </thead>
                            <tbody className='hidden md:contents'>
                                {leadsState.leads.map(lead => (
                                    <tr className='h-[50px] border-b-[1px] border-b-gray-300 cursor-pointer hover:bg-gray-300 duration-100' key={lead.id}>
                                        <td onClick={() => handleNaviate(lead.id)}>
                                            <div className='felx flex-col gap-2'>
                                                <p className='flex flex-col'>
                                                    <span className='font-semibold text-[12px]'>Primer contacto:</span>
                                                    {formatDateToLocal(lead.createdAt)}
                                                </p>
                                            </div>
                                            {lead.travelDate &&
                                                <div className='felx flex-col gap-2'>
                                                    <p className='flex flex-col'>
                                                        <span className='font-semibold text-[12px]'>fecha de viaje:</span>
                                                        {formatDate(lead.travelDate)}
                                                    </p>
                                                </div>
                                            }
                                        </td>
                                        <td onClick={() => handleNaviate(lead.id)}>
                                            <div className='flex flex-col items-start justify-center'>
                                                <p className='flex gap-2 items-center'><FaUser /> {lead.firstName} {lead.lastName}</p>
                                                <p className='flex gap-2 items-center'><FaEarthAmericas /> {lead.country}</p>
                                                <p className='flex gap-2 items-center'><FaMailBulk /> {lead.email}
                                                    {lead.isSuscriptor ?
                                                        <span className="badge border-none flex items-center justify-center text-gray-500 bg-green-400 badge-sm">suscriptor</span> : " "}
                                                </p>
                                                <p className='flex gap-2 items-center'><FaMobile /> {lead.phone}</p>
                                            </div>
                                        </td>
                                        <td onClick={() => handleNaviate(lead.id)}>{lead.message}</td>
                                        <td>
                                            <div className='flex flex-col justify-start items-start'>
                                                {
                                                    lead.saleCompleted ?
                                                        <>
                                                            <p className='flex flex-col text-[12px]'>
                                                                <span className='font-semibold '>Venta concretada</span>
                                                                {formatDate(lead.updatedAt)}
                                                            </p>
                                                            {lead.cancelationDate &&
                                                                <div className='felx flex-col gap-2'>
                                                                    <p className='flex flex-col text-[12px]'>
                                                                        <span className='font-semibold '>Venta cancelada</span>
                                                                        {formatDate(lead.cancelationDate)}
                                                                        <span className='font-semibold text-red-700'>{lead.reasonCancellation}</span>

                                                                    </p>

                                                                </div>
                                                            }
                                                        </>
                                                        :
                                                        <BtnTable
                                                            action="saleCompleted"
                                                            title={t("¿Deseas concretar esta venta?")}
                                                            leadId={lead.id}
                                                            onclick={handleUpdateSatatusLead}
                                                        />
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className='flex justify-start items-center'>
                                                {lead.lead_user === null ?
                                                    <LeadsSubMenu leadId={lead.id} users={userState.users} />
                                                    :
                                                    lead.lead_user?.firstName + " " + lead.lead_user?.lastName}
                                            </div>
                                        </td>
                                        <th>
                                            <div className='flex justify-center items-center'>
                                                <BtnTable action="cancel" leadId={lead.id} onclick={handleUpdateSatatusLead} />
                                            </div>
                                        </th>
                                    </tr>
                                ))}
                            </tbody>
                            <tbody className='md:hidden'>
                                {leadsState.leads.map(lead => (
                                    <tr key={lead.id} className='hover cursor-pointer'>
                                        <td className='flex justify-between items-center border-b-[1px] border-b-gray-300'>
                                            <div onClick={() => handleNaviate(lead.id)}>
                                                <p className='font-semibold'>{lead.firstName} {lead.lastName}</p>
                                                <p className='flex gap-2 items-center'><FaCalendar /> {formatDateToLocal(lead.createdAt)}</p>
                                                <p className='flex gap-2 items-center'><FaMailBulk />{lead.email}</p>
                                                <p className='flex gap-2 items-center'> <FaPhone />{lead.phone}</p>
                                                <p className='flex gap-2 items-center'> <FaUser />{lead.lead_user === null ? 'sin asignar' : lead.lead_user?.firstName + " " + lead.lead_user?.lastName}</p>
                                            </div>
                                            <div>
                                                <LeadsSubMenu leadId={lead.id} users={userState.users} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default LeadsList;