import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
//UI
import MainLoader from '../../../components/Loaders/MainLoader';
import HomeLayout from '../../../layouts/HomeLayout';
import HeaderForm from '../../../components/headers/catalogs/HeaderForm';
import BtnContent from '../../../components/buttons/BtnContent';
import InputCheckboxForm from '../../../components/Inputs/formInput/InputCheckboxForm';
import HeaderSection from '../../../components/headers/catalogs/HeaderSection';
import Error from '../../../components/errors/Error';
// SLICES 
import { getYachtThunk } from '../../../store/slices/catalogs/yacht.slice';
import { getSocialAreaThunk, createSocialAreaThunk, updateSocialAreaThunk, initialStateYachtInformation } from '../../../store/slices/yachts/yachtInfo.slice';
// RESOURCES
import { socialAreas } from '../../../resources/optionsList';
import { API_BASE_URL } from '../../../store/constans';
import { Toast } from '../../../resources/utils';



const SocialAreas = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { yacht_id } = useParams();
    const [selectedImages, setSelectedImages] = useState([]);
    const yachtState = useSelector((state) => state.yachtInformation);
    const yachtname = useSelector((state) => state.yachts)
    const { setValue, handleSubmit, register, formState: { errors } } = useForm();

    useEffect(() => {
        dispatch(getYachtThunk(yacht_id));
        if (yacht_id) {
            dispatch(getSocialAreaThunk(yacht_id));
        }
        return () => {
            dispatch(initialStateYachtInformation());
        };
    }, []);

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('yachtId', yacht_id);
        data.areas.forEach((area, index) => {
            formData.append(`areas[${index}]`, area);
        });
        if (data.images[0]) {
            Array.from(data.images).forEach((file) => {
                formData.append('images', file);
            });
        } else {
            Array.from(yachtState.yachtInformation?.images).forEach((file) => {
                formData.append('images', file);
            });
        }

        if (yachtState.yachtInformation?.id) {
            dispatch(updateSocialAreaThunk(yacht_id, formData));
        } else {
            dispatch(createSocialAreaThunk(formData));
        }
    };

    if (yachtState.message === "resource created successfully" || yachtState.message === "resource updated successfully") {
        Toast.fire({
            icon: 'success',
            title: yachtState.message
        })
        dispatch(getSocialAreaThunk(yacht_id));

    }

    if (yachtState.yachtInformation instanceof Object) {
        if (Object.keys(yachtState.yachtInformation).length !== 0) {
            const areas = yachtState.yachtInformation?.areas?.map(area => {
                return area
            })
            setValue('areas', areas)
        }
    }


    const handleImageChange = (event) => {
        const files = event.target.files;
        const fileArray = Array.from(files).map(file => URL.createObjectURL(file));
        setSelectedImages(prevImages => prevImages.concat(fileArray));
    };

    return (
        <HomeLayout>
            {yachtState.fetching || yachtState.processing ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {yachtState.error ? <Error state={yachtState.error} /> : " "}
                    <HeaderForm title={`Social Areas - ${yachtname.yacht.name}`} yacht_id={yacht_id} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex gap-2 p-2 mb-8'>
                            <InputCheckboxForm
                                type="checkbox"
                                label={"Social Areas Outline"}
                                spam={true}
                                cols={1}
                                options={socialAreas}
                                register={register("areas", { required: true })}
                                errors={errors.areas && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                            />
                        </div>
                        <HeaderSection title={"Gallery"} />
                        <div className='flex flex-col gap-2 mb-5 items-center font-semibold'>
                            <div className='flex gap-3 mt-5 items-center'>
                                <label className="text-sm flex m-1">Images</label>
                                <div className='flex flex-col gap-2'>
                                    <input {...register('images')} onChange={handleImageChange} type="file" multiple accept="image/*" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                </div>
                            </div>
                            {yachtState.yachtInformation && selectedImages.length === 0 ?
                                <>
                                    <div className='p-2 grid grid-cols-5 gap-2'>
                                        {yachtState.yachtInformation.images?.map((image, index) => (
                                            <img key={index} src={API_BASE_URL + image} alt={`Selected ${index}`} className="w-24 h-24 rounded-md" />
                                        ))}
                                    </div>
                                </> :
                                selectedImages ?
                                    <>
                                        <div className='p-2 grid grid-cols-5 gap-2'>
                                            {selectedImages.map((image, index) => (
                                                <img key={index} src={image} alt={`Selected ${index}`} className="w-24 h-24 rounded-md" />
                                            ))}
                                        </div>
                                    </>
                                    : " "}
                        </div>
                        <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                            <BtnContent type="submit">{t("save_button")}</BtnContent>
                            <BtnContent cancel={true} to={'/panel/yachts'}>{t("canceled_button")}</BtnContent>
                        </div>
                    </form>
                </div>
            )}
        </HomeLayout>
    );
};

export default SocialAreas;