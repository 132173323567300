let days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

let years = [
  { name: "2024" },
  { name: "2025" },
  { name: "2026" },
  { name: "2027" },
  { name: "2028" },
  { name: "2029" },
  { name: "2030" },
];

let itineraryRoutes = [
  "West Route",
  "East Route",
  "West & East Route",
];

let itineraryRouteOptions = [
  "Option 1",
  "Option 2",
  "Options 1 & 2"
];

let socialAreas = [
  "Observations & Sun deck",
  "Bar",
  "Salon room",
  "Dining room",
  "Sun deck",
  "Bar & Salon",
  "Comfortable Lounge",
  "Lounge",
];

let Islands = [
  "Baltra",
  "Española",
  "Genovesa",
  "Mosquera",
  "Rabida",
  "Santa Fe",
  "Bartolome",
  "Fernandina",
  "Isabela",
  "North Seymour",
  "San Cristobal",
  "Santiago",
  "Chinese Hat",
  "Floreana",
  "Lobos",
  "Plaza",
  "Santa Cruz",
];

const links = {
  "tiptopii": "https://api.whatsapp.com/send?phone=593987987497&text=Hi%20Rolf%20Wittmer,%20I%20want%20more%20information%20about%20Tip%20Top%20II%20Cruise",
  "tiptopiv": "https://api.whatsapp.com/send?phone=593987987497&text=Hi%20Rolf%20Wittmer,%20I%20want%20more%20information%20about%20Tip%20Top%20IV%20Cruise",
  "tiptopv": "https://api.whatsapp.com/send?phone=593987987497&text=Hi%20Rolf%20Wittmer,%20I%20want%20more%20information%20about%20Tip%20Top%20V%20Cruise",
  "koln": "https://api.whatsapp.com/send?phone=593987987497&text=Hi%20Rolf%20Wittmer,%20I%20want%20more%20information%20about%20K%C3%96LN%20Cruise",
}

const countries = [

  {
    value: "Argentina",
    label: "Argentina"
  },
  {
    value: "Australia",
    label: "Australia"
  },
  {
    value: "Austria",
    label: "Austria"
  },
  {
    value: "Belgium",
    label: "Bélgica"
  },
  {
    value: "Canada",
    label: "Canadá"
  },
  {
    value: "China",
    label: "China"
  },
  {
    value: "Colombia",
    label: "Colombia"
  },
  {
    value: "Costa Rica",
    label: "Costa Rica"
  },
  {
    value: "Croatia",
    label: "Croacia"
  },
  {
    value: "Denmark",
    label: "Dinamarca"
  },
  {
    value: "Dominican Republic",
    label: "República Dominicana"
  },
  {
    value: "Ecuador",
    label: "Ecuador"
  },
  {
    value: "Egypt",
    label: "Egipto"
  },
  {
    value: "El Salvador",
    label: "El Salvador"
  },
  {
    value: "France",
    label: "Francia"
  },
  {
    value: "Germany",
    label: "Alemania"
  },
  {
    value: "Greece",
    label: "Grecia"
  },
  {
    value: "Guatemala",
    label: "Guatemala"
  },
  {
    value: "Honduras",
    label: "Honduras"
  },
  {
    value: "Hong Kong",
    label: "Hong Kong"
  },
  {
    value: "Iceland",
    label: "Islandia"
  },
  {
    value: "India",
    label: "India"
  },
  {
    value: "Indonesia",
    label: "Indonesia"
  },
  {
    value: "Iran, Islamic Republic Of",
    label: "Irán"
  },
  {
    value: "Iraq",
    label: "Iraq"
  },
  {
    value: "Israel",
    label: "Israel"
  },
  {
    value: "Italy",
    label: "Italia"
  },
  {
    value: "Jamaica",
    label: "Jamaica"
  },
  {
    value: "Japan",
    label: "Japón"
  },
  {
    value: "Korea, Democratic People'S Republic of",
    label: "Corea del Norte"
  },
  {
    value: "Korea, Republic of",
    label: "Corea del Sur"
  },
  {
    value: "Mexico",
    label: "México"
  },
  {
    value: "Monaco",
    label: "Mónaco"
  },
  {
    value: "Morocco",
    label: "Marruecos"
  },
  {
    value: "Netherlands",
    label: "Holanda"
  },
  {
    value: "New Zealand",
    label: "Nueva Zelanda"
  },
  {
    value: "Nicaragua",
    label: "Nicaragua"
  },
  {
    value: "Nigeria",
    label: "Nigeria"
  },
  {
    value: "Norway",
    label: "Noruega"
  },
  {
    value: "Panama",
    label: "Panamá"
  },
  {
    value: "Paraguay",
    label: "Paraguay"
  },
  {
    value: "Peru",
    label: "Perú"
  },
  {
    value: "Poland",
    label: "Polonia"
  },
  {
    value: "Portugal",
    label: "Portugal"
  },
  {
    value: "Puerto Rico",
    label: "Puerto Rico"
  },
  {
    value: "Qatar",
    label: "Qatar"
  },
  {
    value: "Russian Federation",
    label: "Rusia"
  },
  {
    value: "Saudi Arabia",
    label: "Arabia Saudita"
  },
  {
    value: "Singapore",
    label: "Singapur"
  },
  {
    value: "South Africa",
    label: "Sudáfrica"
  },
  {
    value: "Spain",
    label: "España"
  },
  {
    value: "Sweden",
    label: "Suecia"
  },
  {
    value: "Switzerland",
    label: "Suiza"
  },
  {
    value: "Turkey",
    label: "Turquía"
  },
  {
    value: "United Arab Emirates",
    label: "Emiratos Árabes Unidos"
  },
  {
    value: "United Kingdom",
    label: "Reino Unido"
  },
  {
    value: "United States",
    label: "Estados Unidos"
  },
  {
    value: "Uruguay",
    label: "Uruguay"
  },
  {
    value: "Venezuela",
    label: "Venezuela"
  },
]

const positions = [
  { id: 1, name: 'izquierda' },
  { id: 2, name: 'derecha' },
  { id: 3, name: 'centro' },
  { id: 4, name: 'izquierda azul' },
  { id: 5, name: 'cuadro azul' },
  { id: 6, name: 'blog seccion 1' },
  { id: 7, name: 'blog seccion 2' },
  { id: 8, name: 'blog seccion 3' },
]

export {
  days,
  years,
  countries,
  socialAreas,
  itineraryRoutes,
  itineraryRouteOptions,
  Islands,
  links,
  positions
};
