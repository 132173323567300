import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//UI
import MainLoader from '../../../components/Loaders/MainLoader';
import HomeLayout from '../../../layouts/HomeLayout';
import Error from '../../../components/errors/Error';
import BreadTwoParts from '../../../components/breadcrumbs/BreadTwoParts';
import AvailabilityModal from '../../../components/modals/AvailabilityModal';
// SLICES 
import { getYachtThunk, initialStateYacht } from '../../../store/slices/catalogs/yacht.slice';
import { deleteAvailabilityThunk } from '../../../store/slices/availability/proceduresAvailability.slice'
// RESOURCES
import { getAvailabilityByYachtThunk, initialStateAvailability } from '../../../store/slices/availability/availability.slice';
import BtnTable from '../../../components/buttons/BtnTable';
import UpdateAvailabilityModal from '../../../components/modals/UpdateAvailabilityModal';

const AvailabilityForm = () => {

    const dispatch = useDispatch();
    const { yacht_id } = useParams();
    const [selectedYear, setSelectedYear] = useState(''); // Inicializa con el primer año disponible
    const yachtState = useSelector((state) => state.yachts);
    const availabilityState = useSelector(state => state.availability)
    const procedureAvailabilityState = useSelector(state => state.proceduresAvailability)

    useEffect(() => {
        if (yacht_id) {
            dispatch(getAvailabilityByYachtThunk(yacht_id))
            dispatch(getYachtThunk(yacht_id));
        }
        return () => {
            dispatch(initialStateYacht());
            dispatch(initialStateAvailability());
        };
    }, []);

    useEffect(() => {
        setSelectedYear(availabilityState.availabilitys[0]?.id)
    }, [availabilityState.availabilitys])

    const handleTabChange = (e) => {
        setSelectedYear(e.target.value);
    };

    if (procedureAvailabilityState.message === 'resource created successfully' || procedureAvailabilityState.message === 'resource updated successfully') {
        dispatch(getAvailabilityByYachtThunk(yacht_id))
    }

    if (procedureAvailabilityState.message === "resource deleted successfully") {
        dispatch(getAvailabilityByYachtThunk(yacht_id))
    }

    const handleDelete = (year_id) => {
        dispatch(deleteAvailabilityThunk(year_id));
    };

    return (
        <HomeLayout>
            {yachtState.fetching || availabilityState.fetching ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {yachtState.error ? <Error state={yachtState.error} /> : " "}
                    <BreadTwoParts titleOne={"Availability"} toOne={'/panel/availability'} titleTwo={yachtState.yacht?.name} />
                    <div className='p-2 absolute top-14 right-8 my-3'>
                        <AvailabilityModal />
                    </div>
                    <div className='md:h-[85%] 2xl:h-[90%] overflow-y-scroll contenedor mt-5'>
                        <div role="tablist" className="tabs tabs-lifted">
                            {
                                availabilityState.availabilitys.map(years => (
                                    <React.Fragment key={years.id}>
                                        <div className=' absolute flex top-[76px] right-[76px]'>
                                            <UpdateAvailabilityModal year={selectedYear}/>
                                            <BtnTable title={"¿Do you want to delete all availability?"} action="delete" onclick={() => handleDelete(selectedYear)}  />
                                        </div>
                                        <input
                                            type="radio"
                                            name="my_tabs_2"
                                            role="tab"
                                            className="tab [--tab-bg:#2c6a80] [--tab-border-color:white] font-semibold text-white bg-orange-500"
                                            aria-label={years.name}
                                            value={years.id}
                                            checked={selectedYear === years.id}
                                            onChange={handleTabChange}
                                        />
                                        {
                                            selectedYear === years.id && (
                                                <div role="tabpanel" className="tab-content">
                                                    <table className="text-[13px] border border-[#2c6a80] table-sm w-full text-start">
                                                        <thead className='' >
                                                            <tr className='text-left h-[50px] bg-[#2c6a80] text-white'>
                                                                <th>IN</th>
                                                                <th>OUT</th>
                                                                <th>SPACES</th>
                                                                <th>CABINS</th>
                                                                <th className='w-[300px]'>ITINERARY</th>
                                                                <th className='w-[100px]'>MAIN/LOWER GROSS</th>
                                                                <th className='w-[100px]'>MAIN/LOWER LM</th>
                                                                <th className='w-[100px]'>UPPER DECK GROOS</th>
                                                                <th className='w-[100px]'>UPPER DECK LM</th>
                                                                <th>CONDITIONS</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                years.availability.map(item => (
                                                                    <tr className='text-[#2c6a80] border-b-[1px] border-b-[#2c6a80]' key={item.id}>
                                                                        <td className='border-l-[1px] border-l-[#2c6a80]'>{item.in}</td>
                                                                        <td className='border-l-[1px] border-l-[#2c6a80]'>{item.out}</td>
                                                                        <td className='border-l-[1px] border-l-[#2c6a80]'>{item.spaces}</td>
                                                                        <td className='border-l-[1px] border-l-[#2c6a80]'>{item.cabins}</td>
                                                                        <td className='border-l-[1px] border-l-[#2c6a80]'>{item.itinerary}</td>
                                                                        <td className='border-l-[1px] border-l-[#2c6a80]'>${item.mainLowerGross}</td>
                                                                        <td className='border-l-[1px] border-l-[#2c6a80]'>${item.mainLowerLm}</td>
                                                                        <td className='border-l-[1px] border-l-[#2c6a80]'>${item.upperGross}</td>
                                                                        <td className='border-l-[1px] border-l-[#2c6a80]'>${item.upperLm}</td>
                                                                        <td className='border-l-[1px] border-l-[#2c6a80]'>{item.conditions}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )
                                        }

                                    </React.Fragment>
                                ))
                            }
                        </div>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default AvailabilityForm;