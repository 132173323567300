import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
//UIO
import { FaPlus, FaSave, FaTrashAlt } from 'react-icons/fa';
import HomeLayout from '../../../layouts/HomeLayout';
import MainLoader from '../../../components/Loaders/MainLoader';
import BtnContent from '../../../components/buttons/BtnContent';
import InputDeckForm from '../../../components/Inputs/formInput/InputDeckForm';
import Error from '../../../components/errors/Error';
import HeaderForm from '../../../components/headers/catalogs/HeaderForm';
import TextAreaForm from '../../../components/Inputs/formInput/TextAreaForm';
//SLICE
import { getDestinationThunk } from '../../../store/slices/catalogs/destination.slice';
import { createSitesThunk, createOneSiteThunk, updateOneSiteThunk, updateSitesThunk, getSitesThunk, initialStateSite, deleteSiteThunk } from "../../../store/slices/catalogs/sites.slice";
//RESOURCES 
import { Toast } from '../../../resources/utils';
import { API_BASE_URL } from '../../../store/constans';

const IslandSites = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { destination_id } = useParams();
    const [site, setSite] = useState([]);
    const sitesState = useSelector((state) => state.sites);
    const destinationState = useSelector((state) => state.destinations);

    useEffect(() => {
        dispatch(getDestinationThunk(destination_id));
        if (destination_id) {
            dispatch(getSitesThunk(destination_id));
        }
        return () => {
            dispatch(initialStateSite());
        }
    }, []);

    useEffect(() => {
        if (sitesState.sites instanceof Array) {
            if (sitesState.sites.length > 0) {
                setSite(
                    sitesState.sites?.map(site => ({
                        id: site.id,
                        title: site.title,
                        description: site.description,
                        preview: API_BASE_URL + site.image
                    }))
                )
            }
        }
    }, [sitesState.sites])

    const onSubmit = (data) => {
        const formData = new FormData();
        site.forEach((pregunta) => {
            if (pregunta.id) {
                formData.append('id', pregunta.id);
            }
            formData.append('destinationId', destination_id);
            formData.append('title', pregunta.title);
            formData.append('description', pregunta.description);
            if (pregunta.image) {
                formData.append(`image_${pregunta.id}`, pregunta.image);
            } else {
                const existingSite = sitesState.sites.find(s => s.id === pregunta.id);
                if (existingSite) {
                    formData.append(`image_${pregunta.id}`, existingSite.image);
                }
            }
        });

        if (site.length === 1) {
            if (sitesState.sites.length > 0) {
                dispatch(updateOneSiteThunk(destination_id, formData))
            } else {
                dispatch(createOneSiteThunk(formData))
            }
        } else {
            if (sitesState.sites.length > 0) {
                dispatch(updateSitesThunk(destination_id, formData))
            } else {
                dispatch(createSitesThunk(formData))
            }
        }

    }

    const handleAddSite = () => {
        setSite([...site, { id: '', destinationId: destination_id, title: '', description: '', image: '', preview: null }]);
    };

    const handleEliminar = (index) => {
        const nuevasPreguntas = [...site];
        nuevasPreguntas.splice(index, 1);
        setSite(nuevasPreguntas);
    };

    const handleChageTitle = (index, event) => {
        const nuevasPreguntas = [...site];
        nuevasPreguntas[index].title = event.target.value;
        setSite(nuevasPreguntas);
    };

    const handleChageDescription = (index, event) => {
        const nuevasPreguntas = [...site];
        nuevasPreguntas[index].description = event.target.value;
        setSite(nuevasPreguntas);
    };

    if (sitesState.message === "resource created successfully" || sitesState.message === "resource updated successfully") {
        Toast.fire({
            icon: 'success',
            title: sitesState.message
        })
        dispatch(getSitesThunk(destination_id));
        setSite([]);
    }

    if (sitesState.message === "resource deleted successfully") {
        dispatch(getSitesThunk(destination_id));
    }

    const handleImageChange = (index, event) => {
        const file = event.target.files[0];
        const nuevasPreguntas = [...site];
        nuevasPreguntas[index].image = file;

        const reader = new FileReader();
        reader.onload = () => {
            nuevasPreguntas[index].preview = reader.result;
            setSite(nuevasPreguntas);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleDelete = (site_id) => {
        dispatch(deleteSiteThunk(site_id))
    }

    const handleSave = () => {
        const formData = new FormData();
        const newSite = site.filter(site => site.id === "")
        newSite.forEach((pregunta) => {
            if (pregunta.id) {
                formData.append('id', pregunta.id);
            }
            formData.append('destinationId', destination_id);
            formData.append('title', pregunta.title);
            formData.append('description', pregunta.description);
            formData.append(`image`, pregunta.image);
        });

        dispatch(createOneSiteThunk(formData))
    }

    return (
        <HomeLayout>
            {sitesState.fetching || sitesState.processing ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {sitesState.error ? <Error state={sitesState.error} /> : " "}
                    <HeaderForm title={`Island Sites - ${destinationState.destination?.name}`} />
                    <div className='flex flex-col mb-5 text-start'>
                        <div className='flex border p-2 justify-between'>
                            <h1 className='font-semibold'>Island Sites</h1>
                            <div className='w-[80%] rounded-md flex flex-col gap-3' >
                                {site.map((pregunta, index) => (
                                    <div className='w-full shadow-md shadow-gray-300 bg-gray-300/20 rounded-md py-2 border flex flex-col' key={index}>
                                        <h1 className='text-center font-semibold'>{pregunta.title}</h1>
                                        <div className='flex gap-2 p-2'>
                                            <InputDeckForm
                                                type="text"
                                                label={t("Site Name")}
                                                input="input"
                                                spam={true}
                                                cols={1}
                                                value={pregunta.title}
                                                onChange={(event) => handleChageTitle(index, event)}
                                            />
                                        </div>
                                        <div className='flex gap-2 p-2 mb-5'>
                                            <div className='flex flex-col gap-2 p-2 justify-center'>
                                                <label className="text-sm flex m-1">Imágen<span className="text-red-500">*</span></label>
                                                <div className='flex flex-col gap-2'>
                                                    {pregunta.preview ?
                                                        <img className='h-32 w-42 rounded-md' src={pregunta.preview} alt={`Preview ${index}`} /> : ""}
                                                    <input type="file" onChange={(event) => handleImageChange(index, event)} accept="image/*" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                                </div>
                                            </div>
                                            <TextAreaForm
                                                type="text"
                                                label={t("Island Description")}
                                                input="input"
                                                spam={true}
                                                cols={1}
                                                value={pregunta.description}
                                                placeholder={t("Island Description")}
                                                onChange={(event) => handleChageDescription(index, event)}
                                            />
                                        </div>
                                        {pregunta.id ?
                                            <div className='p-2 justify-end w-full flex'>
                                                <button
                                                    type="button"
                                                    className='hover:bg-red-300/40 px-3 text-[14px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                                    onClick={() => handleDelete(pregunta.id)}>
                                                    <FaTrashAlt />Eliminar item
                                                </button>
                                            </div>
                                            :
                                            <>
                                                <div className='p-2 justify-end w-full flex'>
                                                    <button
                                                        type="button"
                                                        className='hover:bg-red-300/40 px-3 text-[14px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                                        onClick={() => handleEliminar(index)}>
                                                        <FaTrashAlt />Eliminar item
                                                    </button>
                                                </div>
                                                
                                                <div className='p-2 justify-end w-full flex'>
                                                    <button
                                                        type="button"
                                                        className='hover:bg-green-300/40 px-3 text-[14px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                                        onClick={handleSave}>
                                                        <FaSave />Guardar item
                                                    </button>
                                                </div>
                                            </>

                                        }
                                    </div>
                                ))}
                                <div className='p-2 justify-center w-full flex my-3'>
                                    <button
                                        type="button"
                                        className='hover:bg-green-300/40 text-[15px] font-semibold transition-all gap-1 p-1 px-2 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                        onClick={handleAddSite}><FaPlus />Agregar item</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                        <BtnContent type="initDay" funtion={() => onSubmit()}>{t("save_button")}</BtnContent>
                        <BtnContent cancel={true} to={'/panel/destinations'}>{t("canceled_button")}</BtnContent>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default IslandSites;