import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
//UI
import HomeLayout from '../../../layouts/HomeLayout';
import BtnTable from '../../../components/buttons/BtnTable';
import HeaderSimple from '../../../components/headers/catalogs/HeaderSimple';
import MainLoader from '../../../components/Loaders/MainLoader';
import Error from '../../../components/errors/Error';
//SLICE
import { getDestinationsThunk, deleteDestinationThunk } from '../../../store/slices/catalogs/destination.slice';
//RESOURCES
import { API_BASE_URL } from '../../../store/constans';



const DestinationList = () => {

    const { t } = useTranslation();
    const destinationState = useSelector(state => state.destinations);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDestinationsThunk());
    }, []);

    if (destinationState.message === "resource deleted successfully") {
        dispatch(getDestinationsThunk());
    }

    const handleDelete = (yacht_id) => {
        dispatch(deleteDestinationThunk(yacht_id));
    };

    return (
        <HomeLayout>
            {destinationState.fetching || destinationState.processing ? (
                <MainLoader />
            ) : (
                <div className='mx-5 my-5 w-full'>
                    {destinationState.error ? <Error state={destinationState.error} /> : " "}
                    <HeaderSimple title={"Destinations"} to={`/panel/destinations_new`} />
                    <div className="overflow-y-scroll h-[87%] contenedor">
                        <table className="text-[13px] table-sm w-full text-start">
                            <thead className='border-t-2 border-t-[#2c6a80]' >
                                <tr className='text-left h-[50px] bg-[#c2c2c2]/20'>
                                    <th>{t("yacht_name")}</th>
                                    <th>{t("yacht_pick")}</th>
                                    <th>{t("Island Sites")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {destinationState.destinations.map(destination => (
                                    <tr key={destination.id} className='border-b-[2px] duration-75'>
                                        <td >{destination.name}</td>
                                        <td >
                                            <img className='rounded-md h-14 w-24 ' src={`${API_BASE_URL}${destination.image}`} alt={destination.name} />
                                        </td>
                                        <td >

                                            {destination.sites.length >= 1 ?
                                                <Link to={`/panel/destinations/${destination.id}/island_sites`}>
                                                    <div className="avatar-group -space-x-6 rtl:space-x-reverse">

                                                        {destination.sites.map((gallery, galleryIndex) => (
                                                            <div key={galleryIndex} className="avatar border-[#c3c9ce] border-[3px]">
                                                                <div className="w-12">
                                                                    <img src={`${API_BASE_URL}${gallery.image}`} alt={`Gallery image ${galleryIndex + 1}`} />
                                                                </div>
                                                            </div>

                                                        ))}
                                                    </div>
                                                </Link>
                                                :
                                                <div className='pl-7'>
                                                    <BtnTable action="pluss" to={`/panel/destinations/${destination.id}/island_sites`} />
                                                </div>
                                            }
                                        </td>
                                        <td >
                                            <div className='flex gap-1 justify-end'>
                                                <BtnTable action="edit" to={`/panel/destinations/${destination.id}`} />
                                                <BtnTable title={t("destination_delete")} action="delete" onclick={() => handleDelete(destination.id)} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default DestinationList;