import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
//UI
import MainLoader from '../../../components/Loaders/MainLoader';
import HomeLayout from '../../../layouts/HomeLayout';
import HeaderForm from '../../../components/headers/catalogs/HeaderForm';
import BtnContent from '../../../components/buttons/BtnContent';
import InputCheckCrewsForm from '../../../components/Inputs/formInput/InputCheckCrewsForm';
import Error from '../../../components/errors/Error';
// SLICES 
import { getYachtThunk } from '../../../store/slices/catalogs/yacht.slice';
import { getCrewsThunk } from '../../../store/slices/catalogs/crew.slice';
import { getCrewsOnBoardThunk, createCrewsOnBoardThunk, updateCrewsOnBoardThunk, initialStateYachtInformation } from '../../../store/slices/yachts/yachtInfo.slice';
// RESOURCES
import { Toast } from '../../../resources/utils';




const CrewsOnBoard = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { yacht_id } = useParams();
    const yachtState = useSelector((state) => state.yachtInformation);
    const yachtname = useSelector((state) => state.yachts)
    const crewState = useSelector(state => state.crews);   
    const { setValue, handleSubmit, register, formState: { errors } } = useForm();

    useEffect(() => {
        dispatch(getYachtThunk(yacht_id));
        if (yacht_id) {
            dispatch(getCrewsOnBoardThunk(yacht_id));
            dispatch(getCrewsThunk());
        }
        return () => {
            dispatch(initialStateYachtInformation());
        };
    }, []);

    const onSubmit = (data) => {
        data.yachtId = yacht_id
        if (yachtState.yachtInformation?.id) {
            data.id = yachtState.yachtInformation?.id
            dispatch(updateCrewsOnBoardThunk(yacht_id, data));
        } else {
            dispatch(createCrewsOnBoardThunk(data));
        }
    };

    if (yachtState.message === "resource created successfully" || yachtState.message === "resource updated successfully") {
        Toast.fire({
            icon: 'success',
            title: yachtState.message
        })
        dispatch(getCrewsOnBoardThunk(yacht_id));

    }

    if (yachtState.yachtInformation instanceof Object) {
        if (Object.keys(yachtState.yachtInformation).length !== 0) {
            const crews = yachtState.yachtInformation.crews?.map(crew => {
                return crew
            })
            setValue('crews', crews)
        }
    }

    const nuevoArreglo = crewState.crews.slice(1);

    return (
        <HomeLayout>
            {yachtState.fetching || yachtState.processing ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {yachtState.error ? <Error state={yachtState.error} /> : " "}
                    <HeaderForm title={`Crews On Board - ${yachtname.yacht.name}`} yacht_id={yacht_id} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex gap-2 p-2 mb-8'>
                            <InputCheckCrewsForm
                                type="checkbox"
                                label={"Crews"}
                                spam={true}
                                cols={1}
                                options={nuevoArreglo}
                                register={register("crews", { required: true })}
                                errors={errors.crews && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                            />
                        </div>
                        <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                            <BtnContent type="submit">{t("save_button")}</BtnContent>
                            <BtnContent cancel={true} to={'/panel/yachts'}>{t("canceled_button")}</BtnContent>
                        </div>
                    </form>
                </div>
            )}
        </HomeLayout>
    );
};

export default CrewsOnBoard;