import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut } from '../../../resources/utils';

export const recomendationSlice = createSlice({
    name: 'recomendations',
    initialState: {
        recomendations: [],
        recomendation: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
    },
    reducers: {
        initialStateRecomendation(state, action) {
            return {
                recomendations: [],
                recomendation: {},
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        requestFetchRecomendations(state, action) {
            return {
                recomendations: [],
                recomendation: {},
                processing: false,
                fetching: true,
                message: "",
                error: "",
            }
        },
        fetchRecomendationsSuccess(state, action) {
            return {
                recomendations: action.payload,
                recomendation: {},
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        fetchRecomendationsError(state, action) {
            return {
                recomendations: [],
                recomendation: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestCreateRecomendation(state, action) {
            return {
                recomendations: [],
                recomendation: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        createRecomendationsuccess(state, action) {
            return {
                recomendations: [],
                recomendation: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        createRecomendationError(state, action) {
            return {
                recomendations: [],
                recomendation: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestUpdateRecomendation(state, action) {
            return {
                recomendations: [],
                recomendation: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        updateRecomendationsuccess(state, action) {
            return {
                recomendations: [],
                recomendation: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        updateRecomendationError(state, action) {
            return {
                recomendations: [],
                recomendation: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestDeleteRecomendation(state, action) {
            return {
                recomendations: [],
                recomendation: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        deleteRecomendationsuccess(state, action) {
            return {
                recomendations: [],
                recomendation: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        deleteRecomendationError(state, action) {
            return {
                recomendations: [],
                recomendation: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },

    }
})

export const getRecomendationsByPageThunk = (page) => dispatch => {
    dispatch(requestFetchRecomendations())
    axios.get(`/recomendations/getRecomendationsByPage/${page}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(fetchRecomendationsSuccess(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(fetchRecomendationsError(res.response.data))
            }
        })
};


export const createRecomendationsThunk = (data, file) => dispatch => {
    dispatch(requestCreateRecomendation())
    axios.post(`/recomendations/createRecomendations?images=${file}`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(createRecomendationsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(createRecomendationError(res.response.data))
            }
        })
};

export const updateRecomendationsThunk = (page, file, data) => dispatch => {
    dispatch(requestUpdateRecomendation())
    axios.put(`/recomendations/updateRecomendations/${page}?images=${file}`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(updateRecomendationsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(updateRecomendationError(res.response.data))
            }
        })
};

export const createOneRecomendationThunk = (data, file) => dispatch => {
    dispatch(requestCreateRecomendation())
    axios.post(`/recomendations/createOneRecomendation?images=${file}`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(createRecomendationsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(createRecomendationError(res.response.data))
            }
        })
};

export const updateOneRecomendationThunk = (recomendation_id, data) => dispatch => {
    dispatch(requestUpdateRecomendation())
    axios.put(`/recomendations/updateOneRecomendation/${recomendation_id}?images=${file}`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(updateRecomendationsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(updateRecomendationError(res.response.data))
            }
        })
};

export const deleteRecomendationThunk = (recomendation_id) => dispatch => {
    dispatch(requestDeleteRecomendation())
    axios.delete(`/recomendations/${recomendation_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(deleteRecomendationsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(deleteRecomendationError(res.response.data))
            }
        })
};



export const { initialStateRecomendation,
    requestFetchRecomendations,
    fetchRecomendationsSuccess,
    fetchRecomendationsError,
    requestFetchRecomendation,
    fetchRecomendationSuccess,
    fetchRecomendationError,
    requestCreateRecomendation,
    createRecomendationsuccess,
    createRecomendationError,
    requestUpdateRecomendation,
    updateRecomendationsuccess,
    updateRecomendationError,
    requestDeleteRecomendation,
    deleteRecomendationsuccess,
    deleteRecomendationError,
} = recomendationSlice.actions;

export default recomendationSlice.reducer;