import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
//UI
import HomeLayout from '../../../layouts/HomeLayout';
import BtnTable from '../../../components/buttons/BtnTable';
import HeaderSimple from '../../../components/headers/catalogs/HeaderSimple';
import MainLoader from '../../../components/Loaders/MainLoader';
import YachtSubMenu from '../../../components/submenus/YachtSubMenu';
import Error from '../../../components/errors/Error';
//SLICE
import { getYachtsThunk, deleteYachtThunk } from '../../../store/slices/catalogs/yacht.slice';
//RESOURCES
import { API_BASE_URL } from '../../../store/constans';

const AvailabilityList = () => {

    const { t } = useTranslation();
    const yachtState = useSelector(state => state.yachts);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getYachtsThunk());
    }, []);

    if (yachtState.message === "resource deleted successfully") {
        dispatch(getYachtsThunk());
    }

    const handleDelete = (yacht_id) => {
        dispatch(deleteYachtThunk(yacht_id));
    };

    return (
        <HomeLayout>
            {yachtState.fetching || yachtState.processing ? (
                <MainLoader />
            ) : (
                <div className='mx-5 my-5 w-full'>
                    {yachtState.error ? <Error state={yachtState.error} /> : " "}
                    <HeaderSimple title={t("yacht_title")} to={`/panel/yachts_new`} />
                    <div className="overflow-y-scroll h-[87%] contenedor">
                        <table className="text-[13px] table-sm w-full text-start">
                            <thead className='border-t-2 border-t-[#2c6a80]' >
                                <tr className='text-left h-[50px] bg-[#c2c2c2]/20'>
                                    <th>{t("yacht_name")}</th>
                                    <th>{t("yacht_initials")}</th>
                                    <th>{t("Background")}</th>
                                    <th>{t("Full Screen Gallery")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {yachtState.yachts.map(yacht => (
                                    <tr key={yacht.id} className='border-b-[2px] duration-75'>
                                        <td >
                                            <p className='font-semibold'>{yacht.name}</p>
                                        </td>
                                        <td >{yacht.code}</td>
                                        <td >
                                            <img className='rounded-md w-16 h-14' src={`${API_BASE_URL}${yacht.image}`} alt={yacht.name} />
                                        </td>
                                        <td >
                                            {yacht.full_gallery.length >= 1 ?
                                                <Link to={`/panel/yacht/${yacht.id}/full_screen_gallery`}>
                                                    {yacht.full_gallery.map((gallery, galleryIndex) => (
                                                        <div key={galleryIndex} className="avatar-group -space-x-6 rtl:space-x-reverse">
                                                            {gallery.images.map((image, imageIndex) => (
                                                                <div key={imageIndex} className="avatar border-[#c3c9ce] border-[3px]">
                                                                    <div className="w-12">
                                                                        <img src={`${API_BASE_URL}${image}`} alt={`Gallery image ${imageIndex + 1}`} />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </Link>
                                                :
                                                <div className='pl-7'>
                                                    <BtnTable action="pluss" to={`/panel/yacht/${yacht.id}/full_screen_gallery`} />
                                                </div>
                                            }
                                        </td>
                                        <td >
                                            <div className='flex gap-1 justify-end'>
                                                <YachtSubMenu id={yacht.id} />
                                                <BtnTable action="edit" to={`/panel/yachts/${yacht.id}`} />
                                                <BtnTable title={t("yacht_delete")} action="delete" onclick={() => handleDelete(yacht.id)} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default AvailabilityList;