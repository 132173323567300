import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
//UI
import MainLoader from '../../../components/Loaders/MainLoader';
import HomeLayout from '../../../layouts/HomeLayout';
import HeaderForm from '../../../components/headers/catalogs/HeaderForm';
import BtnContent from '../../../components/buttons/BtnContent';
// SLICES 
import { getYachtThunk } from '../../../store/slices/catalogs/yacht.slice';
import { createSafetyCommunicationsThunk, getSafetyCommunicationsThunk, initialStateYachtInformation, updateSafetyCommunicationsThunk } from '../../../store/slices/yachts/yachtInfo.slice';
// RESOURCES
import Error from '../../../components/errors/Error';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { Toast } from '../../../resources/utils';


const SafetyCommunications = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { yacht_id } = useParams();
    const yachtState = useSelector((state) => state.yachtInformation);
    const yachtname = useSelector((state) => state.yachts)
    const [safety, setSafety] = useState([{ id: '', yachtId: '', commets: [''] }]);
    const { handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        dispatch(getYachtThunk(yacht_id));
        if (yacht_id) {
            dispatch(getSafetyCommunicationsThunk(yacht_id));
        }
        return () => {
            dispatch(initialStateYachtInformation());
        };
    }, []);

    useEffect(() => {
        if (yachtState.yachtInformation instanceof Array) {
            if (yachtState.yachtInformation[0] !== null) {
                setSafety(
                    yachtState.yachtInformation?.map(commet => ({
                        id: commet?.id,
                        yachtId: commet.yachtId,
                        commets: commet.commets?.map((opcion) => opcion)
                    }))
                )
            }
        }
    }, [yachtState])

    const onSubmit = (data) => {
        data = safety
        data[0].yachtId = yacht_id
        if (safety[0].id) {
            dispatch(updateSafetyCommunicationsThunk(yacht_id, data));
        } else {
            dispatch(createSafetyCommunicationsThunk(data));
        }
    };

    const handleAgregarOpcion = (index) => {
        const nuevasPreguntas = [...safety];
        nuevasPreguntas[index].commets.push('');
        setSafety(nuevasPreguntas);
    };

    const handleEliminarOpcion = (preguntaIndex, opcionIndex) => {
        const nuevasPreguntas = [...safety];
        nuevasPreguntas[preguntaIndex].commets.splice(opcionIndex, 1);
        setSafety(nuevasPreguntas);
    };

    const handleCambiarOpcion = (preguntaIndex, opcionIndex, event) => {
        const nuevasPreguntas = [...safety];
        nuevasPreguntas[preguntaIndex].commets[opcionIndex] = event.target.value;
        setSafety(nuevasPreguntas);
    };

    if (yachtState.message === "resource created successfully" || yachtState.message === "resource updated successfully") {
        Toast.fire({
            icon: 'success',
            title: yachtState.message
        })
        dispatch(getSafetyCommunicationsThunk(yacht_id));
    }

    return (
        <HomeLayout>
            {yachtState.fetching || yachtState.processing ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {yachtState.error ? <Error state={yachtState.error} /> : " "}
                    <HeaderForm title={`Safety & Communications - ${yachtname.yacht.name}`} yacht_id={yacht_id} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex flex-col gap-2 p-2'>
                            {safety?.map((comunication, index) => (
                                <div className='w-full shadow-md shadow-gray-300 rounded-md py-2 border flex flex-col' key={index}>
                                    {comunication.commets?.map((opcion, opcionIndex) => (
                                        <div className='flex p-2 justify-between text-[14px]' key={opcionIndex}>
                                            <input
                                                className='border w-[50%] bg-transparent shadow-sm pl-3 shadow-gray-500 rounded-md focus:outline-none focus:border-orange-500'
                                                type="text"
                                                value={opcion}
                                                onChange={(event) => handleCambiarOpcion(index, opcionIndex, event)}
                                                placeholder={`Item ${opcionIndex + 1}`}
                                            />
                                            <button
                                                type="button"
                                                className='hover:bg-red-300/40 transition-all active:scale-95 rounded-full flex justify-center items-center  w-10 h-10' onClick={() => handleEliminarOpcion(index, opcionIndex)}>
                                                <FaTrashAlt />
                                            </button>
                                        </div>
                                    ))}
                                    <div className='p-2'>
                                        <button
                                            type="button"
                                            className='hover:bg-green-300/40 text-[12px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                            onClick={() => handleAgregarOpcion(index)}><FaPlus />Agregar opción</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                            <BtnContent type="submit">{t("save_button")}</BtnContent>
                            <BtnContent cancel={true} to={'/panel/yachts'}>{t("canceled_button")}</BtnContent>
                        </div>
                    </form>
                </div>
            )}
        </HomeLayout>
    );
};
export default SafetyCommunications;