import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import DOMPurify from 'dompurify';
//UI
import HomeLayout from '../../../layouts/HomeLayout';
import BtnTable from '../../../components/buttons/BtnTable';
import MainLoader from '../../../components/Loaders/MainLoader';
import Error from '../../../components/errors/Error';
import HeaderSimpleII from '../../../components/headers/catalogs/HeaderSimpleII';

//RESOURCES
import { API_BASE_URL } from '../../../store/constans';
import { deleteNoticeThunk, getNoticesByPageThunk, initialStateNotice } from '../../../store/slices/pages/noitcesAdmin.slice';
import { Link, useParams } from 'react-router-dom';
import HeaderSimple from '../../../components/headers/catalogs/HeaderSimple';
import BtnAdd from '../../../components/buttons/BtnAdd';

const NoticesBlogList = () => {

    const { t } = useTranslation();
    const { page } = useParams();
    const noticeState = useSelector((state) => state.noticesAdmin);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getNoticesByPageThunk(page));
        return () => {
            dispatch(initialStateNotice());
        }
    }, []);

    if (noticeState.message === "resource deleted successfully") {
        dispatch(getNoticesByPageThunk(page));
    }

    const handleDelete = (notice_id) => {
        dispatch(deleteNoticeThunk(notice_id));
    };

    return (
        <HomeLayout>
            {noticeState.fetching ? (
                <MainLoader />
            ) : (
                <div className='mx-5 my-5 w-full'>
                    {noticeState.error ? <Error state={noticeState.error} /> : " "}
                    <HeaderSimple title={t("Notices")} to={`/panel/${page}/notice/new_notice`} />
                    <div className="overflow-y-scroll h-[87%] contenedor">
                        <table className="text-[13px] table-sm w-full text-start">
                            <thead >
                                <tr className='text-left h-[50px] bg-sky-600/20 border-t-2 border-t-[#2c6a80]'>
                                    <th className='w-[350px]'>{t("Title")}</th>
                                    <th className='w-[450px]'>{t("Description")}</th>
                                    <th className='text-center'>{t("Sections")}</th>
                                    <th className='text-center'>{t("Image")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody >
                                {noticeState.notices?.map(item => (
                                    <tr className='border-b-[2px] duration-75 h-[100px] ' key={item.id}>
                                        <td >
                                            <p className=' font-semibold' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.title) }}></p>
                                        </td>
                                        <td >
                                            <p className=' font-semibold' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.text) }}></p>
                                        </td>
                                        <td >
                                            <div className='flex justify-center items-center'>
                                                {item.sections?.length > 0 ?
                                                    <Link to={`/panel/${item?.page}/notice/${item.id}/sections`}>
                                                        {item.sections.map(section => (
                                                            <div key={section.id} className="avatar-group -space-x-6 rtl:space-x-reverse">
                                                                <p className=' link-hover text-sky-600 font-semibold' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(section.notice_sections?.title) }}></p>
                                                            </div>
                                                        ))}
                                                    </Link>
                                                    :
                                                    <BtnAdd to={`/panel/${item?.page}/notice/${item.id}/sections`} />
                                                }
                                            </div>
                                        </td>
                                        <td >
                                            <div className='flex justify-center items-start'>
                                                <img className='rounded-full w-16 h-14' src={`${API_BASE_URL}${item.image}`} alt={item?.name} />
                                            </div>
                                        </td>
                                        <td >
                                            <div className='flex gap-1 justify-end'>
                                                <BtnTable action="edit" to={`/panel/${item?.page}/notice/${item.id}`} />
                                                <BtnTable title={t("¿Quieres eliminar esta noticia?")} action="delete" onclick={() => handleDelete(item.id)} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default NoticesBlogList;