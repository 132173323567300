//CompanyUsers
import CompanyUserList from "./containers/panel/user/CompanyUserList";
import CompanyUserForm from "./containers/panel/user/CompanyUserForm";
//Yacht
import YachtList from "./containers/panel/yacht/YachtList";
import YachtForm from "./containers/panel/yacht/YachtForm";
import TechnicalInfo from "./containers/panel/yacht/TechnicalInfo";
import GuestsAccommodations from "./containers/panel/yacht/GuestsAccommodations";
import SocialAreas from "./containers/panel/yacht/SocialAreas";
import SafetyCommunication from './containers/panel/yacht/Safety&Communication';
import MechanicEquipment from "./containers/panel/yacht/MechanicEquipment";
import DeckPlan from "./containers/panel/yacht/DeckPlan";
import FullScreenGallery from './containers/panel/yacht/FullScreenGallery';
import DeckSchema from './containers/panel/yacht/DeckSchema';
import CrewsOnBoard from "./containers/panel/yacht/CrewsOnBoard";
//Crew
import CrewList from "./containers/panel/crew/CrewList";
import CrewForm from "./containers/panel/crew/CrewForm";
//Itinerary
import ItineraryList from "./containers/panel/itinerary/ItineraryList";
import ItineraryForm from "./containers/panel/itinerary/ItineraryForm";
import ItineraryYachtList from "./containers/panel/itineraryYacht/ItineraryYachtList";
import ItineraryYachtForm from "./containers/panel/itineraryYacht/ItineraryYachtForm";
//Destinations
import DestinationList from "./containers/panel/destinations/DestinationList";
import DestinationForm from "./containers/panel/destinations/DestinationForm";
import IslandSites from "./containers/panel/destinations/IslandSites";
//CancelationPolicy
import CancelationPolicy from "./containers/panel/cancelationPolicy/cancelationPolicy";
//Home
import HomeList from "./containers/panel/lists/HomeList";
//OurHistory
import OurHistoryList from "./containers/panel/lists/OurHistoryList";
//Galapagos Island
import GalapagosIslandsList from "./containers/panel/lists/GalapagosIslandList";
//Last Minutes deals
import LastMinutesList from "./containers/panel/lists/LastMinuteList";
//Notice
import GalapagosBlogList from "./containers/panel/lists/GalapagosBlogList";
import NoticesBlogList from "./containers/panel/lists/NoticesBlogList";
//Leads 
import LeadsList from "./containers/panel/leads/LeadsList";
import LeadsTrankin from "./containers/panel/leads/LeadsTrankin";
// Availability
import AvailabilityList from "./containers/panel/Availability/AvailabilityList";
import AvailabilityForm from "./containers/panel/Availability/AvailabilityForm";
//social responsability
import SocialResponsabilityList from "./containers/panel/lists/SocialResponsabilityList";

//FORMS
import BanerForm from "./containers/panel/forms/BanerForm";
import SectionForm from "./containers/panel/forms/SectionForm";
import RecomendationForm from "./containers/panel/forms/RecomendationForm";
import DescriptionsForm from "./containers/panel/forms/DescriptionForm";
import NoticesForm from "./containers/panel/forms/NoticesForm";
import NoticesSectionsList from "./containers/panel/lists/NoticesSectionsList";

const routes = [
    //Users
    {
        path: "/panel/users",
        component: CompanyUserList,
    },
    {
        path: "/panel/users_new",
        component: CompanyUserForm,
    },
    {
        path: "/panel/users/:user_id",
        component: CompanyUserForm,
    },
    //Yacht
    {
        path: "/panel/yachts",
        component: YachtList,
    },
    {
        path: "/panel/yachts_new",
        component: YachtForm,
    },
    {
        path: "/panel/yachts/:yacht_id",
        component: YachtForm,
    },
    {
        path: "/panel/yacht/:yacht_id/technical_info",
        component: TechnicalInfo,
    },
    {
        path: "/panel/yacht/:yacht_id/guests_accomodations",
        component: GuestsAccommodations,
    },
    {
        path: "/panel/yacht/:yacht_id/social_areas",
        component: SocialAreas,
    },
    {
        path: "/panel/yacht/:yacht_id/safety_communications",
        component: SafetyCommunication,
    },
    {
        path: "/panel/yacht/:yacht_id/mechanic_equipment",
        component: MechanicEquipment,
    },
    {
        path: "/panel/yacht/:yacht_id/deck_plan",
        component: DeckPlan,
    },
    {
        path: "/panel/yacht/:yacht_id/full_screen_gallery",
        component: FullScreenGallery,
    },
    {
        path: "/panel/yacht/:yacht_id/deck_schema",
        component: DeckSchema,
    },
    {
        path: "/panel/yacht/:yacht_id/crews_on_board",
        component: CrewsOnBoard,
    },
    //Crew
    {
        path: "/panel/crews",
        component: CrewList,
    },
    {
        path: "/panel/crews_new",
        component: CrewForm,
    },
    {
        path: "/panel/crews/:crew_id",
        component: CrewForm,
    },
    //Destinations
    {
        path: "/panel/destinations",
        component: DestinationList,
    },
    {
        path: "/panel/destinations_new",
        component: DestinationForm,
    },
    {
        path: "/panel/destinations/:destination_id",
        component: DestinationForm,
    },
    {
        path: "/panel/destinations/:destination_id/island_sites",
        component: IslandSites,
    },
    //Itineraries
    {
        path: "/panel/itineraries",
        component: ItineraryList,
    },
    {
        path: "/panel/itineraries_new",
        component: ItineraryForm,
    },
    {
        path: "/panel/itineraries/:itinerary_id",
        component: ItineraryForm,
    },
    {
        path: "/panel/itineraries/:itinerary_id/yachts",
        component: ItineraryYachtList,
    },
    {
        path: "/panel/itineraries/:itinerary_id/yacht_new",
        component: ItineraryYachtForm,
    },
    //HOME
    {
        path: "/panel/home",
        component: HomeList,
    },
    {
        path: "/panel/home/recomendation",
        component: RecomendationForm,
    },
    //OUR HISTORY
    {
        path: "/panel/our-history",
        component: OurHistoryList,
    },

    //GALAPAGOS ISLAND
    {
        path: "/panel/galapagos-islands",
        component: GalapagosIslandsList,
    },
    //Last Minuts
    {
        path: "/panel/last-minute-deals",
        component: LastMinutesList,
    },
    //Notices
    {
        path: "/panel/galapagos-blog",
        component: GalapagosBlogList,
    },
    {
        path: "/panel/:page/notices",
        component: NoticesBlogList,
    },
    {
        path: "/panel/:page/notice/:notice_id/sections",
        component: NoticesSectionsList

    },
    {
        path: "/panel/:page/notice/:notice_id",
        component: NoticesForm,
    },
    {
        path: "/panel/:page/notice/new_notice",
        component: NoticesForm,
    },
    //Social responsability
    {
        path: "/panel/social-responsability",
        component: SocialResponsabilityList,
    },
    //Leads
    {
        path: "/panel/leads",
        component: LeadsList,
    },
    {
        path: "/panel/leads/:lead_id",
        component: LeadsTrankin,
    },
    //
    {
        path: "/panel/availability",
        component: AvailabilityList,
    },
    {
        path: "/panel/availability/:yacht_id",
        component: AvailabilityForm,
    },
    //Cancelation policy
    {
        path: "/panel/cancellation_policy",
        component: CancelationPolicy,
    },
    //BANER
    {
        path: "/panel/:page/baner/:baner_id",
        component: BanerForm,
    },
    //SECTION
    {
        path: "/panel/:page/section/:section_id",
        component: SectionForm,
    },
    {
        path: "/panel/:page/section/new_section",
        component: SectionForm,
    },
    {
        path: "/panel/:page/notice/:notice_id/new_section",
        component: SectionForm,
    },
    {
        path: "/panel/:page/notice/:notice_id/section/:section_id",
        component: SectionForm,
    },
    //DESCRIPTIONS
    {
        path: "/panel/:page/description/:description_id",
        component: DescriptionsForm,
    },
    
];

export default routes;
