import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut } from '../../../resources/utils';

export const crewSlice = createSlice({
    name: 'crews',
    initialState: {
        crews: [],
        crew: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
    },
    reducers: {
        initialStateCrew(state, action) {
            return {
                crews: [],
                crew: {},
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        requestFetchCrews(state, action) {
            return {
                crews: [],
                crew: {},
                processing: false,
                fetching: true,
                message: "",
                error: "",
            }
        },
        fetchCrewsSuccess(state, action) {
            return {
                crews: action.payload,
                crew: {},
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        fetchCrewsError(state, action) {
            return {
                crews: [],
                crew: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestFetchCrew(state, action) {
            return {
                crews: [],
                crew: {},
                processing: false,
                fetching: true,
                message: "",
                error: "",
            }
        },
        fetchCrewSuccess(state, action) {
            return {
                crews: [],
                crew: action.payload,
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        fetchCrewError(state, action) {
            return {
                crews: [],
                crew: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestCreateCrew(state, action) {
            return {
                crews: [],
                crew: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        createCrewsuccess(state, action) {
            return {
                crews: [],
                crew: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        createCrewError(state, action) {
            return {
                crews: [],
                crew: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestUpdateCrew(state, action) {
            return {
                crews: [],
                crew: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        updateCrewsuccess(state, action) {
            return {
                crews: [],
                crew: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        updateCrewError(state, action) {
            return {
                crews: [],
                crew: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestDeleteCrew(state, action) {
            return {
                crews: [],
                crew: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        deleteCrewsuccess(state, action) {
            return {
                crews: [],
                crew: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        deleteCrewError(state, action) {
            return {
                crews: [],
                crew: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },

    }
})

export const getCrewsThunk = () => dispatch => {
    dispatch(requestFetchCrews())
    axios.get('/crews')
        .then(res => {
            if (res.status === 200) {
                dispatch(fetchCrewsSuccess(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(fetchCrewsError(res.response.data))
            }
        })
};

export const getCrewThunk = (crew_id) => dispatch => {
    dispatch(requestFetchCrew())
    axios.get(`/crews/${crew_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(fetchCrewSuccess(res.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(fetchCrewError(res.response.data))
            }
        })
};

export const createCrewThunk = (data) => dispatch => {
    dispatch(requestCreateCrew())
    axios.post('/crews/createCrew?images=crews', data)
        .then(res => {
            if (res.status === 200) {
                dispatch(createCrewsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(createCrewError(res.response.data))
            }
        })
};

export const updateCrewThunk = (crew_id, data) => dispatch => {
    dispatch(requestUpdateCrew())
    axios.put(`/crews/updateCrew/${crew_id}?images=crews`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(updateCrewsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(updateCrewError(res.response.data))
            }
        })
};

export const deleteCrewThunk = (crew_id) => dispatch => {
    dispatch(requestDeleteCrew())
    axios.delete(`/crews/${crew_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(deleteCrewsuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(deleteCrewError(res.response.data))
            }
        })
};



export const { initialStateCrew,
    requestFetchCrews,
    fetchCrewsSuccess,
    fetchCrewsError,
    requestFetchCrew,
    fetchCrewSuccess,
    fetchCrewError,
    requestCreateCrew,
    createCrewsuccess,
    createCrewError,
    requestUpdateCrew,
    updateCrewsuccess,
    updateCrewError,
    requestDeleteCrew,
    deleteCrewsuccess,
    deleteCrewError,
} = crewSlice.actions;

export default crewSlice.reducer;