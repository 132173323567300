import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
//UIO
import { FaPlus, FaSave, FaTrashAlt } from 'react-icons/fa';
import HomeLayout from '../../../layouts/HomeLayout';
import MainLoader from '../../../components/Loaders/MainLoader';
import BtnContent from '../../../components/buttons/BtnContent';
import InputDeckForm from '../../../components/Inputs/formInput/InputDeckForm';
import Error from '../../../components/errors/Error';
import HeaderForm from '../../../components/headers/catalogs/HeaderForm';
import TextAreaForm from '../../../components/Inputs/formInput/TextAreaForm';
//SLICE
import {
    createRecomendationsThunk, createOneRecomendationThunk, updateRecomendationsThunk, updateOneRecomendationThunk,
    getRecomendationsByPageThunk, initialStateRecomendation, deleteRecomendationThunk
} from '../../../store/slices/pages/recomendations.slice';
//RESOURCES 
import { Toast } from '../../../resources/utils';
import { API_BASE_URL } from '../../../store/constans';

const RecomendationForm = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const file = "home_page";
    const [site, setSite] = useState([]);
    const recomendationState = useSelector((state) => state.recomendations);

    useEffect(() => {
        dispatch(getRecomendationsByPageThunk("home"));
        return () => {
            dispatch(initialStateRecomendation());
        }
    }, []);

    useEffect(() => {
        if (recomendationState.recomendations instanceof Array) {
            if (recomendationState.recomendations.length > 0) {
                setSite(
                    recomendationState.recomendations?.map(recomendation => ({
                        id: recomendation.id,
                        title: recomendation.title,
                        description: recomendation.description,
                        autor: recomendation.autor,
                        calification: recomendation.calification,
                        posted: recomendation.posted,
                        page: recomendation.page,
                        type: recomendation.type,
                        preview: API_BASE_URL + recomendation.image
                    }))
                )
            }
        }
    }, [recomendationState.recomendations])

    const onSubmit = () => {
        const formData = new FormData();
        site.forEach((site) => {
            if (site.id) {
                formData.append('id', site.id);
            }
            formData.append('autor', site.autor);
            formData.append('title', site.title);
            formData.append('calification', site.calification);
            formData.append('posted', site.posted);
            formData.append('description', site.description);
            formData.append('page', site.page);
            formData.append('type', site.type);
            if (site.image) {
                formData.append(`image_${site.id}`, site.image);
            } else {
                const existingSite = recomendationState.recomendations.find(s => s.id === site.id);
                if (existingSite) {
                    formData.append(`image_${site.id}`, existingSite.image);
                }
            }
        });

        if (site.length === 1) {
            if (recomendationState.recomendations.length > 0) {
                dispatch(updateOneRecomendationThunk("home", file, formData))
            } else {
                dispatch(createOneRecomendationThunk(formData, file))
            }
        } else {
            if (recomendationState.recomendations.length > 0) {
                dispatch(updateRecomendationsThunk("home", file, formData))
            } else {
                dispatch(createRecomendationsThunk(formData, file))
            }
        }

    }

    const handleAddSite = () => {
        setSite([...site, { id: '', autor: '', image: '', calification: '', title: '', description: '', posted: '', page: 'home', type: 'recomendation', preview: null }]);
    };

    const handleEliminar = (index) => {
        const nuevasPreguntas = [...site];
        nuevasPreguntas.splice(index, 1);
        setSite(nuevasPreguntas);
    };

    const handleChageAutor = (index, event) => {
        const nuevasPreguntas = [...site];
        nuevasPreguntas[index].autor = event.target.value;
        setSite(nuevasPreguntas);
    };

    const handleChageTitle = (index, event) => {
        const nuevasPreguntas = [...site];
        nuevasPreguntas[index].title = event.target.value;
        setSite(nuevasPreguntas);
    };

    const handleChageCalification = (index, event) => {
        const nuevasPreguntas = [...site];
        nuevasPreguntas[index].calification = event.target.value;
        setSite(nuevasPreguntas);
    };

    const handleChagePosted = (index, event) => {
        const nuevasPreguntas = [...site];
        nuevasPreguntas[index].posted = event.target.value;
        setSite(nuevasPreguntas);
    };

    const handleChageDescription = (index, event) => {
        const nuevasPreguntas = [...site];
        nuevasPreguntas[index].description = event.target.value;
        setSite(nuevasPreguntas);
    };

    const handleImageChange = (index, event) => {
        const file = event.target.files[0];
        const nuevasPreguntas = [...site];
        nuevasPreguntas[index].image = file;

        const reader = new FileReader();
        reader.onload = () => {
            nuevasPreguntas[index].preview = reader.result;
            setSite(nuevasPreguntas);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleDelete = (site_id) => {
        dispatch(deleteRecomendationThunk(site_id))
    }

    const handleSave = () => {
        const formData = new FormData();
        const newSite = site.filter(site => site.id === "")
        newSite.forEach((site) => {
            if (site.id) {
                formData.append('id', site.id);
            }
            formData.append('autor', site.autor);
            formData.append('title', site.title);
            formData.append('calification', site.calification);
            formData.append('posted', site.posted);
            formData.append('description', site.description);
            formData.append('page', site.page);
            formData.append('type', site.type);
            formData.append(`image`, site.image);
        });

        dispatch(createOneRecomendationThunk(formData, file))
    }

    if (recomendationState.message === "resource created successfully" || recomendationState.message === "resource updated successfully") {
        Toast.fire({
            icon: 'success',
            title: recomendationState.message
        })
        dispatch(getRecomendationsByPageThunk("home"));
        setSite([]);
    }

    if (recomendationState.message === "resource deleted successfully") {
        dispatch(getRecomendationsByPageThunk("home"));
    }

    return (
        <HomeLayout>
            {recomendationState.fetching || recomendationState.processing ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {recomendationState.error ? <Error state={recomendationState.error} /> : " "}
                    <HeaderForm title={`Home Screen - Recomendations`} />
                    <div className='flex flex-col mb-5 text-start'>
                        <div className='rounded-md flex flex-col gap-3' >
                            {site.map((pregunta, index) => (
                                <div className='w-full shadow-md shadow-gray-300 bg-gray-300/20 rounded-md py-2 border flex flex-col' key={index}>
                                    <h1 className='text-center font-semibold'>{pregunta.title}</h1>
                                    <div className='flex gap-2 p-2'>
                                        <div className='flex flex-col gap-2 p-2 justify-center'>
                                            <label className="text-sm flex m-1">Imágen<span className="text-red-500">*</span></label>
                                            <div className='flex flex-col gap-2'>
                                                {pregunta.preview ?
                                                    <img className='h-32 w-42 rounded-md' src={pregunta.preview} alt={`Preview ${index}`} /> : ""}
                                                <input type="file" onChange={(event) => handleImageChange(index, event)} accept="image/*" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                            </div>
                                        </div>
                                        <div className='flex mt-5 flex-col w-full'>
                                            <InputDeckForm
                                                type="text"
                                                label={t("Autor")}
                                                input="input"
                                                spam={true}
                                                cols={1}
                                                value={pregunta.autor}
                                                onChange={(event) => handleChageAutor(index, event)}
                                            />
                                            <div className='flex gap-3'>
                                                <InputDeckForm
                                                    type="text"
                                                    label={t("Title")}
                                                    input="input"
                                                    spam={true}
                                                    cols={1}
                                                    value={pregunta.title}
                                                    onChange={(event) => handleChageTitle(index, event)}
                                                />
                                                <InputDeckForm
                                                    type="text"
                                                    label={t("Calification")}
                                                    input="input"
                                                    spam={true}
                                                    cols={1}
                                                    value={pregunta.calification}
                                                    onChange={(event) => handleChageCalification(index, event)}
                                                />
                                            </div>
                                            <InputDeckForm
                                                type="text"
                                                label={t("Posted In")}
                                                input="input"
                                                spam={true}
                                                cols={1}
                                                value={pregunta.posted}
                                                onChange={(event) => handleChagePosted(index, event)}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex gap-2 p-2 mb-5'>
                                        <TextAreaForm
                                            type="text"
                                            label={t("Description")}
                                            input="input"
                                            spam={true}
                                            cols={1}
                                            value={pregunta.description}
                                            placeholder={t("Description")}
                                            onChange={(event) => handleChageDescription(index, event)}
                                        />
                                    </div>
                                    {pregunta.id ?
                                        <div className='p-2 justify-end w-full flex'>
                                            <button
                                                type="button"
                                                className='hover:bg-red-300/40 px-3 text-[14px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                                onClick={() => handleDelete(pregunta.id)}>
                                                <FaTrashAlt />Eliminar item
                                            </button>
                                        </div>
                                        :
                                        <>
                                            <div className='p-2 justify-end w-full flex'>
                                                <button
                                                    type="button"
                                                    className='hover:bg-red-300/40 px-3 text-[14px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                                    onClick={() => handleEliminar(index)}>
                                                    <FaTrashAlt />Eliminar item
                                                </button>
                                            </div>
                                            <div className='p-2 justify-end w-full flex'>
                                                <button
                                                    type="button"
                                                    className='hover:bg-green-300/40 px-3 text-[14px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                                    onClick={handleSave}>
                                                    <FaSave />Guardar item
                                                </button>
                                            </div>
                                        </>

                                    }
                                </div>
                            ))}
                            <div className='p-2 justify-center w-full flex my-3'>
                                <button
                                    type="button"
                                    className='hover:bg-green-300/40 text-[15px] font-semibold transition-all gap-1 p-1 px-2 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                    onClick={handleAddSite}><FaPlus />Agregar item</button>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                        <BtnContent type="submit" funtion={() => onSubmit()}>{t("save_button")}</BtnContent>
                        <BtnContent cancel={true} to={'/panel/home'}>{t("canceled_button")}</BtnContent>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default RecomendationForm;