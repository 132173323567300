import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
//UI
import HomeLayout from '../../../layouts/HomeLayout';
import BtnTable from '../../../components/buttons/BtnTable';
import MainLoader from '../../../components/Loaders/MainLoader';
import Error from '../../../components/errors/Error';

//RESOURCES
import { API_BASE_URL } from '../../../store/constans';
import { getSectionsByNoticeThunk, initialStateNotice } from '../../../store/slices/pages/noitcesAdmin.slice';
import HeaderSimple from '../../../components/headers/catalogs/HeaderSimple';

const NoticesSectionsList = () => {

    const { t } = useTranslation();
    const { page, notice_id } = useParams();
    const noticeState = useSelector((state) => state.noticesAdmin);
    const dispatch = useDispatch();
   

    useEffect(() => {
        dispatch(getSectionsByNoticeThunk(notice_id));
        return () => {
            dispatch(initialStateNotice());
        }
    }, []);

    return (
        <HomeLayout>
            {noticeState.fetching ? (
                <MainLoader />
            ) : (
                <div className='mx-5 my-5 w-full'>
                    {noticeState.error ? <Error state={noticeState.error} /> : " "}
                    <HeaderSimple title={t("Sections")} to={`/panel/${page}/notice/${notice_id}/new_section`} />
                    <div className="overflow-y-scroll h-[87%] contenedor">
                        <table className="text-[13px] table-sm w-full text-start">
                            <thead >
                                <tr className='text-left h-[50px] bg-sky-600/20 border-t-2 border-t-[#2c6a80]'>
                                    <th className='w-[350px]'>{t("Title")}</th>
                                    <th className='w-[450px]'>{t("Description")}</th>
                                    <th className='text-center'>{t("Image")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody >
                                {noticeState.notices?.map(item => (
                                    <tr className='border-b-[2px] duration-75 h-[100px] ' key={item.id}>
                                        <td >
                                            <p className=' font-semibold' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.notice_sections?.title) }}></p>
                                        </td>
                                        <td >
                                            <p className=' font-semibold' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.notice_sections?.text) }}></p>
                                        </td>
                                        <td >
                                            <div className='flex justify-center items-start'>
                                                <img className='rounded-full w-16 h-14' src={`${API_BASE_URL}${item.notice_sections?.image}`} alt={item.notice_sections?.name} />
                                            </div>
                                        </td>
                                        <td >
                                            <div className='flex gap-1 justify-end'>
                                                <BtnTable action="edit" to={`/panel/${page}/notice/${notice_id}/section/${item.notice_sections?.id}`} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default NoticesSectionsList;