import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut } from '../../../resources/utils';

export const activitiesSlice = createSlice({
    name: 'activities',
    initialState: {
        activities: [],
        activity: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
    },
    reducers: {
        initialStateActivities(state, action) {
            return {
                activities: [],
                activity: {},
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        requestFetchActivities(state, action) {
            return {
                activities: [],
                activity: {},
                processing: false,
                fetching: true,
                message: "",
                error: "",
            }
        },
        fetchActivitiesSuccess(state, action) {
            return {
                activities: action.payload,
                activity: {},
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        fetchActivitiesError(state, action) {
            return {
                activities: [],
                activity: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestFetchActivity(state, action) {
            return {
                activities: [],
                activity: {},
                processing: false,
                fetching: true,
                message: "",
                error: "",
            }
        },
        fetchActivitySuccess(state, action) {
            return {
                activities: [],
                activity:  action.payload,
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        fetchActivityError(state, action) {
            return {
                activities: [],
                activity: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestCreateActivities(state, action) {
            return {
                activities: [],
                activity: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        createActivitiesuccess(state, action) {
            return {
                activities: [],
                activity: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        createActivitiesError(state, action) {
            return {
                activities: [],
                activity: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestUpdateActivities(state, action) {
            return {
                activities: [],
                activity: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        updateActivitiesuccess(state, action) {
            return {
                activities: [],
                activity: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        updateActivitiesError(state, action) {
            return {
                activities: [],
                activity: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestDeleteActivities(state, action) {
            return {
                activities: [],
                activity: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        deleteActivitiesuccess(state, action) {
            return {
                activities: [],
                activity: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        deleteActivitiesError(state, action) {
            return {
                activities: [],
                activity: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },

    }
})

export const getActivitiesByPageAndTypeThunk = (page, type) => dispatch => {
    dispatch(requestFetchActivities())
    axios.get(`/activities/getActivitiesByPageAndType/${page}?type=${type}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(fetchActivitiesSuccess(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(fetchActivitiesError(res.response.data))
            }
        })
};

export const getActivity = (activity_Id) => dispatch => {
    dispatch(requestFetchActivity())
    axios.get(`/activities/${activity_Id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(fetchActivitySuccess(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(fetchActivityError(res.response.data))
            }
        })
};


export const createActivitiesThunk = (data) => dispatch => {
    dispatch(requestCreateActivities())
    axios.post(`/activities/createActivities?images=galapagos_island_page`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(createActivitiesuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(createActivitiesError(res.response.data))
            }
        })
};

export const updateActivitiesThunk = (activity_id, data) => dispatch => {
    dispatch(requestUpdateActivities())
    axios.put(`/activities/updateActivities/${activity_id}?images=galapagos_island_page`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(updateActivitiesuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(updateActivitiesError(res.response.data))
            }
        })
};

export const deleteActivitiesThunk = (activity_id) => dispatch => {
    dispatch(requestDeleteActivities())
    axios.delete(`/activities/${activity_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(deleteActivitiesuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(deleteActivitiesError(res.response.data))
            }
        })
};



export const { initialStateActivities,
    requestFetchActivities,
    fetchActivitiesSuccess,
    fetchActivitiesError,
    requestFetchActivity,
    fetchActivitySuccess,
    fetchActivityError,
    requestCreateActivities,
    createActivitiesuccess,
    createActivitiesError,
    requestUpdateActivities,
    updateActivitiesuccess,
    updateActivitiesError,
    requestDeleteActivities,
    deleteActivitiesuccess,
    deleteActivitiesError,
} = activitiesSlice.actions;

export default activitiesSlice.reducer;