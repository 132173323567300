import React from 'react';
import { useToolbarStore } from '../../store/VitalStore';
//UI
import logo from '../../assets/Rolf-Wittmer-Galapagos-Cruises-Logo.png';
import AdminList from './AdminList';
import PanelList from './PanelList';
// import SurveysList from './SurveysList';

const NavLeft = ({ collapsed, setCollapsed }) => {

  const { isToolbarOpen, closeToolbar } = useToolbarStore((state) => state);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  return (
    <div
      className={`text-white font-sans shadow-lg fixed z-10 top-0 bottom-0 shadow-black/30 md:translate-x-0 transition-all  w-60 bg-cover bg-fixed ${isToolbarOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-[#051937]/80 via-[#004d7a]/80 to-[#008793]/80 "></div>
      <div className="flex items-center justify-center gap-2 my-5 md:my-1 2xl:my-5 h-16 z-20 relative">
        <img className="object-contain h-[80px] md:h-[70px] 2xl:h-[80px] " src={logo} alt="logo" />
      </div>
      <ul className="flex flex-col h-full items-start justify-start w-full gap-2 px-3 z-20 relative ">
        {user.role === 'admin' ?
          <AdminList /> : ""
        }
        <PanelList />
      </ul>

    </div>
  );
};

export default NavLeft;
