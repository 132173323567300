import { createSlice } from '@reduxjs/toolkit';
import { setIsLoading } from '../isLoading.slice';
import axios from 'axios';
import { logOut } from '../../../resources/utils';


export const yachtInfoSlice = createSlice({
    name: 'yachtInformation',
    initialState: {
        yachtInformation: {},
        message: "",
        error: "",
    },
    reducers: {
        initialStateYachtInformation: (state, action) => {
            return {
                yachtInformation: {},
                message: "",
                error: "",
            }
        },
        setYachtInformation: (state, action) => {
            return {
                yachtInformation: action.payload,
                message: "",
                error: "",
            }
        },
        setYachtInformationMessages: (state, action) => {
            return {
                yachtInformation: {},
                message: action.payload,
                error: "",
            }
        },
        setYachtInformationError: (state, action) => {
            return {
                yachtInformation: {},
                message: "",
                error: action.payload,
            }
        },
        deleteSuccess(state, action) {
            return {
                yachtInformation: {},
                message: action.payload,
                error: "",
            }
        },
        deleteError(state, action) {
            return {
                yachtInformation: {},
                message: "",
                error: action.payload,
            }
        },
    }
})

export const getTechnicalInformationThunk = (yacht_id) => dispatch => {
    dispatch(setIsLoading(true));
    axios.get(`/technicalInformation/${yacht_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformation(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const createTechnicalInformationThunk = (data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.post(`/technicalInformation/createTechInfo?images=tiptops&&file=files`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const updateTechnicalInformationThunk = (yacht_id, data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.put(`/technicalInformation/updateTechInfo/${yacht_id}?images=tiptops&&file=files`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};

export const getFullScreenGalleryThunk = (yacht_id) => dispatch => {
    dispatch(setIsLoading(true));
    axios.get(`/fullScreenGallery/${yacht_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformation(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const createFullScreenGalleryThunk = (data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.post(`/fullScreenGallery/createGallery?images=tiptops`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const updateFullScreenGalleryThunk = (yacht_id, data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.put(`/fullScreenGallery/updateGallery/${yacht_id}?images=tiptops`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};

export const getSocialAreaThunk = (yacht_id) => dispatch => {
    dispatch(setIsLoading(true));
    axios.get(`/socialAreas/${yacht_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformation(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const createSocialAreaThunk = (data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.post(`/socialAreas/createSocialArea?images=tiptops`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const updateSocialAreaThunk = (yacht_id, data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.put(`/socialAreas/updateSocialArea/${yacht_id}?images=tiptops`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};

export const getDeckPlanThunk = (yacht_id) => dispatch => {
    dispatch(setIsLoading(true));
    axios.get(`/deckPlans/${yacht_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformation(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const createDeckPlanThunk = (data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.post(`/deckPlans/createDeckPlan?images=tiptops&&file=files`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const updateDeckPlanThunk = (yacht_id, data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.put(`/deckPlans/updateDeckPlan/${yacht_id}?images=tiptops&&file=files`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};

export const getSafetyCommunicationsThunk = (yacht_id) => dispatch => {
    dispatch(setIsLoading(true));
    axios.get(`/safetyCommunications/${yacht_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformation(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const createSafetyCommunicationsThunk = (data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.post(`/safetyCommunications/createSafetyCommunications`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const updateSafetyCommunicationsThunk = (yacht_id, data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.put(`/safetyCommunications/updateSafetyCommunications/${yacht_id}`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const getMechanicEquipmentThunk = (yacht_id) => dispatch => {
    dispatch(setIsLoading(true));
    axios.get(`/mechanicEquipment/${yacht_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformation(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const createMechanicEquipmentThunk = (data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.post(`/mechanicEquipment/createMechanicEquipment`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const updateMechanicEquipmentThunk = (yacht_id, data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.put(`/mechanicEquipment/updateMechanicEquipment/${yacht_id}`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};

export const getGuestsAccommodationThunk = (yacht_id) => dispatch => {
    dispatch(setIsLoading(true));
    axios.get(`/guestsAccommodation/${yacht_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformation(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const createGuestsAccommodationThunk = (data, decks) => dispatch => {
    dispatch(setIsLoading(true));
    axios.post(`/guestsAccommodation/createGuestsAccommodation?images=tiptops`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const updateGuestsAccommodationThunk = (yacht_id, data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.put(`/guestsAccommodation/updateGuestsAccommodation/${yacht_id}?images=tiptops`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};

export const getDeckSchemaThunk = (yacht_id) => dispatch => {
    dispatch(setIsLoading(true));
    axios.get(`/deckSchema/${yacht_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformation(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const createDeckSchemaThunk = (data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.post(`/deckSchema/createDeckSchema`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const updateDeckSchemaThunk = (yacht_id, data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.put(`/deckSchema/updateDeckSchema/${yacht_id}`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};

export const getCrewsOnBoardThunk = (yacht_id) => dispatch => {
    dispatch(setIsLoading(true));
    axios.get(`/crewsOnBoard/${yacht_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformation(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};

export const getCrewsByYachtThunk = (yacht_id) => dispatch => {
    dispatch(setIsLoading(true));
    axios.get(`/crewsOnBoard/getCrewsByYacht/${yacht_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformation(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const createCrewsOnBoardThunk = (data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.post(`/crewsOnBoard/createCrewsOnBoard`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};


export const updateCrewsOnBoardThunk = (yacht_id, data) => dispatch => {
    dispatch(setIsLoading(true));
    axios.put(`/crewsOnBoard/updateCrewsOnBoard/${yacht_id}`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response?.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};

export const deleteDeckSchemaThunk = (schema_id) => dispatch => {
    dispatch(setIsLoading(true));
    axios.delete(`/deckSchema/${schema_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(setYachtInformationMessages(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(setYachtInformationError(res.response.data))
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
};

export const {
    initialStateYachtInformation,
    setYachtInformations,
    setYachtInformation,
    setYachtInformationMessages,
    setYachtInformationError,
} = yachtInfoSlice.actions;

export default yachtInfoSlice.reducer;