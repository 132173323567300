import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const HelpsComponent = () => {

    return (
        <div className='z-20 flex gap-2 fixed right-[10px] bottom-14 p-3'>
            <a href='https://wa.link/u7kidg' aria-label="Contact us to whastapp" target="_blank"
                className='flex justify-center items-center p-2 rounded-full text-white bg-green-500 h-[45px] w-[45px] hover:border-white active:scale-95 transition-all hover:scale-110 duration-300'>
                <FaWhatsapp size={40} />
            </a>
        </div>
    );
};

export default HelpsComponent;