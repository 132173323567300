import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
//UIO
import EditorForm from '../../../components/Inputs/formInput/EditorForm';
import DropdownForm from '../../../components/Inputs/formInput/DropdonwForm';
import HomeLayout from '../../../layouts/HomeLayout';
import MainLoader from '../../../components/Loaders/MainLoader';
import BtnContent from '../../../components/buttons/BtnContent';
import Error from '../../../components/errors/Error';
import HeaderForm from '../../../components/headers/catalogs/HeaderForm';
import HeaderSection from '../../../components/headers/catalogs/HeaderSection'
//SLICE
import { createNoticeThunk, updateNoticeThunk, initialStateNotice, getNoticeThunk } from '../../../store/slices/pages/noitcesAdmin.slice';
//RESOURCES 
import { Toast } from '../../../resources/utils';
import { API_BASE_URL } from '../../../store/constans';
import { positions } from '../../../resources/optionsList';

const NoticesForm = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const file = "notices_page";
    const { page, notice_id } = useParams();
    const [selectedImage, setSelectedImage] = useState(null);
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [textOne, setTextOne] = useState('');
    const noticeState = useSelector((state) => state.noticesAdmin);
    const { setValue, register, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        if (notice_id) {
            dispatch(getNoticeThunk(notice_id));
        }
        return () => {
            dispatch(initialStateNotice());
        }
    }, []);

    useEffect(() => {
        setTitle(noticeState.notice.title);
        setSubTitle(noticeState.notice.subtitle);
        setTextOne(noticeState.notice.text);
    }, [noticeState.notice]);


    //NOTICE
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleChangeTitle = (content) => {
        setTitle(content);
    };

    const handleChangeSubTitle = (content) => {
        setSubTitle(content);
    };

    const handleChangeText = (content) => {
        setTextOne(content);
    };

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('subtitle', subTitle);
        formData.append('text', textOne);
        formData.append('page', page);
        formData.append('position', parseInt(data.position));
        if (data.image[0]) {
            formData.append('image', data.image[0]);
        } else {
            formData.append('image', noticeState.notice?.image);
        }

        if (notice_id) {
            dispatch(updateNoticeThunk(notice_id, file, formData))
        } else {
            dispatch(createNoticeThunk(formData, file))
        }
    }

    if (noticeState.message === "resource created successfully" || noticeState.message === "resource updated successfully") {
        Toast.fire({
            icon: 'success',
            title: noticeState.message
        })
        navigate(`/panel/${page}/notices`);
    }

    return (
        <HomeLayout>
            {noticeState.fetching || noticeState.processing ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {noticeState.error ? <Error state={noticeState.error} /> : " "}
                    <HeaderForm title={`Galapagos blog - Notice`} />
                    <div className='h-[90%] overflow-y-scroll contenedor'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <HeaderSection title={'Notice'} />
                            <div className='flex flex-col'>
                                <div className='flex w-full justify-start items-start'>
                                    <div className='flex flex-col gap-2 p-2 font-semibold w-full'>
                                        <label className="text-sm flex m-1">Imágen<span className="text-red-500">*</span></label>
                                        <div className='flex flex-col gap-2'>
                                            {selectedImage || noticeState.notice?.image ?
                                                <img className='h-52 w-42 object-cover' src={selectedImage ? selectedImage : `${API_BASE_URL}${noticeState.notice?.image}`} alt="" /> : ""}
                                            <input {...register('image')} type="file" onChange={handleImageChange} accept="image/*" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <EditorForm
                                            id={"title_baner"}
                                            label={"Titulo"}
                                            spam={true}
                                            cols={1}
                                            onChange={handleChangeTitle}
                                            initialValue={title}
                                        />
                                        <EditorForm
                                            id={"title_baner"}
                                            label={"Sub título"}
                                            spam={true}
                                            cols={1}
                                            onChange={handleChangeSubTitle}
                                            initialValue={subTitle}
                                        />
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <EditorForm
                                        id={"text_one"}
                                        label={"Texto"}
                                        spam={true}
                                        cols={1}
                                        onChange={handleChangeText}
                                        initialValue={textOne}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                                <BtnContent type="submit">{t("save_button")}</BtnContent>
                                <BtnContent cancel={true} to={`/panel/${page}/notices`}>{t("canceled_button")}</BtnContent>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default NoticesForm;