import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
//UI
import HomeLayout from '../../../layouts/HomeLayout';
import BtnTable from '../../../components/buttons/BtnTable';
import HeaderSimple from '../../../components/headers/catalogs/HeaderSimple';
import MainLoader from '../../../components/Loaders/MainLoader';
import BreadTwoParts from '../../../components/breadcrumbs/BreadTwoParts';
import Error from '../../../components/errors/Error';

//SLICE
import { deleteItineraryYachtThunk, getItineraryYachtsThunk, initialStateItineraryYacht } from '../../../store/slices/catalogs/itineraryYacht.slice';
import { getItineraryThunk, initialStateItinerary } from '../../../store/slices/catalogs/itinerary.slice';


const ItineraryYachtList = () => {

    const { t } = useTranslation();
    let { itinerary_id } = useParams();
    const dispatch = useDispatch();
    const itineraryState = useSelector(state => state.itineraries);
    const itineraryYachtState = useSelector(state => state.itineraryYacht);

    useEffect(() => {
        dispatch(getItineraryThunk(itinerary_id))
        dispatch(getItineraryYachtsThunk(itinerary_id));
        return () => {
            dispatch(initialStateItinerary());
            dispatch(initialStateItineraryYacht());
        }
    }, []);

    if (itineraryYachtState.message === "resource deleted successfully") {
        dispatch(getItineraryYachtsThunk(itinerary_id));
    }

    const handledelete = (yacht_id) => {
        dispatch(deleteItineraryYachtThunk(itinerary_id, yacht_id));
    };

    return (
        <HomeLayout>
            {itineraryYachtState.fetching || itineraryState.fetching ? (
                <MainLoader />
            ) : (
                <div className='mx-5 my-5 w-full'>
                    {itineraryYachtState.error ? <Error state={itineraryYachtState.error}/> : " "}
                    <BreadTwoParts
                        titleOne={t("itinerary_title")} toOne={'/panel/itineraries'}
                        titleTwo={itineraryState.itinerary.name}
                    />
                    <HeaderSimple title={t("yacht_title")} to={`/panel/itineraries/${itinerary_id}/yacht_new`} />
                    <div className="overflow-y-scroll h-screen contenedor">
                        <table className="text-[13px] table-sm w-full ">
                            <thead className='border-t-2 border-t-sky-500' >
                                <tr className='text-left h-[50px] bg-[#c2c2c2]/20'>
                                    <th className='p-2'>{t("guide_lang_name")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {itineraryYachtState.itineraryYachts.map(yacht => (
                                    <tr className='h-[50px] border-b-[2px] duration-75' key={yacht.id}>
                                        <td className='p-2 text-start uppercase font-semibold'>{t(yacht.yacht_itinerary?.name)}</td>
                                        <td className='flex justify-end items-center h-[50px] p-2'>
                                            <div>
                                                <BtnTable title={t("yacht_delete")} action="delete" onclick={() => handledelete(yacht.id)} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default ItineraryYachtList;