import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
//UI
import Error from '../../../components/errors/Error';
import HeaderSection from '../../../components/headers/catalogs/HeaderSection';
import MainLoader from '../../../components/Loaders/MainLoader';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import HomeLayout from '../../../layouts/HomeLayout';
import HeaderForm from '../../../components/headers/catalogs/HeaderForm';
import BtnContent from '../../../components/buttons/BtnContent';
// SLICES 
import { createGuestsAccommodationThunk, getGuestsAccommodationThunk, initialStateYachtInformation, updateGuestsAccommodationThunk } from '../../../store/slices/yachts/yachtInfo.slice';
import { getYachtThunk } from '../../../store/slices/catalogs/yacht.slice';

// RESOURCES
import { API_BASE_URL } from '../../../store/constans';
import { Toast } from '../../../resources/utils';

const GuestsAccommodations = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { yacht_id } = useParams();
    const yachtState = useSelector((state) => state.yachtInformation);
    const yachtname = useSelector((state) => state.yachts)
    const [selectedImages, setSelectedImages] = useState([]);
    const [guestsAccommodation, setGuestsAccommodation] = useState([{ id: '', yachtId: '', areas: [''], preview: null }]);
    const { setValue, handleSubmit, register, formState: { errors } } = useForm();

    useEffect(() => {
        dispatch(getYachtThunk(yacht_id));
        if (yacht_id) {
            dispatch(getGuestsAccommodationThunk(yacht_id));
        }
        return () => {
            dispatch(initialStateYachtInformation());
        };
    }, []);

    useEffect(() => {
        if (yachtState.yachtInformation instanceof Array) {
            if (yachtState.yachtInformation[0] !== null) {
                setGuestsAccommodation(
                    yachtState.yachtInformation?.map(info => ({
                        id: info?.id,
                        yachtId: info.yachtId,
                        areas: info.areas?.map((opcion) => opcion)
                    }))
                )
            }
        }
    }, [yachtState.yachtInformation])

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('yachtId', yacht_id);
        guestsAccommodation[0].areas.forEach((area, index) => {
            formData.append(`areas[${index}]`, area);
        });
        if (data.images[0]) {
            Array.from(data.images).forEach((file) => {
                formData.append('images', file);
            });
        } else {
            yachtState.yachtInformation.map(info => (
                Array.from(info.images).forEach((file) => {
                    formData.append('images', file);
                })
            ))
        }

        if (guestsAccommodation[0].id) {
            dispatch(updateGuestsAccommodationThunk(yacht_id, formData));
        } else {
            dispatch(createGuestsAccommodationThunk(formData));
        }
    };

    if (yachtState.message === "resource created successfully" || yachtState.message === "resource updated successfully") {
        Toast.fire({
            icon: 'success',
            title: yachtState.message
        })
        dispatch(getGuestsAccommodationThunk(yacht_id));
    }

    const handleAgregarOpcion = (index) => {
        const nuevasPreguntas = [...guestsAccommodation];
        nuevasPreguntas[index].areas.push('');
        setGuestsAccommodation(nuevasPreguntas);
    };

    const handleEliminarOpcion = (preguntaIndex, opcionIndex) => {
        const nuevasPreguntas = [...guestsAccommodation];
        nuevasPreguntas[preguntaIndex].areas.splice(opcionIndex, 1);
        setGuestsAccommodation(nuevasPreguntas);
    };

    const handleCambiarOpcion = (preguntaIndex, opcionIndex, event) => {
        const nuevasPreguntas = [...guestsAccommodation];
        nuevasPreguntas[preguntaIndex].areas[opcionIndex] = event.target.value;
        setGuestsAccommodation(nuevasPreguntas);
    };

    const handleImageChange = (event) => {
        const files = event.target.files;
        const fileArray = Array.from(files).map(file => URL.createObjectURL(file));
        setSelectedImages(prevImages => prevImages.concat(fileArray));
    };


    return (
        <HomeLayout>
            {yachtState.fetching || yachtState.processing ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {yachtState.error ? <Error state={yachtState.error} /> : " "}
                    <HeaderForm title={`Guest Accommodations - ${yachtname.yacht.name}`} yacht_id={yacht_id} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <HeaderSection title={"Each Cabin has"} />
                        <div className='flex flex-col gap-2 p-2'>
                            {guestsAccommodation?.map((comunication, index) => (
                                <div className='w-full shadow-md shadow-gray-300 rounded-md py-2 border flex flex-col' key={index}>
                                    {comunication.areas?.map((opcion, opcionIndex) => (
                                        <div className='flex p-2 justify-between text-[14px]' key={opcionIndex}>
                                            <input
                                                className='border w-[50%] bg-transparent shadow-sm pl-3 shadow-gray-500 rounded-md focus:outline-none focus:border-orange-500'
                                                type="text"
                                                value={opcion}
                                                onChange={(event) => handleCambiarOpcion(index, opcionIndex, event)}
                                                placeholder={`Item ${opcionIndex + 1}`}
                                            />
                                            <button
                                                type="button"
                                                className='hover:bg-red-300/40 transition-all active:scale-95 rounded-full flex justify-center items-center  w-10 h-10' onClick={() => handleEliminarOpcion(index, opcionIndex)}>
                                                <FaTrashAlt />
                                            </button>
                                        </div>
                                    ))}
                                    <div className='p-2'>
                                        <button
                                            type="button"
                                            className='hover:bg-green-300/40 text-[12px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                            onClick={() => handleAgregarOpcion(index)}><FaPlus />Agregar opción</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <HeaderSection title={"Gallery"} />
                        <div className='flex flex-col gap-2 mb-5 items-center font-semibold'>
                            <div className='flex gap-3 mt-5 items-center'>
                                <label className="text-sm flex m-1">Images</label>
                                <div className='flex flex-col gap-2'>
                                    <input {...register('images')} onChange={handleImageChange} type="file" multiple accept="image/*" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                </div>
                            </div>
                            {guestsAccommodation[0].id && selectedImages.length === 0 ?
                                <>
                                    <div className='p-2 grid grid-cols-5 gap-2'>
                                        {yachtState.yachtInformation?.map(information => (
                                            information?.images?.map((image, index) => (
                                                <img key={index} src={API_BASE_URL + image} alt={`Selected ${index}`} className="w-24 h-24 rounded-md" />
                                            ))
                                        ))}
                                    </div>
                                </> :
                                selectedImages ?
                                    <>
                                        <div className='p-2 grid grid-cols-5 gap-2'>
                                            {selectedImages.map((image, index) => (
                                                <img key={index} src={image} alt={`Selected ${index}`} className="w-24 h-24 rounded-md" />
                                            ))}
                                        </div>
                                    </>
                                    : " "}
                        </div>
                        <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                            <BtnContent type="submit">{t("save_button")}</BtnContent>
                            <BtnContent cancel={true} to={'/panel/yachts'}>{t("canceled_button")}</BtnContent>
                        </div>
                    </form>
                </div>
            )
            }
        </HomeLayout >
    );
};

export default GuestsAccommodations;