import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
// ESTILOS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';
import './assets/css/landing.css';
//ROUTES
import routes from './routes';
import store from './store';
import ProtectedRoutes from './components/protectedRoutes/ProtectedRoutes';
//PANEL
import Login from './containers/auth/Login';
import ForgotPasswordForm from './containers/auth/ForgotPasswordForm';
import ChangePasswordForm from './containers/auth/ChangePasswordForm';
//LANDING
const HomePage = React.lazy(() => import('./containers/landingPage/HomePage'));
const YachtsPage = React.lazy(() => import('./containers/landingPage/YachtsPage'));
const DestinationsPage = React.lazy(() => import('./containers/landingPage/DestinationsPage'));
const IslandsPage = React.lazy(() => import('./containers/landingPage/IslandsPage'));
const OurHistoryPage = React.lazy(() => import('./containers/landingPage/OurHistoryPage'));
const YachtPage = React.lazy(() => import('./containers/landingPage/YachtPage'));
const ContactUsPage = React.lazy(() => import('./containers/landingPage/ContactUsPage'));
const LastMinutePage = React.lazy(() => import('./containers/landingPage/LastMinutePage'));
const NoticesPage = React.lazy(() => import('./containers/landingPage/NoticesPage'));
const NoticePage = React.lazy(() => import('./containers/landingPage/NoticePage'));
const SocialResponsabilityPage = React.lazy(() => import('./containers/landingPage/SocialResponsabilityPage'));

import MainLoader from './components/Loaders/MainLoader';
import PublicLayout from './layouts/PublicLayout';

function App() {
  return (
    <React.Suspense fallback={<MainLoader />}>
      <HelmetProvider>
        <Provider store={store}>
          <BrowserRouter>
            <div className="h-full min-h-screen">
              <Routes>
                {/* Rutas protegidas */}
                <Route element={<ProtectedRoutes />}>
                  {routes.map(route => (
                    <Route key={route.path} path={route.path} element={<route.component />} />
                  ))}
                </Route>
                <Route path="/panel" element={<Login />} />
                <Route path="/forgotPassword" element={<ForgotPasswordForm />} />
                <Route path="/changePassword/:user_id" element={<ChangePasswordForm />} />
                {/* Rutas públicas con PublicLayout */}
                <Route element={<PublicLayout />}>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/our-history" element={<OurHistoryPage />} />
                  <Route path="/galapagos-cruises" element={<YachtsPage />} />
                  <Route path="/galapagos-cruises/:yacht_code" element={<YachtPage />} />
                  <Route path="/galapagos-islands" element={<DestinationsPage />} />
                  <Route path="/galapagos-islands/:island" element={<IslandsPage />} />
                  <Route path="/last-minute-deals" element={<LastMinutePage />} />
                  <Route path="/galapagos-blog" element={<NoticesPage />} />
                  <Route path="/galapagos-blog/:blog" element={<NoticePage />} />
                  <Route path="/social-responsability" element={<SocialResponsabilityPage />} />
                  <Route path="/contact-us" element={<ContactUsPage />} />
                </Route>
              </Routes>
            </div>
          </BrowserRouter>
        </Provider>
      </HelmetProvider>
    </React.Suspense>
  );
}

export default App;
