import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut } from '../../../resources/utils';

export const noticeAdminSlice = createSlice({
    name: 'noticesAdmin',
    initialState: {
        notices: [],
        notice: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
    },
    reducers: {
        initialStateNotice(state, action) {
            return {
                notices: [],
                notice: {},
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        requestFetchNotice(state, action) {
            return {
                notices: [],
                notice: {},
                processing: false,
                fetching: true,
                message: "",
                error: "",
            }
        },
        fetchNoticeSuccess(state, action) {
            return {
                notices: [],
                notice: action.payload,
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        fetchNoticeError(state, action) {
            return {
                notices: [],
                notice: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestFetchNotices(state, action) {
            return {
                notices: [],
                notice: {},
                processing: false,
                fetching: true,
                message: "",
                error: "",
            }
        },
        fetchNoticesSuccess(state, action) {
            return {
                notices: action.payload,
                notice: {},
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        fetchNoticesError(state, action) {
            return {
                notices: [],
                notice: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestCreateNotice(state, action) {
            return {
                notices: [],
                notice: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        createNoticesuccess(state, action) {
            return {
                notices: [],
                notice: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        createNoticeError(state, action) {
            return {
                notices: [],
                notice: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestUpdateNotice(state, action) {
            return {
                notices: [],
                notice: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        updateNoticesuccess(state, action) {
            return {
                notices: [],
                notice: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        updateNoticeError(state, action) {
            return {
                notices: [],
                notice: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestDeleteNotice(state, action) {
            return {
                notices: [],
                notice: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        deleteNoticesuccess(state, action) {
            return {
                notices: [],
                notice: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        deleteNoticeError(state, action) {
            return {
                notices: [],
                notice: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },

    }
})

export const getNoticesByPageThunk = (page) => dispatch => {
    dispatch(requestFetchNotices())
    axios.get(`/noticesScreen/getNoticesByPage/${page}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(fetchNoticesSuccess(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(fetchNoticesError(res.response.data))
            }
        })
};

export const getSectionsByNoticeThunk = (noticeId) => dispatch => {
    dispatch(requestFetchNotices())
    axios.get(`/noticesScreen/getSectionsByNotice/${noticeId}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(fetchNoticesSuccess(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(fetchNoticesError(res.response.data))
            }
        })
};

export const getNoticeThunk = (noticeId) => dispatch => {
    dispatch(requestFetchNotice())
    axios.get(`/noticesScreen/getNotice/${noticeId}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(fetchNoticeSuccess(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(fetchNoticeError(res.response.data))
            }
        })
};

export const createNoticeThunk = (data, file) => dispatch => {
    dispatch(requestCreateNotice())
    axios.post(`/noticesScreen/createNotice?images=${file}`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(createNoticesuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(createNoticeError(res.response.data))
            }
        })
};

export const updateNoticeThunk = (notice_id, file, data) => dispatch => {
    dispatch(requestUpdateNotice())
    axios.put(`/noticesScreen/updateNotice/${notice_id}?images=${file}`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(updateNoticesuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(updateNoticeError(res.response.data))
            }
        })
};

export const deleteNoticeThunk = (notice_id) => dispatch => {
    dispatch(requestDeleteNotice())
    axios.delete(`/noticesScreen/${notice_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(deleteNoticesuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(deleteNoticeError(res.response.data))
            }
        })
};



export const { initialStateNotice,
    requestFetchNotices,
    fetchNoticesSuccess,
    fetchNoticesError,
    requestFetchNotice,
    fetchNoticeSuccess,
    fetchNoticeError,
    requestCreateNotice,
    createNoticesuccess,
    createNoticeError,
    requestUpdateNotice,
    updateNoticesuccess,
    updateNoticeError,
    requestDeleteNotice,
    deleteNoticesuccess,
    deleteNoticeError,
} = noticeAdminSlice.actions;

export default noticeAdminSlice.reducer;