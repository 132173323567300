import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
//UI
import MainLoader from '../../../components/Loaders/MainLoader';
import InputForm from '../../../components/Inputs/formInput/InputForm';
import HomeLayout from '../../../layouts/HomeLayout';
import HeaderForm from '../../../components/headers/catalogs/HeaderForm';
import BtnContent from '../../../components/buttons/BtnContent';
import InputRadioForm from '../../../components/Inputs/formInput/InputRadioForm';
import InputCheckboxForm from '../../../components/Inputs/formInput/InputCheckboxForm';
import { FaFilePdf } from 'react-icons/fa';
// SLICES 
import { donwloadFileThunk } from '../../../store/slices/donwloadFile.slice';
import { createItineraryThunk, getItineraryThunk, initialStateItinerary, updateItineraryThunk } from '../../../store/slices/catalogs/itinerary.slice';
// RESOURCES
import Error from '../../../components/errors/Error';
//RESOURCES
import { itineraryRoutes, itineraryRouteOptions, Islands, days } from '../../../resources/optionsList';
import { API_BASE_URL } from '../../../store/constans';



const ItineraryForm = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { itinerary_id } = useParams();
    const [rolfFileName, setRolfFileName] = useState("")
    const [selectedImage, setSelectedImage] = useState(null);
    const itineraryState = useSelector((state) => state.itineraries);
    const { setValue, register, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        if (itinerary_id) {
            dispatch(getItineraryThunk(itinerary_id));
        }
        return () => {
            dispatch(initialStateItinerary());
        };
    }, []);

    useEffect(() => {
        setRolfFileName(itineraryState.itinerary?.file)
    }, [itineraryState])

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('route', data.route);
        formData.append('routeOption', data.routeOption);
        formData.append('departure', data.departure);
        formData.append('return', data.return);
        data.islandsIncluded.forEach((island, index) => {
            formData.append(`islandsIncluded[${index}]`, island);
        });

        if (data.image[0]) {
            formData.append('image', data.image[0]);
        } else {
            formData.append('image', itineraryState.itinerary?.image);
        }
        if (data.file[0]) {
            formData.append('file', data.file[0]);
        } else {
            formData.append('file', itineraryState.itinerary?.file);
        }
        if (itinerary_id) {
            dispatch(updateItineraryThunk(itinerary_id, formData));
        } else {
            dispatch(createItineraryThunk(formData));
        }
    };

    if (itineraryState.message === "resource created successfully" || itineraryState.message === "resource updated successfully") {
        navigate("/panel/itineraries");
    }

    if (Object.keys(itineraryState.itinerary).length !== 0) {

        const islandsIncluded = itineraryState.itinerary?.islandsIncluded?.map(island => {
            return island
        })

        setValue('name', itineraryState.itinerary?.name)
        setValue('route', itineraryState.itinerary?.route)
        setValue('routeOption', itineraryState.itinerary?.routeOption)
        setValue('departure', itineraryState.itinerary?.departure)
        setValue('return', itineraryState.itinerary?.return)
        setValue('islandsIncluded', islandsIncluded)
    }

    function getFileNameFromPath(path) {
        return path.split('/').pop();
    }

    const handleDownload = (filename) => {
        dispatch(donwloadFileThunk(filename))
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };


    return (
        <HomeLayout>
            {itineraryState.fetching || itineraryState.processing ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {itineraryState.error ? <Error state={itineraryState.error} /> : " "}
                    <HeaderForm title={itinerary_id ? "Editar itinerario" : "Nuevo itinerario"} />
                    <div className='h-[90%] overflow-y-scroll contenedor'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex gap-2 p-2 border-b-[2px]'>
                                <InputForm
                                    type="text"
                                    label={t("yacht_name")}
                                    input="input"
                                    spam={true}
                                    cols={1}
                                    register={register("name", { required: true })}
                                    placeholder={t("yacht_name")}
                                    errors={errors.name && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                />
                            </div>
                            <div className='flex gap-2 p-2 border-b-[2px]'>
                                <InputRadioForm
                                    type="radio"
                                    label={"Itinerary Route"}
                                    spam={true}
                                    cols={1}
                                    options={itineraryRoutes}
                                    register={register("route", { required: true })}
                                    errors={errors.route && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                />
                                <InputRadioForm
                                    type="radio"
                                    label={"Itinerary Route Option"}
                                    spam={true}
                                    cols={1}
                                    options={itineraryRouteOptions}
                                    register={register("routeOption", { required: true })}
                                    errors={errors.routeOption && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                />
                            </div>
                            <div className='flex gap-2 p-2 border-b-[2px]'>
                                <InputRadioForm
                                    type="radio"
                                    label={"Itinerary Departure"}
                                    spam={true}
                                    cols={1}
                                    options={days}
                                    register={register("departure", { required: true })}
                                    errors={errors.departure && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                />
                                <InputRadioForm
                                    type="radio"
                                    label={"Itinerary Return"}
                                    spam={true}
                                    cols={1}
                                    options={days}
                                    register={register("return", { required: true })}
                                    errors={errors.return && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                />
                            </div>
                            <div className='flex gap-2 p-2 border-b-[2px]'>
                                <InputCheckboxForm
                                    type="checkbox"
                                    label={"Islands included"}
                                    spam={true}
                                    cols={1}
                                    options={Islands}
                                    register={register("islandsIncluded", { required: true })}
                                    errors={errors.islandsIncluded && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                />
                            </div>
                            <div className='flex justify-center'>
                                <div className='flex flex-col gap-2 p-2 items-center font-semibold'>
                                    <div className='flex gap-3'>
                                        <label className="text-sm flex m-1">File</label>
                                        <div className='flex flex-col gap-2'>
                                            <input {...register('file')} type="file" accept="application/pdf" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                        </div>
                                    </div>
                                    {rolfFileName ?
                                        <div className='flex flex-col items-center cursor-pointer' onClick={() => handleDownload(getFileNameFromPath(rolfFileName))} >
                                            <div className='bg-gray-300 py-3 w-24 rounded-md flex flex-col justify-center items-center' >
                                                <FaFilePdf size={90} color='red' />
                                            </div>
                                            <div className='w-full p-2'>
                                                <p className='text-[12px] w-auto'>{rolfFileName}</p>
                                            </div>
                                        </div> : " "
                                    }
                                </div>
                            </div>
                            <div className='flex flex-col gap-2 p-2 my-5 items-center font-semibold'>
                                <div className='flex items-center'>
                                    <label className="text-sm flex m-1">Imágen<span className="text-red-500">*</span></label>
                                    <input {...register('image')} type="file" onChange={handleImageChange} accept="image/*" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    {selectedImage || itineraryState.itinerary?.image ?
                                        <img className='h-32 w-42 rounded-md' src={selectedImage ? selectedImage : `${API_BASE_URL}${itineraryState.itinerary?.image}`} alt="" /> : ""}

                                </div>

                            </div>
                            <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                                <BtnContent type="submit">{t("save_button")}</BtnContent>
                                <BtnContent cancel={true} to={'/panel/itineraries'}>{t("canceled_button")}</BtnContent>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default ItineraryForm;