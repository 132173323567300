import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
//UI
import MainLoader from '../../../components/Loaders/MainLoader';
import HomeLayout from '../../../layouts/HomeLayout';
import HeaderForm from '../../../components/headers/catalogs/HeaderForm';
import BtnContent from '../../../components/buttons/BtnContent';
import Error from '../../../components/errors/Error';
// SLICES 
import { createDescriptionWhitImageThunk, updateDescriptionWhitImageThunk, initialStateDescription, getDescriptionThunk } from '../../../store/slices/pages/descriptions.slice';
// RESOURCES
import { Toast } from '../../../resources/utils';
import { API_BASE_URL } from '../../../store/constans';
import EditorForm from '../../../components/Inputs/formInput/EditorForm';
import { useParams } from 'react-router-dom';
import { FaImage } from 'react-icons/fa6';

const DescriptionsForm = () => {

    const { t } = useTranslation();
    const { page, description_id } = useParams();
    const dispatch = useDispatch();
    const [selectedImages, setSelectedImages] = useState([]);
    const [text, setText] = useState("")
    const descriptionState = useSelector(state => state.descriptions);
    const { setValue, handleSubmit, register, formState: { errors } } = useForm();

    useEffect(() => {
        dispatch(getDescriptionThunk(description_id));
        return () => {
            dispatch(initialStateDescription());
        }
    }, [])

    useEffect(() => {
        setText(descriptionState.description?.text)
    }, [descriptionState.description])

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('text', text);
        if (data.images[0]) {
            Array.from(data.images).forEach((file) => {
                formData.append('images', file);
            });
        } else {
            Array.from(descriptionState.description?.images).forEach((file) => {
                formData.append('images', file);
            });
        }

        if (descriptionState.description?.id) {
            dispatch(updateDescriptionWhitImageThunk(descriptionState.description?.id, formData));
        } else {
            dispatch(createDescriptionWhitImageThunk(formData));
        }
    };

    if (descriptionState.message === "resource created successfully" || descriptionState.message === "resource updated successfully") {
        Toast.fire({
            icon: 'success',
            title: descriptionState.message
        })
        dispatch(getDescriptionThunk(description_id));
    }

    const handleImageChange = (event) => {
        const files = event.target.files;
        const fileArray = Array.from(files).map(file => URL.createObjectURL(file));
        setSelectedImages(prevImages => prevImages.concat(fileArray));
    };

    const handleChange = (content) => {
        setText(content);
    };

    return (
        <HomeLayout>
            {descriptionState.fetching || descriptionState.processing ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {descriptionState.error ? <Error state={descriptionState.error} /> : " "}
                    <HeaderForm title={`Our History Screen - About Us`} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='grid grid-cols-2 '>
                            <div className='w-full h-full'>
                                <EditorForm
                                    id={"text_one"}
                                    label={"Texto"}
                                    spam={true}
                                    cols={1}
                                    onChange={handleChange}
                                    initialValue={text}
                                />
                            </div>
                            <div className='flex flex-col gap-2 mb-5 items-center font-semibold'>
                                <div className='flex gap-3 mt-5 items-center'>
                                    <label className="text-sm flex m-1">Images</label>
                                    <div className='flex flex-col gap-2'>
                                        <input {...register('images')} onChange={handleImageChange} type="file" multiple accept="image/*" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                    </div>
                                </div>
                                {selectedImages.length === 0 ?
                                    <>
                                        <div className='p-2 grid grid-cols-5 gap-2'>
                                            {descriptionState.description.images?.map((image, index) => (
                                                
                                                    <img key={index} src={API_BASE_URL + image} alt={`Selected ${index}`} className="w-24 h-24 rounded-md" />
                                                
                                            ))}
                                        </div>
                                    </> :
                                    selectedImages ?
                                        <>
                                            <div className='p-2 grid grid-cols-5 gap-2'>
                                                {selectedImages.map((image, index) => (
                                                    <img key={index} src={image} alt={`Selected ${index}`} className="w-24 h-24 rounded-md" />
                                                ))}
                                            </div>
                                        </>
                                        : " "}
                            </div>
                        </div>
                        <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                            <BtnContent type="submit">{t("save_button")}</BtnContent>
                            <BtnContent cancel={true} to={`/panel/${page}`}>{t("canceled_button")}</BtnContent>
                        </div>
                    </form>
                </div>
            )}
        </HomeLayout>
    );
};

export default DescriptionsForm;