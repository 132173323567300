import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut } from '../../../resources/utils';

export const cancelationPolicySlice = createSlice({
    name: 'cancelationPolicy',
    initialState: {
        cancelationPolicy: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
    },
    reducers: {
        initialStateCancelationPolicy(state, action) {
            return {
                cancelationPolicy: [],
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        requestFetchCancelationPolicy(state, action) {
            return {
                cancelationPolicy: [],
                processing: false,
                fetching: true,
                message: "",
                error: "",
            }
        },
        fetchCancelationPolicySuccess(state, action) {
            return {
                cancelationPolicy: action.payload,
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        fetchCancelationPolicyError(state, action) {
            return {
                cancelationPolicy: [],
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestCreateCancelationPolicy(state, action) {
            return {
                cancelationPolicy: [],
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        createCancelationPolicysuccess(state, action) {
            return {
                cancelationPolicy: [],
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        createCancelationPolicyError(state, action) {
            return {
                cancelationPolicy: [],
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestUpdateCancelationPolicy(state, action) {
            return {
                cancelationPolicy: [],
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        updateCancelationPolicysuccess(state, action) {
            return {
                cancelationPolicy: [],
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        updateCancelationPolicyError(state, action) {
            return {
                cancelationPolicy: [],
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
    }
})

export const getCancelationPolicysThunk = () => dispatch => {
    dispatch(requestFetchCancelationPolicy())
    axios.get('/cancelationPolicy')
        .then(res => {
            if (res.status === 200) {
                dispatch(fetchCancelationPolicySuccess(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(fetchCancelationPolicyError(res.response.data))
            }
        })
};

export const createCancelationPolicyThunk = (data) => dispatch => {
    dispatch(requestCreateCancelationPolicy())
    axios.post('/cancelationPolicy/createCancellationPolicy?file=files', data)
        .then(res => {
            if (res.status === 200) {
                dispatch(createCancelationPolicysuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(createCancelationPolicyError(res.response.data))
            }
        })
};

export const updateCancelationPolicyThunk = (cancelationPolicy_id, data) => dispatch => {
    dispatch(requestUpdateCancelationPolicy())
    axios.put(`/cancelationPolicy/updateCancellationPolicy/${cancelationPolicy_id}?file=files`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(updateCancelationPolicysuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(updateCancelationPolicyError(res.response.data))
            }
        })
};




export const { initialStateCancelationPolicy,
    requestFetchCancelationPolicy,
    fetchCancelationPolicySuccess,
    fetchCancelationPolicyError,
    requestCreateCancelationPolicy,
    createCancelationPolicysuccess,
    createCancelationPolicyError,
    requestUpdateCancelationPolicy,
    updateCancelationPolicysuccess,
    updateCancelationPolicyError,
} = cancelationPolicySlice.actions;

export default cancelationPolicySlice.reducer;