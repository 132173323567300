import React from 'react';

const BtnDonwload = ({ children, btnAction, color}) => {
  const bg = `bg-${color}-300`;

  return (
    <button
      onClick={btnAction}
      className={`bg-green-300 hover:bg-green-500 transition-all active:scale-95 duration-200 flex items-center justify-end gap-1 py-1 px-3 rounded-full`}
    >
      {children}
    </button>
  );
};

export default BtnDonwload;
