import { useEffect } from "react";

const useZendesk = () => {
    useEffect(() => {
        // Verifica si el script ya fue cargado
        if (document.getElementById("ze-snippet")) return;

        const script = document.createElement("script");
        script.id = "ze-snippet";
        script.src = "https://static.zdassets.com/ekr/snippet.js?key=2057b5ff-fdaf-4468-830b-b52ed9ebf8a7";
        script.async = true;

        document.head.appendChild(script);

        return () => {
            // Opcional: eliminar el script si se desmonta
            const existingScript = document.getElementById("ze-snippet");
            if (existingScript) existingScript.remove();
        };
    }, []);
};

export default useZendesk;
