import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
//UI
import MainLoader from '../../../components/Loaders/MainLoader';
import InputForm from '../../../components/Inputs/formInput/InputForm';
import HomeLayout from '../../../layouts/HomeLayout';
import HeaderForm from '../../../components/headers/catalogs/HeaderForm';
import BtnContent from '../../../components/buttons/BtnContent';

// SLICES 
import { createDestinationThunk, getDestinationThunk, initialStateDestination, updateDestinationThunk } from '../../../store/slices/catalogs/destination.slice';

// RESOURCES
import Error from '../../../components/errors/Error';
//RESOURCES
import { API_BASE_URL } from '../../../store/constans';
import EditorForm from '../../../components/Inputs/formInput/EditorForm';

const DestinationForm = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { destination_id } = useParams();
    const [selectedImage, setSelectedImage] = useState(null);
    const destinationState = useSelector((state) => state.destinations);
    const { setValue, register, handleSubmit, formState: { errors } } = useForm();
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [text, setText] = useState("")

    useEffect(() => {
        if (destination_id) {
            dispatch(getDestinationThunk(destination_id));
        }
        return () => {
            dispatch(initialStateDestination());
        };
    }, []);

    useEffect(() => {
        setTitle(destinationState.destination?.title);
        setSubTitle(destinationState.destination?.subtitle);
        setText(destinationState.destination?.description)
    }, [destinationState.destination]);

    useEffect(() => {
        setText(destinationState.destination?.description)
    }, [destinationState.destination])

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('title', title);
        formData.append('subtitle', subTitle);
        formData.append('description', text);
        if (data.image[0]) {
            formData.append('image', data.image[0]);
        } else {
            formData.append('image', destinationState.destination?.image);
        }

        if (destination_id) {
            dispatch(updateDestinationThunk(destination_id, formData));
        } else {
            dispatch(createDestinationThunk(formData));
        }
    };

    if (destinationState.message === "resource created successfully" || destinationState.message === "resource updated successfully") {
        navigate("/panel/destinations");
    }

    if (Object.keys(destinationState.destination).length !== 0) {
        setValue('name', destinationState.destination?.name)
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleChangeTitle = (content) => {
        setTitle(content);
    };

    const handleChangeSubTitle = (content) => {
        setSubTitle(content);
    };

    const handleChangeText = (content) => {
        setText(content);
    };

    return (
        <HomeLayout>
            {destinationState.fetching || destinationState.processing ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {destinationState.error ? <Error state={destinationState.error} /> : " "}
                    <HeaderForm title={destination_id ? "Editar destino" : "Nuevo destino"} />

                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* <div className='flex gap-2 p-2'>
                                <InputForm
                                    type="text"
                                    label={t("yacht_name")}
                                    input="input"
                                    spam={true}
                                    cols={1}
                                    register={register("name", { required: true })}
                                    placeholder={t("yacht_name")}
                                    errors={errors.name && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                />
                            
                                <div>
                                    <label className="text-sm flex m-1">Imágen<span className="text-red-500">*</span></label>
                                    <div className='flex flex-col gap-2'>
                                        {selectedImage || destinationState.destination?.image ?
                                            <img className='h-32 w-42 rounded-md' src={selectedImage ? selectedImage : `${API_BASE_URL}${destinationState.destination?.image}`} alt="" /> : ""}
                                        <input {...register('image')} type="file" onChange={handleImageChange} accept="image/*" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                    </div>
                                </div>
                            <TextAreaForm
                                    type="text"
                                    label={t("Island Description")}
                                    input="input"
                                    spam={true}
                                    cols={1}
                                    register={register("description", { required: true })}
                                    placeholder={t("Island Description")}
                                    errors={errors.description && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                />
                                <EditorForm
                                    id={"text_one"}
                                    label={"Description"}
                                    spam={true}
                                    cols={1}
                                    onChange={handleChange}
                                    initialValue={text}
                                />
                            </div>
                            <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                                <BtnContent type="submit">{t("save_button")}</BtnContent>
                                <BtnContent cancel={true} to={'/panel/destinations'}>{t("canceled_button")}</BtnContent>
                            </div> */}
                        <div className='flex flex-col'>
                            <div className='flex w-full justify-start items-start'>
                                <div className='flex flex-col gap-2 p-2 font-semibold w-full'>
                                    <label className="text-sm flex m-1">Imágen<span className="text-red-500">*</span></label>
                                    <div className='flex flex-col gap-2'>
                                        {selectedImage || destinationState.destination?.image ?
                                            <img className='h-52 w-42 object-cover' src={selectedImage ? selectedImage : `${API_BASE_URL}${destinationState.destination?.image}`} alt="" /> : ""}
                                        <input {...register('image')} type="file" onChange={handleImageChange} accept="image/*" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <InputForm
                                        type="text"
                                        label={t("Nombre de la Isla")}
                                        input="input"
                                        spam={true}
                                        cols={1}
                                        register={register("name", { required: true })}
                                        placeholder={t("yacht_name")}
                                        errors={errors.name && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                    />
                                    <EditorForm
                                        id={"title_baner"}
                                        label={"Titulo"}
                                        spam={true}
                                        cols={1}
                                        onChange={handleChangeTitle}
                                        initialValue={title}
                                    />
                                    <EditorForm
                                        id={"title_baner"}
                                        label={"Sub título"}
                                        spam={true}
                                        cols={1}
                                        onChange={handleChangeSubTitle}
                                        initialValue={subTitle}
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <EditorForm
                                    id={"text_one"}
                                    label={"Texto"}
                                    spam={true}
                                    cols={1}
                                    onChange={handleChangeText}
                                    initialValue={text}
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                            <BtnContent type="submit">{t("save_button")}</BtnContent>
                            <BtnContent cancel={true} to={'/panel/destinations'}>{t("canceled_button")}</BtnContent>
                        </div>
                    </form>
                </div>
            )}
        </HomeLayout>
    );
};

export default DestinationForm;