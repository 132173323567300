import { configureStore } from '@reduxjs/toolkit';
//CATALOGS
import users from './slices/catalogs/users.slice';
import isLoadingSlice from './slices/isLoading.slice';
import donwloadFile from './slices/donwloadFile.slice';
import roles from './slices/catalogs/roles.slice';
import yachts from './slices/catalogs/yacht.slice';
import crews from './slices/catalogs/crew.slice';
import sites from './slices/catalogs/sites.slice';
import itineraries from './slices/catalogs/itinerary.slice';
import destinations from './slices/catalogs/destination.slice';
import itineraryYacht from './slices/catalogs/itineraryYacht.slice';
import yachtInformation from './slices/yachts/yachtInfo.slice';
import yachtForPage from './slices/catalogs/yachtForPage.slice';
import cancelationPolicy from './slices/catalogs/cancelationPolicy.slice';
//PAGE

import recomendations from './slices/pages/recomendations.slice';
import descriptions from './slices/pages/descriptions.slice';
import activities from './slices/pages/activities.slice';
import leads from './slices/pages/leads.slice';
import noticesPage from './slices/pages/notices.slice';
import noticesAdmin from './slices/pages/noitcesAdmin.slice';
import proceduresLeads from './slices/pages/proceduresLeads.slice';
import availability from './slices/availability/availability.slice';
import proceduresAvailability from './slices/availability/proceduresAvailability.slice';
import page from './slices/pages/page.slice';
import baner from './slices/pages/baner.slice';
import sections from './slices/pages/section.slice';
//Availability

export default configureStore({
  reducer: {
    users,
    isLoadingSlice,
    donwloadFile,
    roles,
    yachts,
    crews,
    sites,
    cancelationPolicy,
    destinations,
    itineraries,
    itineraryYacht,
    yachtInformation,
    yachtForPage,
    recomendations,
    descriptions,
    activities,
    leads,
    noticesPage,
    noticesAdmin,
    proceduresLeads,
    availability,
    proceduresAvailability,
    page,
    baner,
    sections
  }
})