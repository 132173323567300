import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
//UI
import Error from '../../../components/errors/Error';
import HeaderSection from '../../../components/headers/catalogs/HeaderSection';
import MainLoader from '../../../components/Loaders/MainLoader';
import InputForm from '../../../components/Inputs/formInput/InputForm';
import HomeLayout from '../../../layouts/HomeLayout';
import HeaderForm from '../../../components/headers/catalogs/HeaderForm';
import BtnContent from '../../../components/buttons/BtnContent';
import { FaFilePdf } from 'react-icons/fa';
// SLICES 
import { donwloadFileThunk } from '../../../store/slices/donwloadFile.slice';
import { createTechnicalInformationThunk, getTechnicalInformationThunk, initialStateYachtInformation, updateTechnicalInformationThunk } from '../../../store/slices/yachts/yachtInfo.slice';
import { getYachtThunk } from '../../../store/slices/catalogs/yacht.slice';
// RESOURCES
import { API_BASE_URL } from '../../../store/constans';
import { Toast } from '../../../resources/utils';


const TechnicalInformation = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { yacht_id } = useParams();
    const [selectedImages, setSelectedImages] = useState([]);
    const [rolfFileName, setRolfFileName] = useState("")
    const [tipTopFileName, setTipTopFileName] = useState("")
    const yachtState = useSelector((state) => state.yachtInformation);
    const yachtname = useSelector((state) => state.yachts)
    const { setValue, handleSubmit, register, formState: { errors } } = useForm();

    useEffect(() => {
        dispatch(getYachtThunk(yacht_id));
        dispatch(getTechnicalInformationThunk(yacht_id));
        return () => {
            dispatch(initialStateYachtInformation());
        };
    }, []);

    useEffect(() => {
        setRolfFileName(yachtState.yachtInformation?.rwFile)
        setTipTopFileName(yachtState.yachtInformation?.ttfFile)

    }, [yachtState])

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('yachtId', yacht_id);
        formData.append('length', data.length);
        formData.append('beam', data.beam);
        formData.append('draft', data.draft);
        formData.append('cruising', data.cruising);
        if (data.images[0]) {
            Array.from(data.images).forEach((file) => {
                formData.append('images', file);
            });
        } else {
            Array.from(yachtState.yachtInformation?.images).forEach((file) => {
                formData.append('images', file);
            });
        }

        if (data.rwFile[0]) {
            formData.append('rwFile', data.rwFile[0]);
        } else {
            formData.append('rwFile', yachtState.yachtInformation?.rwFile);
        }
        if (data.ttfFile[0]) {
            formData.append('ttfFile', data.ttfFile[0]);
        } else {
            formData.append('ttfFile', yachtState.yachtInformation?.ttfFile);
        }

        if (yachtState.yachtInformation !== null) {
            dispatch(updateTechnicalInformationThunk(yacht_id, formData));
        } else {
            dispatch(createTechnicalInformationThunk(formData));
        }
    };

    if (yachtState.message === "resource created successfully" || yachtState.message === "resource updated successfully") {
        Toast.fire({
            icon: 'success',
            title: yachtState.message
        })
        dispatch(getTechnicalInformationThunk(yacht_id));
    }

    if (yachtState.yachtInformation instanceof Object) {
        if (Object.keys(yachtState.yachtInformation).length !== 0) {
            setValue('beam', yachtState.yachtInformation?.beam)
            setValue('draft', yachtState.yachtInformation?.draft)
            setValue('length', yachtState.yachtInformation?.length)
            setValue('cruising', yachtState.yachtInformation?.cruising)
        }
    }


    const handleImageChange = (event) => {
        const files = event.target.files;
        const fileArray = Array.from(files).map(file => URL.createObjectURL(file));
        setSelectedImages(prevImages => prevImages.concat(fileArray));
    };

    function getFileNameFromPath(path) {
        return path.split('/').pop();
    }

    const handleDownload = (filename) => {
        dispatch(donwloadFileThunk(filename))
    };



    return (
        <HomeLayout>
            {yachtState.fetching || yachtState.processing ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {yachtState.error ? <Error state={yachtState.error} /> : " "}
                    <HeaderForm title={`Technical Information - ${yachtname.yacht.name}`} yacht_id={yacht_id} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex gap-2 p-2 mb-5'>
                            <InputForm
                                type="text"
                                label={"Length"}
                                input="input"
                                spam={true}
                                cols={1}
                                register={register("length", { required: true })}
                                placeholder={t("length")}
                                errors={errors.length && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                            />
                            <InputForm
                                type="text"
                                label={"Beam"}
                                input="input"
                                spam={true}
                                cols={1}
                                register={register("beam", { required: true })}
                                placeholder={t("beam")}
                                errors={errors.beam && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                            />
                            <InputForm
                                type="text"
                                label={"Draft"}
                                input="input"
                                spam={true}
                                cols={1}
                                register={register("draft", { required: true })}
                                placeholder={t("draft")}
                                errors={errors.draft && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                            />
                            <InputForm
                                type="text"
                                label={"Cruising"}
                                input="input"
                                spam={true}
                                cols={1}
                                register={register("cruising", { required: true })}
                                placeholder={t("cruising")}
                                errors={errors.cruising && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                            />
                        </div>
                        <HeaderSection title={"Gallery"} />
                        <div className='flex flex-col gap-2 mb-5 items-center font-semibold'>
                            <div className='flex gap-3 mt-5 items-center'>
                                <label className="text-sm flex m-1">Images</label>
                                <div className='flex flex-col gap-2'>
                                    <input {...register('images')} onChange={handleImageChange} type="file" multiple accept="image/*" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                </div>
                            </div>
                            {yachtState.yachtInformation && selectedImages.length === 0 ?
                                <>
                                    <div className='p-2 grid grid-cols-5 gap-2'>
                                        {yachtState.yachtInformation.images?.map((image, index) => (
                                            <img key={index} src={API_BASE_URL + image} alt={`Selected ${index}`} className="w-24 h-24 rounded-md" />
                                        ))}
                                    </div>
                                </> :
                                selectedImages ?
                                    <>
                                        <div className='p-2 grid grid-cols-5 gap-2'>
                                            {selectedImages.map((image, index) => (
                                                <img key={index} src={image} alt={`Selected ${index}`} className="w-24 h-24 rounded-md" />
                                            ))}
                                        </div>
                                    </>
                                    : " "}
                        </div>
                        <HeaderSection title={"PDF Archive"} />
                        <div className='flex justify-center'>
                            <div className='flex flex-col gap-2 p-2 items-center font-semibold'>
                                <div className='flex gap-3'>
                                    <label className="text-sm flex m-1">Rolf Wittmer File</label>
                                    <div className='flex flex-col gap-2'>
                                        <input {...register('rwFile')} type="file" accept="application/pdf" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                    </div>
                                </div>
                                {rolfFileName ?
                                    <div className='flex flex-col items-center cursor-pointer' onClick={() => handleDownload(getFileNameFromPath(rolfFileName))} >
                                        <div className='bg-gray-300 py-3 w-24 rounded-md flex flex-col justify-center items-center' >
                                            <FaFilePdf size={90} color='red' />
                                        </div>
                                        <div className='w-full p-2'>
                                            <p className='text-[12px] w-auto'>{rolfFileName}</p>
                                        </div>
                                    </div> : " "
                                }
                            </div>
                            <div className='flex flex-col gap-2 p-2 items-center font-semibold'>
                                <div className='flex gap-3'>
                                    <label className="text-sm flex m-1">Tip Top Fleet File</label>
                                    <div className='flex flex-col gap-2'>
                                        <input {...register('ttfFile')} type="file" accept="application/pdf" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                    </div>
                                </div>
                                {tipTopFileName ?
                                    <div className='flex flex-col items-center cursor-pointer' onClick={() => handleDownload(getFileNameFromPath(tipTopFileName))} >
                                        <div className='bg-gray-300 py-3 w-24 rounded-md flex flex-col justify-center items-center' >
                                            <FaFilePdf size={90} color='red' />
                                        </div>
                                        <div className='w-full p-2'>
                                            <p className='text-[12px] w-auto'>{tipTopFileName}</p>
                                        </div>
                                    </div> : " "
                                }
                            </div>
                        </div>
                        <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                            <BtnContent type="submit">{t("save_button")}</BtnContent>
                            <BtnContent cancel={true} to={'/panel/yachts'}>{t("canceled_button")}</BtnContent>
                        </div>
                    </form>
                </div>
            )}
        </HomeLayout>
    );
};

export default TechnicalInformation;