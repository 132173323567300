import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
//UIO
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import HomeLayout from '../../../layouts/HomeLayout';
import MainLoader from '../../../components/Loaders/MainLoader';
import BtnContent from '../../../components/buttons/BtnContent';
import InputDeckForm from '../../../components/Inputs/formInput/InputDeckForm';
import Error from '../../../components/errors/Error';
import HeaderForm from '../../../components/headers/catalogs/HeaderForm';
import { Collapse } from 'react-collapse';
//SLICE
import { getYachtThunk } from '../../../store/slices/catalogs/yacht.slice';
import { getDeckSchemaThunk, deleteDeckSchemaThunk, createDeckSchemaThunk, updateDeckSchemaThunk, initialStateYachtInformation } from "../../../store/slices/yachts/yachtInfo.slice"

//RESOURCES
import { Toast } from '../../../resources/utils';


const DeckSchema = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { yacht_id } = useParams();
    const [mainDeck, setMainDeck] = useState([]);
    const [lowerDeck, setLowerDeck] = useState([]);
    const [upperDeck, setUpperDeck] = useState([]);
    const [activeMain, setActiveMain] = useState(false);
    const [activeLower, setActiveLower] = useState(false);
    const [activeUpper, setActiveUpper] = useState(false);
    const yachtname = useSelector((state) => state.yachts)
    const yachtState = useSelector((state) => state.yachtInformation);

    useEffect(() => {
        dispatch(getYachtThunk(yacht_id));
        dispatch(getDeckSchemaThunk(yacht_id));
        return () => {
            dispatch(initialStateYachtInformation());
        }
    }, []);

    useEffect(() => {
        if (yachtState.yachtInformation.mainDeckCabins instanceof Array) {
            if (yachtState.yachtInformation.mainDeckCabins.length > 0) {
                setActiveMain(yachtState.yachtInformation.mainDeckCabins[0].active ? true : false)
                setMainDeck(
                    yachtState.yachtInformation?.mainDeckCabins?.map(commet => ({
                        id: commet.id,
                        yachtId: commet.yachtId,
                        type: commet.type,
                        title: commet.title,
                        opciones: commet.coments?.map((opcion) => opcion)
                    }))
                )
            }
        }
        if (yachtState.yachtInformation.lowerDeckCabins instanceof Array) {
            if (yachtState.yachtInformation.lowerDeckCabins.length > 0) {
                setActiveLower(yachtState.yachtInformation.lowerDeckCabins[0].active ? true : false)
                setLowerDeck(
                    yachtState.yachtInformation?.lowerDeckCabins?.map(commet => ({
                        id: commet.id,
                        yachtId: commet.yachtId,
                        type: commet.type,
                        title: commet.title,
                        opciones: commet.coments?.map((opcion) => opcion)
                    }))
                )
            }
        }
        if (yachtState.yachtInformation.upperDeckCabins instanceof Array) {
            if (yachtState.yachtInformation.upperDeckCabins.length > 0) {
                setActiveUpper(yachtState.yachtInformation.upperDeckCabins[0].active ? true : false)
                setUpperDeck(
                    yachtState.yachtInformation?.upperDeckCabins?.map(commet => ({
                        id: commet.id,
                        yachtId: commet.yachtId,
                        type: commet.type,
                        title: commet.title,
                        opciones: commet.coments?.map((opcion) => opcion)
                    }))
                )
            }
        }
    }, [yachtState.yachtInformation])

    const handleCheckboxChange = (type) => {
        if (type === 'MainDeck') {
            setActiveMain(!activeMain);
        } else if (type === 'LowerDeck') {
            setActiveLower(!activeLower)
        } else if (type === 'UpperDeck') {
            setActiveUpper(!activeUpper)
        }
    };


    const onSubmit = () => {
        if (mainDeck.length > 0 && activeMain) {
            mainDeck.map(option => (
                option.active = activeMain
            ))
        } else {
            mainDeck.map(option => (
                option.active = activeMain
            ))
        }

        if (lowerDeck.length > 0 && activeLower) {
            lowerDeck.map(option => (
                option.active = activeLower
            ))
        } else {
            lowerDeck.map(option => (
                option.active = activeLower
            ))
        }

        if (upperDeck.length > 0 && activeUpper) {
            upperDeck.map(option => (
                option.active = activeUpper
            ))
        } else {
            upperDeck.map(option => (
                option.active = activeUpper
            ))
        }

        const newMainDeck = mainDeck.filter(deck => deck.id === '')
        const newLowerDeck = lowerDeck.filter(deck => deck.id === '')
        const newUpperDeck = upperDeck.filter(deck => deck.id === '')

        const uploadMainDeck = mainDeck.filter(deck => deck.id !== '')
        const uploadLowerDeck = lowerDeck.filter(deck => deck.id !== '')
        const uploadUpperDeck = upperDeck.filter(deck => deck.id !== '')

        const newData = {
            mainDeck: newMainDeck,
            lowerDeck: newLowerDeck,
            upperDeck: newUpperDeck
        }

        const updateData = {
            mainDeck: uploadMainDeck,
            lowerDeck: uploadLowerDeck,
            upperDeck: uploadUpperDeck,
        }

        if (newData.mainDeck.length > 0 || newData.lowerDeck.length > 0 || newData.upperDeck.length > 0) {
            dispatch(createDeckSchemaThunk(newData))
        }
        
        if (updateData.mainDeck.length > 0 || updateData.lowerDeck.length > 0 || updateData.upperDeck.length > 0) {
            dispatch(updateDeckSchemaThunk(yacht_id, updateData))
        }

    }


    //MAIN DECK
    const handleAgregarMainDeck = () => {
        setMainDeck([...mainDeck, { id: '', yachtId: yacht_id, type: 'MainDeck', title: '', opciones: [''], active: false }]);
    };

    const handleEliminarMainDeck = (index) => {
        const nuevasPreguntas = [...mainDeck];
        nuevasPreguntas.splice(index, 1);
        setMainDeck(nuevasPreguntas);
    };

    const handleChageTitleMainDeck = (index, event) => {
        const nuevasPreguntas = [...mainDeck];
        nuevasPreguntas[index].title = event.target.value;
        setMainDeck(nuevasPreguntas);
    };

    const handleMainDeckOptions = (preguntaIndex, opcionIndex, event) => {
        const nuevasPreguntas = [...mainDeck];
        nuevasPreguntas[preguntaIndex].opciones[opcionIndex] = event.target.value;
        setMainDeck(nuevasPreguntas);
    };

    const handleAgregarMainDeckOption = (index) => {
        const nuevasPreguntas = [...mainDeck];
        nuevasPreguntas[index].opciones.push('');
        setMainDeck(nuevasPreguntas);
    };

    const handleEliminarMainDeckOption = (preguntaIndex, opcionIndex) => {
        const nuevasPreguntas = [...mainDeck];
        nuevasPreguntas[preguntaIndex].opciones.splice(opcionIndex, 1);
        setMainDeck(nuevasPreguntas);
    };

    //LOWER DECK

    const handleAgregarLowerDeck = () => {
        setLowerDeck([...lowerDeck, { id: '', yachtId: yacht_id, type: 'LowerDeck', title: '', opciones: [''], active: false }]);
    };

    const handleEliminarLowerDeck = (index) => {
        const nuevasPreguntas = [...lowerDeck];
        nuevasPreguntas.splice(index, 1);
        setLowerDeck(nuevasPreguntas);
    };

    const handleChageTitleLowerDeck = (index, event) => {
        const nuevasPreguntas = [...lowerDeck];
        nuevasPreguntas[index].title = event.target.value;
        setLowerDeck(nuevasPreguntas);
    };

    const handleLowerDeckOptions = (preguntaIndex, opcionIndex, event) => {
        const nuevasPreguntas = [...lowerDeck];
        nuevasPreguntas[preguntaIndex].opciones[opcionIndex] = event.target.value;
        setLowerDeck(nuevasPreguntas);
    };

    const handleAgregarLowerDeckOption = (index) => {
        const nuevasPreguntas = [...lowerDeck];
        nuevasPreguntas[index].opciones.push('');
        setLowerDeck(nuevasPreguntas);
    };

    const handleEliminarLowerDeckOption = (preguntaIndex, opcionIndex) => {
        const nuevasPreguntas = [...lowerDeck];
        nuevasPreguntas[preguntaIndex].opciones.splice(opcionIndex, 1);
        setLowerDeck(nuevasPreguntas);
    };

    //UPPER DECK
    const handleAgregarUpperDeck = () => {
        setUpperDeck([...upperDeck, { id: '', yachtId: yacht_id, type: 'UpperDeck', title: '', opciones: [''], active: false }]);
    };

    const handleEliminarUpperDeck = (index) => {
        const nuevasPreguntas = [...upperDeck];
        nuevasPreguntas.splice(index, 1);
        setUpperDeck(nuevasPreguntas);
    };

    const handleChageTitleUpperDeck = (index, event) => {
        const nuevasPreguntas = [...upperDeck];
        nuevasPreguntas[index].title = event.target.value;
        setUpperDeck(nuevasPreguntas);
    };

    const handleUpperDeckOptions = (preguntaIndex, opcionIndex, event) => {
        const nuevasPreguntas = [...upperDeck];
        nuevasPreguntas[preguntaIndex].opciones[opcionIndex] = event.target.value;
        setUpperDeck(nuevasPreguntas);
    };

    const handleAgregarUpperDeckOption = (index) => {
        const nuevasPreguntas = [...upperDeck];
        nuevasPreguntas[index].opciones.push('');
        setUpperDeck(nuevasPreguntas);
    };

    const handleEliminarUpperDeckOption = (preguntaIndex, opcionIndex) => {
        const nuevasPreguntas = [...upperDeck];
        nuevasPreguntas[preguntaIndex].opciones.splice(opcionIndex, 1);
        setUpperDeck(nuevasPreguntas);
    };

    if (yachtState.message === "resource created successfully" || yachtState.message === "resource updated successfully") {
        Toast.fire({
            icon: 'success',
            title: yachtState.message
        })
        dispatch(getDeckSchemaThunk(yacht_id));
    }

    if (yachtState.message === "resource deleted successfully") {
        dispatch(getDeckSchemaThunk(yacht_id));
    }

    const handleDelete = (schema_id) => {
        dispatch(deleteDeckSchemaThunk(schema_id))
    }

    return (
        <HomeLayout>
            {yachtState.fetching || yachtState.processing ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {yachtState.error ? <Error state={yachtState.error} /> : " "}
                    <HeaderForm title={`Deck Schema - ${yachtname.yacht.name}`} yacht_id={yacht_id} />
                    <div className='flex flex-col mb-5 text-start'>
                        <div className='border p-2'>
                            <h1>Main Deck</h1>
                            <div className="form-control w-52">
                                <label className="cursor-pointer label">
                                    <span className="label-text">{activeMain ? "Desactivate" : "Activate"}</span>
                                    <input type="checkbox" checked={activeMain} className="toggle toggle-accent [--tglbg:white]"
                                        onChange={() => handleCheckboxChange("MainDeck")} />
                                </label>
                            </div>
                        </div>
                        <Collapse isOpened={activeMain}>
                            <div className='flex border p-2 justify-between'>
                                <h1 className='font-semibold'>Main Deck Schema</h1>
                                <div className='w-[60%] rounded-md flex flex-col gap-3' >
                                    {mainDeck.map((pregunta, index) => (
                                        <div className='w-full shadow-md shadow-gray-300 bg-gray-400/20 rounded-md py-2 border flex flex-col' key={index}>
                                            <h1 className='text-center font-semibold'>{pregunta.title}</h1>
                                            <div className='flex gap-2 p-2'>
                                                <InputDeckForm
                                                    type="text"
                                                    label={t("Main Deck Outline Title")}
                                                    input="input"
                                                    spam={true}
                                                    cols={1}
                                                    value={pregunta.title}
                                                    onChange={(event) => handleChageTitleMainDeck(index, event)}
                                                />
                                            </div>
                                            {pregunta.opciones?.map((opcion, opcionIndex) => (
                                                <div className='flex p-2 justify-between items-center text-[14px]' key={opcionIndex}>
                                                    <div className='flex gap-2 p-2 w-[80%]'>
                                                        <InputDeckForm
                                                            type="text"
                                                            label={t(`Main Deck Outline Option ${opcionIndex + 1}`)}
                                                            input="input"
                                                            spam={true}
                                                            cols={1}
                                                            value={opcion}
                                                            onChange={(event) => handleMainDeckOptions(index, opcionIndex, event)}
                                                            placeholder={`Option ${opcionIndex + 1}`}
                                                        />
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className='hover:bg-red-300/40 transition-all active:scale-95 mt-8 rounded-full flex justify-center items-center  w-10 h-10' onClick={() => handleEliminarMainDeckOption(index, opcionIndex)}>
                                                        <FaTrashAlt />
                                                    </button>
                                                </div>
                                            ))}
                                            <div className='p-2'>
                                                <button
                                                    type="button"
                                                    className='hover:bg-green-300/40 text-[12px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                                    onClick={() => handleAgregarMainDeckOption(index)}><FaPlus />Agregar opción</button>
                                            </div>
                                            {pregunta.id ?
                                                <div className='p-2 justify-end w-full flex'>
                                                    <button
                                                        type="button"
                                                        className='hover:bg-red-300/40 text-[14px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                                        onClick={() => handleDelete(pregunta.id)}>
                                                        <FaTrashAlt />Eliminar item sads
                                                    </button>
                                                </div> 
                                                :
                                                <div className='p-2 justify-end w-full flex'>
                                                    <button
                                                        type="button"
                                                        className='hover:bg-red-300/40 text-[14px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                                        onClick={() => handleEliminarMainDeck(index)}>
                                                        <FaTrashAlt />Eliminar item
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                    <div className='p-2 justify-center w-full flex my-3'>
                                        <button
                                            type="button"
                                            className='hover:bg-green-300/40 text-[15px] font-semibold transition-all gap-1 p-1 px-2 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                            onClick={handleAgregarMainDeck}><FaPlus />Agregar item</button>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                        <div className='border p-2'>
                            <h1>Lower  Deck</h1>
                            <div className="form-control w-52">
                                <label className="cursor-pointer label">
                                    <span className="label-text">{activeLower ? "Desactivate" : "Activate"}</span>
                                    <input type="checkbox" checked={activeLower} className="toggle toggle-accent [--tglbg:white]"
                                        onChange={() => handleCheckboxChange("LowerDeck")} />
                                </label>
                            </div>
                        </div>
                        <Collapse isOpened={activeLower}>
                            <div className='flex border p-2 justify-between'>
                                <h1 className='font-semibold'>Lower Deck Schema</h1>
                                <div className='w-[60%] rounded-md flex flex-col gap-3' >
                                    {lowerDeck.map((pregunta, index) => (
                                        <div className='w-full shadow-md shadow-gray-300 bg-gray-400/20 rounded-md py-2 border flex flex-col' key={index}>
                                            <h1 className='text-center font-semibold'>{pregunta.title}</h1>
                                            <div className='flex gap-2 p-2'>
                                                <InputDeckForm
                                                    type="text"
                                                    label={t("Lower Deck Outline Title")}
                                                    input="input"
                                                    spam={true}
                                                    cols={1}
                                                    value={pregunta.title}
                                                    onChange={(event) => handleChageTitleLowerDeck(index, event)}
                                                />
                                            </div>
                                            {pregunta.opciones?.map((opcion, opcionIndex) => (
                                                <div className='flex p-2 justify-between items-center text-[14px]' key={opcionIndex}>
                                                    <div className='flex gap-2 p-2 w-[80%]'>
                                                        <InputDeckForm
                                                            type="text"
                                                            label={t(`Lower Deck Outline Option ${opcionIndex + 1}`)}
                                                            input="input"
                                                            spam={true}
                                                            cols={1}
                                                            value={opcion}
                                                            onChange={(event) => handleLowerDeckOptions(index, opcionIndex, event)}
                                                            placeholder={`Option ${opcionIndex + 1}`}
                                                        />
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className='hover:bg-red-300/40 transition-all active:scale-95 mt-8 rounded-full flex justify-center items-center  w-10 h-10' onClick={() => handleEliminarLowerDeckOption(index, opcionIndex)}>
                                                        <FaTrashAlt />
                                                    </button>
                                                </div>
                                            ))}
                                            <div className='p-2'>
                                                <button
                                                    type="button"
                                                    className='hover:bg-green-300/40 text-[12px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                                    onClick={() => handleAgregarLowerDeckOption(index)}><FaPlus />Agregar opción</button>
                                            </div>
                                            {pregunta.id ?
                                                <div className='p-2 justify-end w-full flex'>
                                                    <button
                                                        type="button"
                                                        className='hover:bg-red-300/40 text-[14px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                                        onClick={() => handleDelete(pregunta.id)}>
                                                        <FaTrashAlt />Eliminar item
                                                    </button>
                                                </div> :
                                                <div className='p-2 justify-end w-full flex'>
                                                    <button
                                                        type="button"
                                                        className='hover:bg-red-300/40 text-[14px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                                        onClick={() => handleEliminarLowerDeck(index)}>
                                                        <FaTrashAlt />Eliminar item
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                    <div className='p-2 justify-center w-full flex my-3'>
                                        <button
                                            type="button"
                                            className='hover:bg-green-300/40 text-[15px] font-semibold transition-all gap-1 p-1 px-2 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                            onClick={handleAgregarLowerDeck}><FaPlus />Agregar item</button>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                        <div className='border p-2'>
                            <h1>Upper  Deck</h1>
                            <div className="form-control w-52">
                                <label className="cursor-pointer label">
                                    <span className="label-text">{activeUpper ? "Desactivate" : "Activate"}</span>
                                    <input type="checkbox" checked={activeUpper} className="toggle toggle-accent [--tglbg:white]"
                                        onChange={() => handleCheckboxChange("UpperDeck")} />
                                </label>
                            </div>
                        </div>
                        <Collapse isOpened={activeUpper}>
                            <div className='flex border p-2 justify-between'>
                                <h1 className='font-semibold'>Upper Deck Schema</h1>
                                <div className='w-[60%] rounded-md flex flex-col gap-3' >
                                    {upperDeck.map((pregunta, index) => (
                                        <div className='w-full shadow-md shadow-gray-300 bg-gray-400/20 rounded-md py-2 border flex flex-col' key={index}>
                                            <h1 className='text-center font-semibold'>{pregunta.title}</h1>
                                            <div className='flex gap-2 p-2'>
                                                <InputDeckForm
                                                    type="text"
                                                    label={t("Upper Deck Outline Title")}
                                                    input="input"
                                                    spam={true}
                                                    cols={1}
                                                    value={pregunta.title}
                                                    onChange={(event) => handleChageTitleUpperDeck(index, event)}
                                                />
                                            </div>
                                            {pregunta.opciones.map((opcion, opcionIndex) => (
                                                <div className='flex p-2 justify-between items-center text-[14px]' key={opcionIndex}>
                                                    <div className='flex gap-2 p-2 w-[80%]'>
                                                        <InputDeckForm
                                                            type="text"
                                                            label={t(`Upper Deck Outline Option ${opcionIndex + 1}`)}
                                                            input="input"
                                                            spam={true}
                                                            cols={1}
                                                            value={opcion}
                                                            onChange={(event) => handleUpperDeckOptions(index, opcionIndex, event)}
                                                            placeholder={`Option ${opcionIndex + 1}`}
                                                        />
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className='hover:bg-red-300/40 transition-all active:scale-95 mt-8 rounded-full flex justify-center items-center  w-10 h-10' onClick={() => handleEliminarUpperDeckOption(index, opcionIndex)}>
                                                        <FaTrashAlt />
                                                    </button>
                                                </div>
                                            ))}
                                            <div className='p-2'>
                                                <button
                                                    type="button"
                                                    className='hover:bg-green-300/40 text-[12px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                                    onClick={() => handleAgregarUpperDeckOption(index)}><FaPlus />Agregar opción</button>
                                            </div>
                                            {pregunta.id ?
                                                <div className='p-2 justify-end w-full flex'>
                                                    <button
                                                        type="button"
                                                        className='hover:bg-red-300/40 text-[14px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                                        onClick={() => handleDelete(pregunta.id)}>
                                                        <FaTrashAlt />Eliminar item
                                                    </button>
                                                </div> :
                                                <div className='p-2 justify-end w-full flex'>
                                                    <button
                                                        type="button"
                                                        className='hover:bg-red-300/40 text-[14px] font-semibold transition-all gap-1 p-1 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                                        onClick={() => handleEliminarUpperDeck(index)}>
                                                        <FaTrashAlt />Eliminar item
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                    <div className='p-2 justify-center w-full flex my-3'>
                                        <button
                                            type="button"
                                            className='hover:bg-green-300/40 text-[15px] font-semibold transition-all gap-1 p-1 px-2 active:scale-95 rounded-full flex justify-center items-center  w-30 h-8'
                                            onClick={handleAgregarUpperDeck}><FaPlus />Agregar item</button>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                    <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                        <BtnContent type="initDay" funtion={() => onSubmit()}>{t("save_button")}</BtnContent>
                        <BtnContent cancel={true} to={'/panel/yachts'}>{t("canceled_button")}</BtnContent>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default DeckSchema;