import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
//UI
import MainLoader from '../../../components/Loaders/MainLoader';
import InputForm from '../../../components/Inputs/formInput/InputForm';
import HomeLayout from '../../../layouts/HomeLayout';
import HeaderForm from '../../../components/headers/catalogs/HeaderForm';
import BtnContent from '../../../components/buttons/BtnContent';

// SLICES 
import { createYachtThunk, getYachtThunk, initialStateYacht, updateYachtThunk } from '../../../store/slices/catalogs/yacht.slice';
// RESOURCES
import Error from '../../../components/errors/Error';
import { API_BASE_URL } from '../../../store/constans';
import EditorForm from '../../../components/Inputs/formInput/EditorForm';

const yachtForm = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { yacht_id } = useParams();
    const [selectedImage, setSelectedImage] = useState(null);
    const [text, setText] = useState('');
    const yachtState = useSelector((state) => state.yachts);
    const { setValue, register, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        if (yacht_id) {
            dispatch(getYachtThunk(yacht_id));
        }
        return () => {
            dispatch(initialStateYacht());
        };
    }, []);

    useEffect(() => {
        setText(yachtState.yacht?.text)
    }, [yachtState.yacht])

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('code', data.code);
        formData.append('guests', data.guests);
        formData.append('cabins', data.cabins);
        formData.append('crews', data.crews);
        formData.append('title', data.title);
        formData.append('text', text);
        if (data.image[0]) {
            formData.append('image', data.image[0]);
        } else {
            formData.append('image', yachtState.yacht?.image);
        }

        if (yacht_id) {
            dispatch(updateYachtThunk(yacht_id, formData));
        } else {
            formData
            dispatch(createYachtThunk(formData));
        }
    };

    if (yachtState.message === "resource created successfully" || yachtState.message === "resource updated successfully") {
        navigate("/panel/yachts");
    }

    if (Object.keys(yachtState.yacht).length !== 0) {
        setValue('name', yachtState.yacht?.name)
        setValue('code', yachtState.yacht?.code)
        setValue('guests', yachtState.yacht?.guests)
        setValue('cabins', yachtState.yacht?.cabins)
        setValue('crews', yachtState.yacht?.crews)
        setValue('title', yachtState.yacht?.title)
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleChangeText = (content) => {
        setText(content);
    };

    return (
        <HomeLayout>
            {yachtState.fetching || yachtState.processing ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {yachtState.error ? <Error state={yachtState.error} /> : " "}
                    <HeaderForm title={yacht_id ? "Editar yate" : "Nuevo yate"} />
                    <div className='h-[90%] overflow-y-scroll contenedor'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex gap-2 p-2'>
                                <InputForm
                                    type="text"
                                    label={t("yacht_name")}
                                    input="input"
                                    spam={true}
                                    cols={1}
                                    register={register("name", { required: true })}
                                    placeholder={t("yacht_name")}
                                    errors={errors.name && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                />
                                <InputForm
                                    type="text"
                                    label={t("yacht_code")}
                                    input="input"
                                    spam={true}
                                    cols={1}
                                    register={register("code", { required: true })}
                                    placeholder={t("yacht_code")}
                                    errors={errors.code && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                />
                            </div>
                            <div className='flex gap-2 p-2'>
                                <InputForm
                                    type="text"
                                    label={"Guests"}
                                    input="input"
                                    spam={true}
                                    cols={1}
                                    register={register("guests", { required: true })}
                                    placeholder={t("guests")}
                                    errors={errors.guests && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                />
                                <InputForm
                                    type="text"
                                    label={"Cabins"}
                                    input="input"
                                    spam={true}
                                    cols={1}
                                    register={register("cabins", { required: true })}
                                    placeholder={t("cabins")}
                                    errors={errors.cabins && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                />
                                <InputForm
                                    type="text"
                                    label={"Crews"}
                                    input="input"
                                    spam={true}
                                    cols={1}
                                    register={register("crews", { required: true })}
                                    placeholder={t("crews")}
                                    errors={errors.crews && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                />
                            </div>
                            <div className='flex gap-2 p-2'>
                                <InputForm
                                    type="text"
                                    label={t("Title")}
                                    input="input"
                                    spam={true}
                                    cols={1}
                                    register={register("title", { required: true })}
                                    placeholder={t("Title")}
                                    errors={errors.title && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                />
                            </div>
                            <div className=''>
                                <EditorForm
                                    id={"yachtBaner_Text"}
                                    label={"Paragraf"}
                                    spam={true}
                                    cols={1}
                                    onChange={handleChangeText}
                                    initialValue={text}
                                />
                            </div>
                            <div className='flex flex-col gap-2 p-2 my-5 items-center font-semibold'>
                                <div className='flex items-center'>
                                    <label className="text-sm flex m-1">Imágen<span className="text-red-500">*</span></label>
                                    <input {...register('image')} type="file" onChange={handleImageChange} accept="image/*" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    {selectedImage || yachtState.yacht?.image ?
                                        <img className='h-32 w-42 rounded-md' src={selectedImage ? selectedImage : `${API_BASE_URL}${yachtState.yacht?.image}`} alt="" /> : ""}

                                </div>
                            </div>
                            <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                                <BtnContent type="submit">{t("save_button")}</BtnContent>
                                <BtnContent cancel={true} to={'/panel/yachts'}>{t("canceled_button")}</BtnContent>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default yachtForm;