
import axios from "axios";
import { Toast } from "./utils";

const generateExcelOfLeads = async () => {
    try {
        const response = await axios.get(`/reports/generate_report_leads`, {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `reporte_leads.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        Toast.fire({
            icon: 'error',
            title: 'Error al descargar el archivo Excel', error
        })
    }
};


export {
    generateExcelOfLeads,
}