import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
//UI
import MainLoader from '../../../components/Loaders/MainLoader';
import InputForm from '../../../components/Inputs/formInput/InputForm';
import HomeLayout from '../../../layouts/HomeLayout';
import HeaderForm from '../../../components/headers/catalogs/HeaderForm';
import BtnContent from '../../../components/buttons/BtnContent';

// SLICES 
import { createCrewThunk, getCrewThunk, initialStateCrew, updateCrewThunk } from '../../../store/slices/catalogs/crew.slice';
// RESOURCES
import Error from '../../../components/errors/Error';
//RESOURCES
import { API_BASE_URL } from '../../../store/constans';

const CrewForm = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { crew_id } = useParams();
    const [selectedImage, setSelectedImage] = useState(null);
    const crewState = useSelector((state) => state.crews);
    const { setValue, register, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        if (crew_id) {
            dispatch(getCrewThunk(crew_id));
        }
        return () => {
            dispatch(initialStateCrew());
        };
    }, []);

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append('name', data.name);
        if(data.image[0]){
            formData.append('image',  data.image[0]);
        } else {
            formData.append('image',  crewState.crew?.image);
        }
        
        if (crew_id) {
            dispatch(updateCrewThunk(crew_id, formData));
        } else {
            dispatch(createCrewThunk(formData));
        }
    };

    if (crewState.message === "resource created successfully" || crewState.message === "resource updated successfully") {
        navigate("/panel/crews");
    }


    if (Object.keys(crewState.crew).length !== 0) {
        setValue('name', crewState.crew?.name)
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];
   
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    return (
        <HomeLayout>
            {crewState.fetching || crewState.processing ? (
                <MainLoader />
            ) : (
                <div className="w-[96%] mt-5 ml-5 ">
                    {crewState.error ? <Error state={crewState.error} /> : " "}
                    <HeaderForm title={crew_id ? "Editar crew" : "Nuevo crew"} />
                    <div className='h-[90%] overflow-y-scroll contenedor'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex gap-2 p-2'>
                                <InputForm
                                    type="text"
                                    label={t("yacht_name")}
                                    input="input"
                                    spam={true}
                                    cols={1}
                                    register={register("name", { required: true })}
                                    placeholder={t("yacht_name")}
                                    errors={errors.name && (<span className="text-red-500 text-xs">{t("required_information")}</span>)}
                                />
                            </div>
                            <div className='flex gap-2 p-2 items-center font-semibold'>
                                <label className="text-sm flex m-1">Imágen<span className="text-red-500">*</span></label>
                                <div className='flex flex-col gap-2'>
                                    {selectedImage || crewState.crew?.image ?
                                        <img className='h-32 w-42' src={selectedImage ? selectedImage : `${API_BASE_URL}${crewState.crew?.image}`} alt="" /> : ""}
                                    <input {...register('image')} type="file" onChange={handleImageChange} accept="image/*" className='bg-slate-200 file-input file-input-bordered file-input-info file-input-xs w-full max-w-xs' />
                                </div>
                            </div>
                            <div className="flex items-center justify-start py-5 gap-2 border-t-2 border-orange-500 mt-8">
                                <BtnContent type="submit">{t("save_button")}</BtnContent>
                                <BtnContent cancel={true} to={'/panel/crews'}>{t("canceled_button")}</BtnContent>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default CrewForm;