import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut } from '../../../resources/utils';

export const SiteSlice = createSlice({
    name: 'sites',
    initialState: {
        sites: [],
        site: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
    },
    reducers: {
        initialStateSite(state, action) {
            return {
                sites: [],
                site: {},
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        requestFetchSites(state, action) {
            return {
                sites: [],
                site: {},
                processing: false,
                fetching: true,
                message: "",
                error: "",
            }
        },
        fetchSitesSuccess(state, action) {
            return {
                sites: action.payload,
                site: {},
                processing: false,
                fetching: false,
                message: "",
                error: "",
            }
        },
        fetchSitesError(state, action) {
            return {
                sites: [],
                site: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestCreateSite(state, action) {
            return {
                sites: [],
                site: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        createSitesuccess(state, action) {
            return {
                sites: [],
                site: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        createSiteError(state, action) {
            return {
                sites: [],
                site: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestUpdateSite(state, action) {
            return {
                sites: [],
                site: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        updateSitesuccess(state, action) {
            return {
                sites: [],
                site: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        updateSiteError(state, action) {
            return {
                sites: [],
                site: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },
        requestDeleteSite(state, action) {
            return {
                sites: [],
                site: {},
                processing: true,
                fetching: false,
                message: "",
                error: "",
            }
        },
        deleteSitesuccess(state, action) {
            return {
                sites: [],
                site: {},
                processing: false,
                fetching: false,
                message: action.payload,
                error: "",
            }
        },
        deleteSiteError(state, action) {
            return {
                sites: [],
                site: {},
                processing: false,
                fetching: false,
                message: "",
                error: action.payload,
            }
        },

    }
})

export const getSitesThunk = (destination_id) => dispatch => {
    dispatch(requestFetchSites())
    axios.get(`/destinations/sites/${destination_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(fetchSitesSuccess(res.data))
            }
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(fetchSitesError(res.response.data))
            }
        })
};


export const createSitesThunk = (data) => dispatch => {
    dispatch(requestCreateSite())
    axios.post('/destinations/sites/createSite?images=sites', data)
        .then(res => {
            if (res.status === 200) {
                dispatch(createSitesuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(createSiteError(res.response.data))
            }
        })
};

export const updateSitesThunk = (destination_id, data) => dispatch => {
    dispatch(requestUpdateSite())
    axios.put(`/destinations/sites/updateSite/${destination_id}?images=sites`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(updateSitesuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(updateSiteError(res.response.data))
            }
        })
};

export const createOneSiteThunk = (data) => dispatch => {
    dispatch(requestCreateSite())
    axios.post('/destinations/sites/createOneSite?images=sites', data)
        .then(res => {
            if (res.status === 200) {
                dispatch(createSitesuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(createSiteError(res.response.data))
            }
        })
};

export const updateOneSiteThunk = (destination_id, data) => dispatch => {
    dispatch(requestUpdateSite())
    axios.put(`/destinations/sites/updateOneSite/${destination_id}?images=sites`, data)
        .then(res => {
            if (res.status === 200) {
                dispatch(updateSitesuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(updateSiteError(res.response.data))
            }
        })
};

export const deleteSiteThunk = (site_id) => dispatch => {
    dispatch(requestDeleteSite())
    axios.delete(`/destinations/sites/${site_id}`)
        .then(res => {
            if (res.status === 200) {
                dispatch(deleteSitesuccess(res.data.data))
            } 
        })
        .catch(res => {
            if (res.response.status === 498) {
                logOut();
            } else {
                dispatch(deleteSiteError(res.response.data))
            }
        })
};



export const { initialStateSite,
    requestFetchSites,
    fetchSitesSuccess,
    fetchSitesError,
    requestFetchSite,
    fetchSiteSuccess,
    fetchSiteError,
    requestCreateSite,
    createSitesuccess,
    createSiteError,
    requestUpdateSite,
    updateSitesuccess,
    updateSiteError,
    requestDeleteSite,
    deleteSitesuccess,
    deleteSiteError,
} = SiteSlice.actions;

export default SiteSlice.reducer;