import React from "react";
import { Outlet } from "react-router-dom";
import HelpsComponent from "../components/helps/HelpsComponent";
import useZendesk from "../components/hook/useZendesk";

const PublicLayout = () => {

  useZendesk(); 
  return (
    <div className="public-layout">
      <Outlet /> {/* Renderiza el contenido de la ruta */}
      <HelpsComponent /> {/* Se muestra en todas las rutas públicas */}
    </div>
  );
};

export default PublicLayout;
