import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
//UI
import HomeLayout from '../../../layouts/HomeLayout';
import MainLoader from '../../../components/Loaders/MainLoader';
import Error from '../../../components/errors/Error';
//SLICE
import { getAvailabilityYachtsThunk } from '../../../store/slices/availability/availability.slice';
//RESOURCES
import { API_BASE_URL } from '../../../store/constans';
import HeaderSimpleII from '../../../components/headers/catalogs/HeaderSimpleII';
import { FaCalendar } from 'react-icons/fa';

const AvailabilityList = () => {

    const { t } = useTranslation();
    const availabilityState = useSelector(state => state.availability)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getAvailabilityYachtsThunk());
    }, []);

    const handleNavigate = (yacht_id) => {
        navigate(`/panel/availability/${yacht_id}`)
    };

    return (
        <HomeLayout>
            {availabilityState.fetching || availabilityState.processing ? (
                <MainLoader />
            ) : (
                <div className='mx-5 my-5 w-full'>
                    {availabilityState.error ? <Error state={availabilityState.error} /> : " "}
                    <HeaderSimpleII title={t("Availability")} />
                    <div className="overflow-y-scroll h-[87%] contenedor">
                        <table className="text-[13px] table-sm w-full text-start">
                            <thead className='border-t-2 border-t-[#2c6a80]' >
                                <tr className='text-left h-[50px] bg-[#c2c2c2]/20'>
                                    <th>{t("Image")}</th>
                                    <th>{t("Name yacht")}</th>
                                    <th>{t("Years")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {availabilityState.availabilitys?.map(yacht => (
                                    <tr key={yacht.id} className='border-b-[2px] duration-75 hover:bg-gray-200 cursor-pointer' onClick={() => handleNavigate(yacht.id)}>
                                        <td >
                                            <img className='rounded-md w-16 h-14' src={`${API_BASE_URL}${yacht.image}`} alt={yacht.name} />
                                        </td>
                                        <td>
                                            <p className='font-semibold uppercase'>{yacht.name}</p>
                                        </td>
                                        <td className='h-[60px]'>
                                            <div className='grid grid-cols-3'>
                                                {
                                                    yacht.years_availability?.map(year => (
                                                        <div className='flex items-center gap-1'>
                                                            <FaCalendar />
                                                            <p>{year.name}</p>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </HomeLayout>
    );
};

export default AvailabilityList;